import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import * as Types from './gqlTypes';
const defaultOptions = {} as const;
export const UserBasicFragmentDoc = gql`
  fragment userBasic on User {
    id
    first_name
    last_name
    blocked
    avatar
  }
`;
export const ActivityFragmentDoc = gql`
  fragment activity on Activity {
    id
    event_type
    payload
    created_at
    summary
    target
    user {
      ...userBasic
    }
  }
`;
export const ClientSettingsFragmentDoc = gql`
  fragment clientSettings on User {
    id
    fe_settings
  }
`;
export const CmsFragmentDoc = gql`
  fragment cms on Cms {
    id
    value
  }
`;
export const CredentialFragmentDoc = gql`
  fragment credential on Credential {
    id
    name
    updatedBy {
      ...userBasic
    }
    updated_at
  }
`;
export const CredentialFilesFragmentDoc = gql`
  fragment credentialFiles on Credential {
    files {
      id
      sizeInKb
      title
      url
    }
  }
`;
export const CredentialLoginFragmentDoc = gql`
  fragment credentialLogin on Credential {
    id
    name
    login
    password
    host
    comment
    ...credentialFiles
  }
`;
export const DealDiscountFragmentDoc = gql`
  fragment dealDiscount on DealDiscount {
    created_at
    id
    import_id
    original_created_at
    start_date
    unit
    updated_at
    value
  }
`;
export const DomainAdsBudgetFragmentDoc = gql`
  fragment domainAdsBudget on DomainAdsBudget {
    id
    domain {
      id
      name
    }
    planned_budget
    spent_budget
    date
    created_at
    updated_at
  }
`;
export const DomainAdsDepositFragmentDoc = gql`
  fragment domainAdsDeposit on DomainAdsDeposit {
    id
    domain {
      id
      name
    }
    deposited_budget
    is_infinite
    date
    created_at
    updated_at
    description
  }
`;
export const DomainBasicFragmentDoc = gql`
  fragment domainBasic on Domain {
    id
    name
  }
`;
export const LspTypeFragmentDoc = gql`
  fragment lspType on LspType {
    id
    value
  }
`;
export const DiscountFragmentDoc = gql`
  fragment discount on DealDiscount {
    id
    unit
    value
    start_date
    end_date
  }
`;
export const DealTerminationFragmentDoc = gql`
  fragment dealTermination on DealTermination {
    id
    saved
    valid
    termination_date
  }
`;
export const DealFragmentDoc = gql`
  fragment deal on Deal {
    id
    active
    amount
    max_budget
    communication_language_name
    content_quarterly
    text_reduction_percent
    currency
    link_building_budget
    diversification_links_count
    sponsored_articles_count
    type
    type_name
    lspType {
      ...lspType
    }
    basket
    name
    start_date
    orig_start_date
    import_id
    is_whitelabel
    status
    status_name
    termination_should_be_saved
    product
    actions_quarterly
    content_guidelines
    content_creation
    content_implementation
    discounts {
      ...discount
    }
    terminations {
      ...dealTermination
    }
    extra_data {
      ads_min_budget
      ads_max_budget
      ads_percent_profit
      ads_profit_amount
      ads_client_pays_budget
    }
    suspension_date
    real_amount
  }
`;
export const DomainDetailsFragmentDoc = gql`
  fragment domainDetails on Domain {
    id
    name
    max_phrases
    phraseCount
    strategy_link
    linkbuilding_strategy
    diversification_linkbuilding_strategy
    brief_copy
    comment_copy
    seo_comment
    cs_comment
    ads_comment
    content_marketing_spreadsheet
    linkbuilding_diversification_spreadsheet
    cms {
      id
      value
    }
    language
    categories
    countable_texts
    country
    first_deal_start_date
    positioned_domain
    service_type
    full_url
    full_url_locked
    percentage_of_positioning
    text_to_be_written_by
    google_ads_currency
    google_ads_last_fetch_date
    related {
      ...domainBasic
    }
    newestDeals {
      key
      name
      deal {
        ...deal
      }
    }
    textOrderGuidelines {
      id
      guidelines
      text_type
      text_type_name
    }
  }
`;
export const DomainViewFragmentDoc = gql`
  fragment domainView on Domain {
    id
    name
    max_phrases
    positioned_domain
    fulfillmentUsers {
      ...userBasic
    }
    salesUsers {
      ...userBasic
    }
    adminUsers {
      ...userBasic
    }
    semFulfillmentUsers {
      ...userBasic
    }
    seoContactUsers {
      ...userBasic
    }
    semContactUsers {
      ...userBasic
    }
    uxUsers {
      ...userBasic
    }
  }
`;
export const DealInTheDomainListFragmentDoc = gql`
  fragment dealInTheDomainList on Deal {
    id
    max_budget
    content_quarterly
    status
    status_name
    type
    type_name
    lspType {
      id
      value
    }
    basket
    currency
    link_building_budget
    diversification_links_count
    sponsored_articles_count
    start_date
    name
    import_id
    text_reduction_percent
    amount
    product
    actions_quarterly
    content_guidelines
    content_creation
    content_implementation
    terminations {
      id
      termination_date
    }
  }
`;
export const DomainInTheListFragmentDoc = gql`
  fragment domainInTheList on Domain {
    ...domainView
    full_url
    strategy_link
    linkbuilding_strategy
    linkbuilding_strategy_name
    diversification_linkbuilding_strategy
    diversification_linkbuilding_strategy_name
    brief_copy
    comment_copy
    seo_comment
    linkbuilding_diversification_spreadsheet
    content_marketing_spreadsheet
    updated_at
    cms {
      id
      value
    }
    days_to_end_quarter
    language
    language_name
    categories
    categories_names
    countable_texts
    countable_texts_names
    country
    country_name
    service_type
    service_type_name
    first_deal_start_date
    percentage_of_positioning
    lastQuarter {
      start_date
      end_date
      created_characters
      characters_to_create
      type
    }
    related {
      ...domainBasic
    }
    newestGridDeal {
      ...dealInTheDomainList
    }
  }
`;
export const DomainReportFragmentDoc = gql`
  fragment domainReport on DomainReport {
    id
    user {
      ...userBasic
    }
    report_path
    report_type
    report_type_name
    created_at
  }
`;
export const ExternalReportFragmentDoc = gql`
  fragment externalReport on ExternalReportDTO {
    id
    emailSent
    language
    link
    reportDate
    note
  }
`;
export const ExternalReportSettingsFragmentDoc = gql`
  fragment externalReportSettings on DomainExternalReportSettings {
    branded_keywords
    company
    country
    created_at
    domain {
      id
    }
    excluded_keywords
    language
    report_emails
    report_languages
    send_email
    template
    updated_at
    google_ads_account_id
    google_ads_send_email
    google_ads_report_emails
    google_ads_report_languages
    ecommerce
    start_date
    GAMGAService {
      id
      name
    }
    GAMGAServiceAudience {
      id
      name
    }
    GAMSearchConsoleSite {
      id
      website_url
    }
  }
`;
export const DomainContactsFragmentDoc = gql`
  fragment domainContacts on DomainContacts {
    id
    contact_person
    contact_info {
      contact_value
      contact_type
      contact_type_name
    }
  }
`;
export const GamgaServiceFragmentDoc = gql`
  fragment gamgaService on GAMGAService {
    id
    name
    service_id
    permission_ok
    GAMGAServiceAudiences {
      id
      name
    }
    GAMAccount {
      id
      GAMInternalAccount {
        id
        name
      }
    }
  }
`;
export const GamSearchConsoleSiteFragmentDoc = gql`
  fragment gamSearchConsoleSite on GAMSearchConsoleSite {
    id
    website_url
  }
`;
export const FullDomainViewFragmentDoc = gql`
  fragment fullDomainView on Domain {
    id
    name
    max_phrases
    positioned_domain
    contacts {
      ...domainContacts
    }
    fulfillmentUsers {
      ...userBasic
    }
    salesUsers {
      ...userBasic
    }
    adminUsers {
      ...userBasic
    }
    semFulfillmentUsers {
      ...userBasic
    }
    seoContactUsers {
      ...userBasic
    }
    semContactUsers {
      ...userBasic
    }
    uxUsers {
      ...userBasic
    }
    GAMGAServices {
      ...gamgaService
    }
    GAMSearchConsoleSites {
      ...gamSearchConsoleSite
    }
    textOrderGuidelines {
      id
      guidelines
      text_type
      text_type_name
    }
    newestDeals {
      key
      name
    }
  }
`;
export const GamInternalAccountFragmentDoc = gql`
  fragment gamInternalAccount on GAMInternalAccount {
    id
    name
    count_ga
    count_gsc
  }
`;
export const PhrasePositionOrderedFragmentDoc = gql`
  fragment phrasePositionOrdered on PhrasePositionOrdered {
    date
    position
    url
    positionDifference
  }
`;
export const GroupedPhraseFragmentDoc = gql`
  fragment groupedPhrase on Phrase {
    id
    value
    start_position
    user_assigned_url
    location
    location_key
    deleted_at
    is_paused
    import_id
    last_position_url
    exclude_from_linking
    positionsOrdered {
      ...phrasePositionOrdered
    }
    search {
      id
      count
    }
    group {
      id
      name
    }
    domain {
      id
    }
  }
`;
export const GroupedPhrasesFragmentDoc = gql`
  fragment groupedPhrases on GroupedPhrases {
    id
    name
    phrases {
      ...groupedPhrase
    }
  }
`;
export const GroupedPhraseMonthsViewFragmentDoc = gql`
  fragment groupedPhraseMonthsView on Phrase {
    id
    value
    start_position
    user_assigned_url
    location
    location_key
    deleted_at
    is_paused
    import_id
    last_position_url
    exclude_from_linking
    monthAvgPositions {
      position
      date
    }
    search {
      id
      count
    }
    group {
      id
      name
    }
    domain {
      id
    }
  }
`;
export const GroupedPhrasesMonthsViewFragmentDoc = gql`
  fragment groupedPhrasesMonthsView on GroupedPhrases {
    id
    name
    phrases {
      ...groupedPhraseMonthsView
    }
  }
`;
export const LinkBasicFragmentDoc = gql`
  fragment linkBasic on Link {
    id
    portal
  }
`;
export const LinkAnchorBasicFragmentDoc = gql`
  fragment linkAnchorBasic on LinkAnchor {
    id
    anchor
    url
  }
`;
export const LinkInTheListFragmentDoc = gql`
  fragment linkInTheList on Link {
    ...linkBasic
    group
    status
    price
    real_publication_date
    planned_publication_date
    note
    anchor {
      ...linkAnchorBasic
    }
    domain {
      ...domainBasic
    }
  }
`;
export const LinkFragmentDoc = gql`
  fragment link on Link {
    ...linkInTheList
    order_date
    text_delivery_date
    text_url
    owner {
      ...userBasic
    }
    copywriter {
      ...userBasic
    }
    created_at
    updated_at
    article_url
    character_count
  }
`;
export const MonitorFragmentDoc = gql`
  fragment monitor on Monitor {
    id
    monitor_id
    friendly_name
    url
    status
    daily_uptime_ratio
    weekly_uptime_ratio
    monthly_uptime_ratio
    domain {
      id
    }
  }
`;
export const NotificationFragmentDoc = gql`
  fragment notification on Notification {
    id
    type
    data
    created_at
    read_at
    owner {
      ...userBasic
    }
  }
`;
export const PhraseGroupFragmentDoc = gql`
  fragment phraseGroup on PhraseGroup {
    id
    name
    domain_id
  }
`;
export const TypeFragmentDoc = gql`
  fragment type on Type {
    id
    value
    color
    color_value
    default
    priority
    archived
  }
`;
export const StatusFragmentDoc = gql`
  fragment status on Status {
    id
    value
    treat_as_closed
    color
    color_value
    default
    visible_in_kanban
    priority
    archived
  }
`;
export const PriorityFragmentDoc = gql`
  fragment priority on Priority {
    id
    value
    color
    color_value
    default
    priority
    archived
  }
`;
export const ProjectFragmentDoc = gql`
  fragment project on Project {
    id
    name
    content
    archived
    priority
    types {
      ...type
    }
    statuses {
      ...status
    }
    priorities {
      ...priority
    }
  }
`;
export const ProjectUsersFragmentDoc = gql`
  fragment projectUsers on Project {
    id
    users {
      ...userBasic
    }
  }
`;
export const QuarterFragmentDoc = gql`
  fragment quarter on Quarter {
    number
    start_date
    end_date
    type
    created_characters
    characters_to_create
    displayed_number
    summary {
      ordered
      done
      accepted
      implemented
      wrong
      to_be_corrected
      category_description
      product_description
      article
      faq
      meta_description
    }
  }
`;
export const QuarterOverrideFragmentDoc = gql`
  fragment quarterOverride on QuarterOverride {
    id
    number
    start_date
    end_date
    type
    type_name
    characters_to_create
  }
`;
export const RoleFragmentDoc = gql`
  fragment role on Role {
    id
    name
    title
    permissions {
      id
      name
    }
  }
`;
export const RoleInTheListFragmentDoc = gql`
  fragment roleInTheList on RoleResponse {
    id
    name
    title
    permissions
  }
`;
export const S4sInstanceFragmentDoc = gql`
  fragment s4sInstance on S4sInstance {
    id
    name
    host
    port
    prefix
    db
    phrases
    domains {
      id
      name
    }
  }
`;
export const SubpageFragmentDoc = gql`
  fragment subpage on Subpage {
    id
    url
    type
    status
    priority
    title
    heading
    description
    character_count
    target_title
    target_heading
    target_description
    target_character_count
    keywords
  }
`;
export const UserInTheListFragmentDoc = gql`
  fragment userInTheList on User {
    ...userBasic
    email
    phone
    roleName
    role
  }
`;
export const TeamFragmentDoc = gql`
  fragment team on Team {
    totalMembers
    id
    name
    leader {
      ...userInTheList
    }
    members {
      ...userInTheList
    }
  }
`;
export const TeamInTheListFragmentDoc = gql`
  fragment teamInTheList on Team {
    totalMembers
    id
    name
    leader {
      ...userBasic
    }
  }
`;
export const TeamStatisticsTotalsFragmentDoc = gql`
  fragment teamStatisticsTotals on Totals {
    thisWeek
    overdue
    today
    open
    closed
    domains
  }
`;
export const TextOrderFragmentDoc = gql`
  fragment textOrder on TextOrder {
    id
    title
    text_type
    text_type_name
    schema {
      meta_description
      bold_keywords
      short_text
      long_text
      base_on_url
      headers
    }
    status
    priority
    order_type
    order_type_name
    url
    text_sum
    text_url
    text_to_be_written_by
    domain {
      id
      name
      brief_copy
      country_name
    }
    verifier {
      ...userBasic
    }
    orderer {
      ...userBasic
    }
    keywords
    tags
    verification_time
    text_score
    final_text_count
    created_at
    verification_date
    planned_publication_date
    text_added_at
    error_message
    sendingToClientTicket {
      id
    }
    implementationTicket {
      id
    }
    comments
  }
`;
export const TextOrderGuidelineFragmentDoc = gql`
  fragment textOrderGuideline on TextOrderGuideline {
    id
    guidelines
    text_type
    text_type_name
  }
`;
export const TextOrdersStatisticsFragmentDoc = gql`
  fragment textOrdersStatistics on TextOrdersStatistics {
    new
    ready_to_write
    to_be_verified
    ready_to_send
    sent_to_customer
    accepted_by_client
    to_be_corrected
    incorrect_order
    implemented
    cancelled
    domain {
      id
      name
    }
  }
`;
export const TicketCommentFileFragmentDoc = gql`
  fragment ticketCommentFile on TicketCommentFile {
    id
    title
    sizeInKb
  }
`;
export const TicketCommentFragmentDoc = gql`
  fragment ticketComment on TicketComment {
    id
    user {
      ...userBasic
    }
    content_html
    created_at
    updated_at
    file {
      ...ticketCommentFile
    }
    ticket {
      id
    }
    pwl_id
  }
`;
export const TicketInTheListFragmentDoc = gql`
  fragment ticketInTheList on Ticket {
    sequence
    id
    title
    start
    deadline
    updated_at
    created_at
    priority_value
    total_time_logs
    project {
      id
      name
    }
    type {
      id
      value
      color
      color_value
    }
    priority {
      id
      value
      color
    }
    status {
      id
      value
      color
    }
    owner {
      ...userBasic
    }
    assigned {
      ...userBasic
    }
    updatedBy {
      ...userBasic
    }
    domain {
      id
      name
    }
  }
`;
export const TicketSubtaskFragmentDoc = gql`
  fragment ticketSubtask on Ticket {
    id
    title
    priority {
      id
      value
      color
    }
    type {
      id
      value
      color
    }
    status {
      id
      value
      color
      treat_as_closed
    }
    project {
      id
      name
    }
  }
`;
export const TicketTodoFragmentDoc = gql`
  fragment ticketTodo on TicketTodo {
    id
    text
    status
  }
`;
export const UserWithPermInfoFragmentDoc = gql`
  fragment userWithPermInfo on User {
    ...userBasic
    allowedPermissions
    teams {
      id
      leader {
        id
      }
    }
  }
`;
export const TicketFileFragmentDoc = gql`
  fragment ticketFile on TicketFile {
    id
    title
    sizeInKb
  }
`;
export const TicketFragmentDoc = gql`
  fragment ticket on Ticket {
    sequence
    id
    title
    content_html
    created_at
    updated_at
    is_children
    deadline
    start
    total_time_logs
    priority_value
    priority {
      id
      value
      color
      color_value
    }
    type {
      id
      value
      color
      color_value
    }
    status {
      id
      value
      color
      color_value
    }
    project {
      id
      name
    }
    owner {
      ...userWithPermInfo
    }
    assigned {
      ...userBasic
    }
    updatedBy {
      ...userBasic
    }
    watchers {
      ...userBasic
    }
    file {
      ...ticketFile
    }
    domain {
      id
      name
      cms {
        value
      }
      is_whitelabel
    }
    sendingToClientTextOrders {
      id
    }
  }
`;
export const RelatedTicketFragmentDoc = gql`
  fragment relatedTicket on Ticket {
    relation_type
    is_children
    sequence
    id
    title
    created_at
    updated_at
    priority {
      id
      value
      color
    }
    type {
      id
      value
      color
    }
    status {
      id
      value
      color
    }
    project {
      id
      name
    }
  }
`;
export const TicketWithRelatedTicketsFragmentDoc = gql`
  fragment ticketWithRelatedTickets on Ticket {
    ...ticket
    is_subtask
    relatedTickets {
      ...relatedTicket
    }
  }
`;
export const TimeLogFragmentDoc = gql`
  fragment timeLog on TimeLog {
    id
    minutes
    created_at
    user {
      ...userBasic
    }
  }
`;
export const TimeStatsFragmentDoc = gql`
  fragment timeStats on TimeLog {
    id
    minutes
    created_at
    user_id
    project_id
    project_name
  }
`;
export const TeamBasicFragmentDoc = gql`
  fragment teamBasic on Team {
    id
    name
    leader {
      id
    }
    members {
      id
    }
  }
`;
export const UserFragmentDoc = gql`
  fragment user on User {
    ...userInTheList
    allowedPermissions
    crm_import_id
    app_locale
    is_impersonated
    teams {
      ...teamBasic
    }
  }
`;
export const UserStatisticsDetailsFragmentDoc = gql`
  fragment userStatisticsDetails on Details {
    id
    thisWeek
    overdue
    today
    open
    closed
    domains
  }
`;
export const AddProjectUserDocument = gql`
  mutation addProjectUser($projectId: ID!, $userId: String!) {
    addProjectUser(projectId: $projectId, userId: $userId) {
      ...projectUsers
    }
  }
  ${ProjectUsersFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type AddProjectUserMutationFn = Apollo.MutationFunction<
  Types.AddProjectUserMutation,
  Types.AddProjectUserMutationVariables
>;

/**
 * __useAddProjectUserMutation__
 *
 * To run a mutation, you first call `useAddProjectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectUserMutation, { data, loading, error }] = useAddProjectUserMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddProjectUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddProjectUserMutation,
    Types.AddProjectUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AddProjectUserMutation, Types.AddProjectUserMutationVariables>(
    AddProjectUserDocument,
    options,
  );
}
export type AddProjectUserMutationHookResult = ReturnType<typeof useAddProjectUserMutation>;
export type AddProjectUserMutationResult = Apollo.MutationResult<Types.AddProjectUserMutation>;
export type AddProjectUserMutationOptions = Apollo.BaseMutationOptions<
  Types.AddProjectUserMutation,
  Types.AddProjectUserMutationVariables
>;
export const AddRelatedTicketDocument = gql`
  mutation addRelatedTicket(
    $ticketId: ID!
    $relatedTicketId: String!
    $relationType: RelationTypeEnum!
  ) {
    addRelatedTicket(
      ticketId: $ticketId
      relatedTicketId: $relatedTicketId
      relationType: $relationType
    ) {
      id
    }
  }
`;
export type AddRelatedTicketMutationFn = Apollo.MutationFunction<
  Types.AddRelatedTicketMutation,
  Types.AddRelatedTicketMutationVariables
>;

/**
 * __useAddRelatedTicketMutation__
 *
 * To run a mutation, you first call `useAddRelatedTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRelatedTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRelatedTicketMutation, { data, loading, error }] = useAddRelatedTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      relatedTicketId: // value for 'relatedTicketId'
 *      relationType: // value for 'relationType'
 *   },
 * });
 */
export function useAddRelatedTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddRelatedTicketMutation,
    Types.AddRelatedTicketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddRelatedTicketMutation,
    Types.AddRelatedTicketMutationVariables
  >(AddRelatedTicketDocument, options);
}
export type AddRelatedTicketMutationHookResult = ReturnType<typeof useAddRelatedTicketMutation>;
export type AddRelatedTicketMutationResult = Apollo.MutationResult<Types.AddRelatedTicketMutation>;
export type AddRelatedTicketMutationOptions = Apollo.BaseMutationOptions<
  Types.AddRelatedTicketMutation,
  Types.AddRelatedTicketMutationVariables
>;
export const AddTicketPriorityDocument = gql`
  mutation addTicketPriority($value: String!, $color: ColorPaletteEnum!) {
    addTicketPriority(value: $value, color: $color) {
      ...priority
    }
  }
  ${PriorityFragmentDoc}
`;
export type AddTicketPriorityMutationFn = Apollo.MutationFunction<
  Types.AddTicketPriorityMutation,
  Types.AddTicketPriorityMutationVariables
>;

/**
 * __useAddTicketPriorityMutation__
 *
 * To run a mutation, you first call `useAddTicketPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTicketPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTicketPriorityMutation, { data, loading, error }] = useAddTicketPriorityMutation({
 *   variables: {
 *      value: // value for 'value'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useAddTicketPriorityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddTicketPriorityMutation,
    Types.AddTicketPriorityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddTicketPriorityMutation,
    Types.AddTicketPriorityMutationVariables
  >(AddTicketPriorityDocument, options);
}
export type AddTicketPriorityMutationHookResult = ReturnType<typeof useAddTicketPriorityMutation>;
export type AddTicketPriorityMutationResult =
  Apollo.MutationResult<Types.AddTicketPriorityMutation>;
export type AddTicketPriorityMutationOptions = Apollo.BaseMutationOptions<
  Types.AddTicketPriorityMutation,
  Types.AddTicketPriorityMutationVariables
>;
export const AddTicketStatusDocument = gql`
  mutation addTicketStatus(
    $value: String!
    $color: ColorPaletteEnum!
    $treatAsClosed: Boolean
    $visibleInKanban: Boolean
  ) {
    addTicketStatus(
      value: $value
      color: $color
      treatAsClosed: $treatAsClosed
      visibleInKanban: $visibleInKanban
    ) {
      ...status
    }
  }
  ${StatusFragmentDoc}
`;
export type AddTicketStatusMutationFn = Apollo.MutationFunction<
  Types.AddTicketStatusMutation,
  Types.AddTicketStatusMutationVariables
>;

/**
 * __useAddTicketStatusMutation__
 *
 * To run a mutation, you first call `useAddTicketStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTicketStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTicketStatusMutation, { data, loading, error }] = useAddTicketStatusMutation({
 *   variables: {
 *      value: // value for 'value'
 *      color: // value for 'color'
 *      treatAsClosed: // value for 'treatAsClosed'
 *      visibleInKanban: // value for 'visibleInKanban'
 *   },
 * });
 */
export function useAddTicketStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddTicketStatusMutation,
    Types.AddTicketStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AddTicketStatusMutation, Types.AddTicketStatusMutationVariables>(
    AddTicketStatusDocument,
    options,
  );
}
export type AddTicketStatusMutationHookResult = ReturnType<typeof useAddTicketStatusMutation>;
export type AddTicketStatusMutationResult = Apollo.MutationResult<Types.AddTicketStatusMutation>;
export type AddTicketStatusMutationOptions = Apollo.BaseMutationOptions<
  Types.AddTicketStatusMutation,
  Types.AddTicketStatusMutationVariables
>;
export const AddTicketSubtaskDocument = gql`
  mutation addTicketSubtask(
    $ticketId: ID!
    $title: String!
    $priority: String!
    $type: String!
    $status: String!
    $project: String!
  ) {
    addTicketSubtask(
      ticketId: $ticketId
      title: $title
      priority: $priority
      type: $type
      status: $status
      project: $project
    ) {
      ...ticketSubtask
    }
  }
  ${TicketSubtaskFragmentDoc}
`;
export type AddTicketSubtaskMutationFn = Apollo.MutationFunction<
  Types.AddTicketSubtaskMutation,
  Types.AddTicketSubtaskMutationVariables
>;

/**
 * __useAddTicketSubtaskMutation__
 *
 * To run a mutation, you first call `useAddTicketSubtaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTicketSubtaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTicketSubtaskMutation, { data, loading, error }] = useAddTicketSubtaskMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      title: // value for 'title'
 *      priority: // value for 'priority'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      project: // value for 'project'
 *   },
 * });
 */
export function useAddTicketSubtaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddTicketSubtaskMutation,
    Types.AddTicketSubtaskMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddTicketSubtaskMutation,
    Types.AddTicketSubtaskMutationVariables
  >(AddTicketSubtaskDocument, options);
}
export type AddTicketSubtaskMutationHookResult = ReturnType<typeof useAddTicketSubtaskMutation>;
export type AddTicketSubtaskMutationResult = Apollo.MutationResult<Types.AddTicketSubtaskMutation>;
export type AddTicketSubtaskMutationOptions = Apollo.BaseMutationOptions<
  Types.AddTicketSubtaskMutation,
  Types.AddTicketSubtaskMutationVariables
>;
export const AddTicketTypeDocument = gql`
  mutation addTicketType($value: String!, $projectId: ID!, $color: ColorPaletteEnum!) {
    addTicketType(value: $value, projectId: $projectId, color: $color) {
      ...type
    }
  }
  ${TypeFragmentDoc}
`;
export type AddTicketTypeMutationFn = Apollo.MutationFunction<
  Types.AddTicketTypeMutation,
  Types.AddTicketTypeMutationVariables
>;

/**
 * __useAddTicketTypeMutation__
 *
 * To run a mutation, you first call `useAddTicketTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTicketTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTicketTypeMutation, { data, loading, error }] = useAddTicketTypeMutation({
 *   variables: {
 *      value: // value for 'value'
 *      projectId: // value for 'projectId'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useAddTicketTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddTicketTypeMutation,
    Types.AddTicketTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AddTicketTypeMutation, Types.AddTicketTypeMutationVariables>(
    AddTicketTypeDocument,
    options,
  );
}
export type AddTicketTypeMutationHookResult = ReturnType<typeof useAddTicketTypeMutation>;
export type AddTicketTypeMutationResult = Apollo.MutationResult<Types.AddTicketTypeMutation>;
export type AddTicketTypeMutationOptions = Apollo.BaseMutationOptions<
  Types.AddTicketTypeMutation,
  Types.AddTicketTypeMutationVariables
>;
export const AddTodoDocument = gql`
  mutation addTodo($ticketId: ID!, $text: String!, $priority: Float) {
    addTodo(ticketId: $ticketId, text: $text, priority: $priority) {
      ...ticketTodo
    }
  }
  ${TicketTodoFragmentDoc}
`;
export type AddTodoMutationFn = Apollo.MutationFunction<
  Types.AddTodoMutation,
  Types.AddTodoMutationVariables
>;

/**
 * __useAddTodoMutation__
 *
 * To run a mutation, you first call `useAddTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTodoMutation, { data, loading, error }] = useAddTodoMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      text: // value for 'text'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useAddTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.AddTodoMutation, Types.AddTodoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AddTodoMutation, Types.AddTodoMutationVariables>(
    AddTodoDocument,
    options,
  );
}
export type AddTodoMutationHookResult = ReturnType<typeof useAddTodoMutation>;
export type AddTodoMutationResult = Apollo.MutationResult<Types.AddTodoMutation>;
export type AddTodoMutationOptions = Apollo.BaseMutationOptions<
  Types.AddTodoMutation,
  Types.AddTodoMutationVariables
>;
export const AddTodosDocument = gql`
  mutation addTodos($ticketId: ID!, $todos: [TodoInput!]!) {
    addTodos(ticketId: $ticketId, todos: $todos) {
      ...ticketTodo
    }
  }
  ${TicketTodoFragmentDoc}
`;
export type AddTodosMutationFn = Apollo.MutationFunction<
  Types.AddTodosMutation,
  Types.AddTodosMutationVariables
>;

/**
 * __useAddTodosMutation__
 *
 * To run a mutation, you first call `useAddTodosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTodosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTodosMutation, { data, loading, error }] = useAddTodosMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      todos: // value for 'todos'
 *   },
 * });
 */
export function useAddTodosMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.AddTodosMutation, Types.AddTodosMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AddTodosMutation, Types.AddTodosMutationVariables>(
    AddTodosDocument,
    options,
  );
}
export type AddTodosMutationHookResult = ReturnType<typeof useAddTodosMutation>;
export type AddTodosMutationResult = Apollo.MutationResult<Types.AddTodosMutation>;
export type AddTodosMutationOptions = Apollo.BaseMutationOptions<
  Types.AddTodosMutation,
  Types.AddTodosMutationVariables
>;
export const ArchiveProjectDocument = gql`
  mutation archiveProject($id: ID!) {
    archiveProject(id: $id) {
      ...project
    }
  }
  ${ProjectFragmentDoc}
  ${TypeFragmentDoc}
  ${StatusFragmentDoc}
  ${PriorityFragmentDoc}
`;
export type ArchiveProjectMutationFn = Apollo.MutationFunction<
  Types.ArchiveProjectMutation,
  Types.ArchiveProjectMutationVariables
>;

/**
 * __useArchiveProjectMutation__
 *
 * To run a mutation, you first call `useArchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProjectMutation, { data, loading, error }] = useArchiveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ArchiveProjectMutation,
    Types.ArchiveProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ArchiveProjectMutation, Types.ArchiveProjectMutationVariables>(
    ArchiveProjectDocument,
    options,
  );
}
export type ArchiveProjectMutationHookResult = ReturnType<typeof useArchiveProjectMutation>;
export type ArchiveProjectMutationResult = Apollo.MutationResult<Types.ArchiveProjectMutation>;
export type ArchiveProjectMutationOptions = Apollo.BaseMutationOptions<
  Types.ArchiveProjectMutation,
  Types.ArchiveProjectMutationVariables
>;
export const BlockUserDocument = gql`
  mutation blockUser($id: ID!) {
    blockUser(id: $id) {
      id
    }
  }
`;
export type BlockUserMutationFn = Apollo.MutationFunction<
  Types.BlockUserMutation,
  Types.BlockUserMutationVariables
>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.BlockUserMutation,
    Types.BlockUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.BlockUserMutation, Types.BlockUserMutationVariables>(
    BlockUserDocument,
    options,
  );
}
export type BlockUserMutationHookResult = ReturnType<typeof useBlockUserMutation>;
export type BlockUserMutationResult = Apollo.MutationResult<Types.BlockUserMutation>;
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<
  Types.BlockUserMutation,
  Types.BlockUserMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation changePassword($newPassword: String!, $oldPassword: String!) {
    changePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
      ...user
    }
  }
  ${UserFragmentDoc}
  ${UserInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TeamBasicFragmentDoc}
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  Types.ChangePasswordMutation,
  Types.ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ChangePasswordMutation,
    Types.ChangePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ChangePasswordMutation, Types.ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    options,
  );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<Types.ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.ChangePasswordMutation,
  Types.ChangePasswordMutationVariables
>;
export const ChangeTicketAssigneeDocument = gql`
  mutation changeTicketAssignee($ticketId: ID!, $assignedUserId: ID!) {
    changeTicketAssignee(ticketId: $ticketId, assignedUserId: $assignedUserId) {
      ...ticket
    }
  }
  ${TicketFragmentDoc}
  ${UserWithPermInfoFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketFileFragmentDoc}
`;
export type ChangeTicketAssigneeMutationFn = Apollo.MutationFunction<
  Types.ChangeTicketAssigneeMutation,
  Types.ChangeTicketAssigneeMutationVariables
>;

/**
 * __useChangeTicketAssigneeMutation__
 *
 * To run a mutation, you first call `useChangeTicketAssigneeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTicketAssigneeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTicketAssigneeMutation, { data, loading, error }] = useChangeTicketAssigneeMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      assignedUserId: // value for 'assignedUserId'
 *   },
 * });
 */
export function useChangeTicketAssigneeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ChangeTicketAssigneeMutation,
    Types.ChangeTicketAssigneeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ChangeTicketAssigneeMutation,
    Types.ChangeTicketAssigneeMutationVariables
  >(ChangeTicketAssigneeDocument, options);
}
export type ChangeTicketAssigneeMutationHookResult = ReturnType<
  typeof useChangeTicketAssigneeMutation
>;
export type ChangeTicketAssigneeMutationResult =
  Apollo.MutationResult<Types.ChangeTicketAssigneeMutation>;
export type ChangeTicketAssigneeMutationOptions = Apollo.BaseMutationOptions<
  Types.ChangeTicketAssigneeMutation,
  Types.ChangeTicketAssigneeMutationVariables
>;
export const CreateCmsItemDocument = gql`
  mutation createCMSItem($value: String!) {
    createCMSItem(value: $value) {
      ...cms
    }
  }
  ${CmsFragmentDoc}
`;
export type CreateCmsItemMutationFn = Apollo.MutationFunction<
  Types.CreateCmsItemMutation,
  Types.CreateCmsItemMutationVariables
>;

/**
 * __useCreateCmsItemMutation__
 *
 * To run a mutation, you first call `useCreateCmsItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCmsItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCmsItemMutation, { data, loading, error }] = useCreateCmsItemMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateCmsItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateCmsItemMutation,
    Types.CreateCmsItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateCmsItemMutation, Types.CreateCmsItemMutationVariables>(
    CreateCmsItemDocument,
    options,
  );
}
export type CreateCmsItemMutationHookResult = ReturnType<typeof useCreateCmsItemMutation>;
export type CreateCmsItemMutationResult = Apollo.MutationResult<Types.CreateCmsItemMutation>;
export type CreateCmsItemMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCmsItemMutation,
  Types.CreateCmsItemMutationVariables
>;
export const CreateCredentialsDocument = gql`
  mutation createCredentials(
    $domainId: String!
    $host: String!
    $login: String!
    $name: String!
    $password: String!
    $comment: String
  ) {
    createCredentials(
      domainId: $domainId
      host: $host
      login: $login
      name: $name
      password: $password
      comment: $comment
    ) {
      ...credential
    }
  }
  ${CredentialFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type CreateCredentialsMutationFn = Apollo.MutationFunction<
  Types.CreateCredentialsMutation,
  Types.CreateCredentialsMutationVariables
>;

/**
 * __useCreateCredentialsMutation__
 *
 * To run a mutation, you first call `useCreateCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCredentialsMutation, { data, loading, error }] = useCreateCredentialsMutation({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      host: // value for 'host'
 *      login: // value for 'login'
 *      name: // value for 'name'
 *      password: // value for 'password'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateCredentialsMutation,
    Types.CreateCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateCredentialsMutation,
    Types.CreateCredentialsMutationVariables
  >(CreateCredentialsDocument, options);
}
export type CreateCredentialsMutationHookResult = ReturnType<typeof useCreateCredentialsMutation>;
export type CreateCredentialsMutationResult =
  Apollo.MutationResult<Types.CreateCredentialsMutation>;
export type CreateCredentialsMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCredentialsMutation,
  Types.CreateCredentialsMutationVariables
>;
export const CreateDealDocument = gql`
  mutation createDeal(
    $name: String!
    $startDate: DateTime!
    $realStartDate: DateTime
    $origStartDate: DateTime
    $origRealStartDate: DateTime
    $endDate: DateTime
    $analyticsMonthEndDate: DateTime
    $amount: Float!
    $maxBudget: Float!
    $active: Boolean!
    $url: String!
    $indefinite: Boolean!
    $activationAmount: Float!
    $currency: CurrencyEnum!
    $isLatest: Boolean!
    $type: DealTypeEnum!
    $product: DealCrmProductEnum
    $nofCreatedText: Int!
    $textReductionPercent: Int
    $previousDealId: ID
    $domainId: ID
    $ipressMonthly: Int
    $linkBuildingBudget: Float
    $lspTypeId: String
  ) {
    createDeal(
      name: $name
      startDate: $startDate
      realStartDate: $realStartDate
      origStartDate: $origStartDate
      origRealStartDate: $origRealStartDate
      endDate: $endDate
      analyticsMonthEndDate: $analyticsMonthEndDate
      amount: $amount
      maxBudget: $maxBudget
      active: $active
      url: $url
      indefinite: $indefinite
      activationAmount: $activationAmount
      currency: $currency
      isLatest: $isLatest
      type: $type
      product: $product
      nofCreatedText: $nofCreatedText
      textReductionPercent: $textReductionPercent
      previousDealId: $previousDealId
      domainId: $domainId
      ipressMonthly: $ipressMonthly
      linkBuildingBudget: $linkBuildingBudget
      lspTypeId: $lspTypeId
    ) {
      ...deal
    }
  }
  ${DealFragmentDoc}
  ${LspTypeFragmentDoc}
  ${DiscountFragmentDoc}
  ${DealTerminationFragmentDoc}
`;
export type CreateDealMutationFn = Apollo.MutationFunction<
  Types.CreateDealMutation,
  Types.CreateDealMutationVariables
>;

/**
 * __useCreateDealMutation__
 *
 * To run a mutation, you first call `useCreateDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDealMutation, { data, loading, error }] = useCreateDealMutation({
 *   variables: {
 *      name: // value for 'name'
 *      startDate: // value for 'startDate'
 *      realStartDate: // value for 'realStartDate'
 *      origStartDate: // value for 'origStartDate'
 *      origRealStartDate: // value for 'origRealStartDate'
 *      endDate: // value for 'endDate'
 *      analyticsMonthEndDate: // value for 'analyticsMonthEndDate'
 *      amount: // value for 'amount'
 *      maxBudget: // value for 'maxBudget'
 *      active: // value for 'active'
 *      url: // value for 'url'
 *      indefinite: // value for 'indefinite'
 *      activationAmount: // value for 'activationAmount'
 *      currency: // value for 'currency'
 *      isLatest: // value for 'isLatest'
 *      type: // value for 'type'
 *      product: // value for 'product'
 *      nofCreatedText: // value for 'nofCreatedText'
 *      textReductionPercent: // value for 'textReductionPercent'
 *      previousDealId: // value for 'previousDealId'
 *      domainId: // value for 'domainId'
 *      ipressMonthly: // value for 'ipressMonthly'
 *      linkBuildingBudget: // value for 'linkBuildingBudget'
 *      lspTypeId: // value for 'lspTypeId'
 *   },
 * });
 */
export function useCreateDealMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateDealMutation,
    Types.CreateDealMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateDealMutation, Types.CreateDealMutationVariables>(
    CreateDealDocument,
    options,
  );
}
export type CreateDealMutationHookResult = ReturnType<typeof useCreateDealMutation>;
export type CreateDealMutationResult = Apollo.MutationResult<Types.CreateDealMutation>;
export type CreateDealMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateDealMutation,
  Types.CreateDealMutationVariables
>;
export const CreateDomainAdsBudgetDocument = gql`
  mutation createDomainAdsBudget($input: DomainAdsBudgetInput!) {
    createDomainAdsBudget(input: $input) {
      ...domainAdsBudget
    }
  }
  ${DomainAdsBudgetFragmentDoc}
`;
export type CreateDomainAdsBudgetMutationFn = Apollo.MutationFunction<
  Types.CreateDomainAdsBudgetMutation,
  Types.CreateDomainAdsBudgetMutationVariables
>;

/**
 * __useCreateDomainAdsBudgetMutation__
 *
 * To run a mutation, you first call `useCreateDomainAdsBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDomainAdsBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDomainAdsBudgetMutation, { data, loading, error }] = useCreateDomainAdsBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDomainAdsBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateDomainAdsBudgetMutation,
    Types.CreateDomainAdsBudgetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateDomainAdsBudgetMutation,
    Types.CreateDomainAdsBudgetMutationVariables
  >(CreateDomainAdsBudgetDocument, options);
}
export type CreateDomainAdsBudgetMutationHookResult = ReturnType<
  typeof useCreateDomainAdsBudgetMutation
>;
export type CreateDomainAdsBudgetMutationResult =
  Apollo.MutationResult<Types.CreateDomainAdsBudgetMutation>;
export type CreateDomainAdsBudgetMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateDomainAdsBudgetMutation,
  Types.CreateDomainAdsBudgetMutationVariables
>;
export const CreateDomainReportDocument = gql`
  mutation createDomainReport(
    $domainId: ID!
    $fields: [DomainReportFieldInput!]!
    $language: LocaleEnum
    $reportType: DomainReportTypeEnum!
  ) {
    createDomainReport(
      domainId: $domainId
      fields: $fields
      language: $language
      reportType: $reportType
    ) {
      ...domainReport
    }
  }
  ${DomainReportFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type CreateDomainReportMutationFn = Apollo.MutationFunction<
  Types.CreateDomainReportMutation,
  Types.CreateDomainReportMutationVariables
>;

/**
 * __useCreateDomainReportMutation__
 *
 * To run a mutation, you first call `useCreateDomainReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDomainReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDomainReportMutation, { data, loading, error }] = useCreateDomainReportMutation({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      fields: // value for 'fields'
 *      language: // value for 'language'
 *      reportType: // value for 'reportType'
 *   },
 * });
 */
export function useCreateDomainReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateDomainReportMutation,
    Types.CreateDomainReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateDomainReportMutation,
    Types.CreateDomainReportMutationVariables
  >(CreateDomainReportDocument, options);
}
export type CreateDomainReportMutationHookResult = ReturnType<typeof useCreateDomainReportMutation>;
export type CreateDomainReportMutationResult =
  Apollo.MutationResult<Types.CreateDomainReportMutation>;
export type CreateDomainReportMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateDomainReportMutation,
  Types.CreateDomainReportMutationVariables
>;
export const CreateGamInternalAccountDocument = gql`
  mutation createGAMInternalAccount($name: String!) {
    createGAMInternalAccount(name: $name) {
      ...gamInternalAccount
    }
  }
  ${GamInternalAccountFragmentDoc}
`;
export type CreateGamInternalAccountMutationFn = Apollo.MutationFunction<
  Types.CreateGamInternalAccountMutation,
  Types.CreateGamInternalAccountMutationVariables
>;

/**
 * __useCreateGamInternalAccountMutation__
 *
 * To run a mutation, you first call `useCreateGamInternalAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGamInternalAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGamInternalAccountMutation, { data, loading, error }] = useCreateGamInternalAccountMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateGamInternalAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateGamInternalAccountMutation,
    Types.CreateGamInternalAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateGamInternalAccountMutation,
    Types.CreateGamInternalAccountMutationVariables
  >(CreateGamInternalAccountDocument, options);
}
export type CreateGamInternalAccountMutationHookResult = ReturnType<
  typeof useCreateGamInternalAccountMutation
>;
export type CreateGamInternalAccountMutationResult =
  Apollo.MutationResult<Types.CreateGamInternalAccountMutation>;
export type CreateGamInternalAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateGamInternalAccountMutation,
  Types.CreateGamInternalAccountMutationVariables
>;
export const CreateLinkDocument = gql`
  mutation createLink(
    $group: LinkGroupEnum!
    $status: LinkStatusEnum!
    $portal: String
    $price: Float!
    $articleUrl: String
    $characterCount: Int
    $ownerId: ID!
    $plannedPublicationDate: DateTime
    $realPublicationDate: DateTime
    $orderDate: DateTime
    $textDeliveryDate: DateTime
    $note: String
    $textUrl: String
    $copywriterId: ID
    $domainId: ID
    $anchors: [LinkAnchorDTOInput!]
  ) {
    createLink(
      group: $group
      status: $status
      portal: $portal
      price: $price
      articleUrl: $articleUrl
      characterCount: $characterCount
      ownerId: $ownerId
      plannedPublicationDate: $plannedPublicationDate
      realPublicationDate: $realPublicationDate
      orderDate: $orderDate
      textDeliveryDate: $textDeliveryDate
      note: $note
      textUrl: $textUrl
      copywriterId: $copywriterId
      domainId: $domainId
      anchors: $anchors
    ) {
      ...link
    }
  }
  ${LinkFragmentDoc}
  ${LinkInTheListFragmentDoc}
  ${LinkBasicFragmentDoc}
  ${LinkAnchorBasicFragmentDoc}
  ${DomainBasicFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type CreateLinkMutationFn = Apollo.MutationFunction<
  Types.CreateLinkMutation,
  Types.CreateLinkMutationVariables
>;

/**
 * __useCreateLinkMutation__
 *
 * To run a mutation, you first call `useCreateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkMutation, { data, loading, error }] = useCreateLinkMutation({
 *   variables: {
 *      group: // value for 'group'
 *      status: // value for 'status'
 *      portal: // value for 'portal'
 *      price: // value for 'price'
 *      articleUrl: // value for 'articleUrl'
 *      characterCount: // value for 'characterCount'
 *      ownerId: // value for 'ownerId'
 *      plannedPublicationDate: // value for 'plannedPublicationDate'
 *      realPublicationDate: // value for 'realPublicationDate'
 *      orderDate: // value for 'orderDate'
 *      textDeliveryDate: // value for 'textDeliveryDate'
 *      note: // value for 'note'
 *      textUrl: // value for 'textUrl'
 *      copywriterId: // value for 'copywriterId'
 *      domainId: // value for 'domainId'
 *      anchors: // value for 'anchors'
 *   },
 * });
 */
export function useCreateLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateLinkMutation,
    Types.CreateLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateLinkMutation, Types.CreateLinkMutationVariables>(
    CreateLinkDocument,
    options,
  );
}
export type CreateLinkMutationHookResult = ReturnType<typeof useCreateLinkMutation>;
export type CreateLinkMutationResult = Apollo.MutationResult<Types.CreateLinkMutation>;
export type CreateLinkMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateLinkMutation,
  Types.CreateLinkMutationVariables
>;
export const CreateLspTypeDocument = gql`
  mutation createLspType($value: String!) {
    createLspType(value: $value) {
      ...lspType
    }
  }
  ${LspTypeFragmentDoc}
`;
export type CreateLspTypeMutationFn = Apollo.MutationFunction<
  Types.CreateLspTypeMutation,
  Types.CreateLspTypeMutationVariables
>;

/**
 * __useCreateLspTypeMutation__
 *
 * To run a mutation, you first call `useCreateLspTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLspTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLspTypeMutation, { data, loading, error }] = useCreateLspTypeMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateLspTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateLspTypeMutation,
    Types.CreateLspTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateLspTypeMutation, Types.CreateLspTypeMutationVariables>(
    CreateLspTypeDocument,
    options,
  );
}
export type CreateLspTypeMutationHookResult = ReturnType<typeof useCreateLspTypeMutation>;
export type CreateLspTypeMutationResult = Apollo.MutationResult<Types.CreateLspTypeMutation>;
export type CreateLspTypeMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateLspTypeMutation,
  Types.CreateLspTypeMutationVariables
>;
export const CreateMonitorDocument = gql`
  mutation createMonitor($domainId: String!, $url: String!) {
    createMonitor(domainId: $domainId, url: $url) {
      ...monitor
    }
  }
  ${MonitorFragmentDoc}
`;
export type CreateMonitorMutationFn = Apollo.MutationFunction<
  Types.CreateMonitorMutation,
  Types.CreateMonitorMutationVariables
>;

/**
 * __useCreateMonitorMutation__
 *
 * To run a mutation, you first call `useCreateMonitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMonitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMonitorMutation, { data, loading, error }] = useCreateMonitorMutation({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useCreateMonitorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateMonitorMutation,
    Types.CreateMonitorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateMonitorMutation, Types.CreateMonitorMutationVariables>(
    CreateMonitorDocument,
    options,
  );
}
export type CreateMonitorMutationHookResult = ReturnType<typeof useCreateMonitorMutation>;
export type CreateMonitorMutationResult = Apollo.MutationResult<Types.CreateMonitorMutation>;
export type CreateMonitorMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateMonitorMutation,
  Types.CreateMonitorMutationVariables
>;
export const CreatePhraseGroupDocument = gql`
  mutation createPhraseGroup($name: String!, $domainId: String!, $phraseIds: [String!]) {
    createPhraseGroup(name: $name, domainId: $domainId, phraseIds: $phraseIds) {
      ...phraseGroup
    }
  }
  ${PhraseGroupFragmentDoc}
`;
export type CreatePhraseGroupMutationFn = Apollo.MutationFunction<
  Types.CreatePhraseGroupMutation,
  Types.CreatePhraseGroupMutationVariables
>;

/**
 * __useCreatePhraseGroupMutation__
 *
 * To run a mutation, you first call `useCreatePhraseGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhraseGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhraseGroupMutation, { data, loading, error }] = useCreatePhraseGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      domainId: // value for 'domainId'
 *      phraseIds: // value for 'phraseIds'
 *   },
 * });
 */
export function useCreatePhraseGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreatePhraseGroupMutation,
    Types.CreatePhraseGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreatePhraseGroupMutation,
    Types.CreatePhraseGroupMutationVariables
  >(CreatePhraseGroupDocument, options);
}
export type CreatePhraseGroupMutationHookResult = ReturnType<typeof useCreatePhraseGroupMutation>;
export type CreatePhraseGroupMutationResult =
  Apollo.MutationResult<Types.CreatePhraseGroupMutation>;
export type CreatePhraseGroupMutationOptions = Apollo.BaseMutationOptions<
  Types.CreatePhraseGroupMutation,
  Types.CreatePhraseGroupMutationVariables
>;
export const CreatePhrasesDocument = gql`
  mutation createPhrases($phrases: [PhraseDTOInput!]!, $domainId: String!, $groupId: String) {
    createPhrases(phrases: $phrases, domainId: $domainId, groupId: $groupId) {
      id
    }
  }
`;
export type CreatePhrasesMutationFn = Apollo.MutationFunction<
  Types.CreatePhrasesMutation,
  Types.CreatePhrasesMutationVariables
>;

/**
 * __useCreatePhrasesMutation__
 *
 * To run a mutation, you first call `useCreatePhrasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhrasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhrasesMutation, { data, loading, error }] = useCreatePhrasesMutation({
 *   variables: {
 *      phrases: // value for 'phrases'
 *      domainId: // value for 'domainId'
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useCreatePhrasesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreatePhrasesMutation,
    Types.CreatePhrasesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreatePhrasesMutation, Types.CreatePhrasesMutationVariables>(
    CreatePhrasesDocument,
    options,
  );
}
export type CreatePhrasesMutationHookResult = ReturnType<typeof useCreatePhrasesMutation>;
export type CreatePhrasesMutationResult = Apollo.MutationResult<Types.CreatePhrasesMutation>;
export type CreatePhrasesMutationOptions = Apollo.BaseMutationOptions<
  Types.CreatePhrasesMutation,
  Types.CreatePhrasesMutationVariables
>;
export const CreateProjectDocument = gql`
  mutation createProject($name: String!, $content: String, $priority: Float, $archived: Boolean) {
    createProject(name: $name, content: $content, priority: $priority, archived: $archived) {
      ...project
    }
  }
  ${ProjectFragmentDoc}
  ${TypeFragmentDoc}
  ${StatusFragmentDoc}
  ${PriorityFragmentDoc}
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  Types.CreateProjectMutation,
  Types.CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      content: // value for 'content'
 *      priority: // value for 'priority'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateProjectMutation,
    Types.CreateProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateProjectMutation, Types.CreateProjectMutationVariables>(
    CreateProjectDocument,
    options,
  );
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<Types.CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateProjectMutation,
  Types.CreateProjectMutationVariables
>;
export const CreateQuarterOverrideDocument = gql`
  mutation createQuarterOverride(
    $domainId: ID!
    $startDate: DateTime!
    $endDate: DateTime!
    $type: QuarterTypeEnum!
    $charactersToCreate: Int!
    $number: Int!
  ) {
    createQuarterOverride(
      domainId: $domainId
      startDate: $startDate
      endDate: $endDate
      type: $type
      charactersToCreate: $charactersToCreate
      number: $number
    ) {
      ...quarterOverride
    }
  }
  ${QuarterOverrideFragmentDoc}
`;
export type CreateQuarterOverrideMutationFn = Apollo.MutationFunction<
  Types.CreateQuarterOverrideMutation,
  Types.CreateQuarterOverrideMutationVariables
>;

/**
 * __useCreateQuarterOverrideMutation__
 *
 * To run a mutation, you first call `useCreateQuarterOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuarterOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuarterOverrideMutation, { data, loading, error }] = useCreateQuarterOverrideMutation({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      type: // value for 'type'
 *      charactersToCreate: // value for 'charactersToCreate'
 *      number: // value for 'number'
 *   },
 * });
 */
export function useCreateQuarterOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateQuarterOverrideMutation,
    Types.CreateQuarterOverrideMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateQuarterOverrideMutation,
    Types.CreateQuarterOverrideMutationVariables
  >(CreateQuarterOverrideDocument, options);
}
export type CreateQuarterOverrideMutationHookResult = ReturnType<
  typeof useCreateQuarterOverrideMutation
>;
export type CreateQuarterOverrideMutationResult =
  Apollo.MutationResult<Types.CreateQuarterOverrideMutation>;
export type CreateQuarterOverrideMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateQuarterOverrideMutation,
  Types.CreateQuarterOverrideMutationVariables
>;
export const CreateRoleDocument = gql`
  mutation createRole($title: String!, $permissions: [PermissionEnum!]) {
    createRole(title: $title, permissions: $permissions) {
      ...role
    }
  }
  ${RoleFragmentDoc}
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<
  Types.CreateRoleMutation,
  Types.CreateRoleMutationVariables
>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      title: // value for 'title'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useCreateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateRoleMutation,
    Types.CreateRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateRoleMutation, Types.CreateRoleMutationVariables>(
    CreateRoleDocument,
    options,
  );
}
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<Types.CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateRoleMutation,
  Types.CreateRoleMutationVariables
>;
export const CreateS4sInstanceDocument = gql`
  mutation createS4sInstance(
    $name: String!
    $host: String!
    $port: Int!
    $user: String!
    $password: String!
    $db: String!
    $prefix: String!
  ) {
    createS4sInstance(
      name: $name
      host: $host
      port: $port
      user: $user
      password: $password
      db: $db
      prefix: $prefix
    ) {
      ...s4sInstance
    }
  }
  ${S4sInstanceFragmentDoc}
`;
export type CreateS4sInstanceMutationFn = Apollo.MutationFunction<
  Types.CreateS4sInstanceMutation,
  Types.CreateS4sInstanceMutationVariables
>;

/**
 * __useCreateS4sInstanceMutation__
 *
 * To run a mutation, you first call `useCreateS4sInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateS4sInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createS4sInstanceMutation, { data, loading, error }] = useCreateS4sInstanceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      host: // value for 'host'
 *      port: // value for 'port'
 *      user: // value for 'user'
 *      password: // value for 'password'
 *      db: // value for 'db'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useCreateS4sInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateS4sInstanceMutation,
    Types.CreateS4sInstanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateS4sInstanceMutation,
    Types.CreateS4sInstanceMutationVariables
  >(CreateS4sInstanceDocument, options);
}
export type CreateS4sInstanceMutationHookResult = ReturnType<typeof useCreateS4sInstanceMutation>;
export type CreateS4sInstanceMutationResult =
  Apollo.MutationResult<Types.CreateS4sInstanceMutation>;
export type CreateS4sInstanceMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateS4sInstanceMutation,
  Types.CreateS4sInstanceMutationVariables
>;
export const CreateSubpageDocument = gql`
  mutation createSubpage(
    $domainId: String!
    $url: String!
    $type: SubpageTypeEnum!
    $status: SubpageStatusEnum!
    $priority: SubpagePriorityEnum!
    $title: String!
    $heading: String
    $description: String
    $characterCount: Int
    $targetTitle: String
    $targetHeading: String
    $targetDescription: String
    $targetCharacterCount: Int
    $keywords: [String!]!
  ) {
    createSubpage(
      input: {
        domainId: $domainId
        url: $url
        type: $type
        status: $status
        priority: $priority
        title: $title
        heading: $heading
        description: $description
        characterCount: $characterCount
        targetTitle: $targetTitle
        targetHeading: $targetHeading
        targetDescription: $targetDescription
        targetCharacterCount: $targetCharacterCount
        keywords: $keywords
      }
    ) {
      ...subpage
    }
  }
  ${SubpageFragmentDoc}
`;
export type CreateSubpageMutationFn = Apollo.MutationFunction<
  Types.CreateSubpageMutation,
  Types.CreateSubpageMutationVariables
>;

/**
 * __useCreateSubpageMutation__
 *
 * To run a mutation, you first call `useCreateSubpageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubpageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubpageMutation, { data, loading, error }] = useCreateSubpageMutation({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      url: // value for 'url'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      priority: // value for 'priority'
 *      title: // value for 'title'
 *      heading: // value for 'heading'
 *      description: // value for 'description'
 *      characterCount: // value for 'characterCount'
 *      targetTitle: // value for 'targetTitle'
 *      targetHeading: // value for 'targetHeading'
 *      targetDescription: // value for 'targetDescription'
 *      targetCharacterCount: // value for 'targetCharacterCount'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useCreateSubpageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateSubpageMutation,
    Types.CreateSubpageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateSubpageMutation, Types.CreateSubpageMutationVariables>(
    CreateSubpageDocument,
    options,
  );
}
export type CreateSubpageMutationHookResult = ReturnType<typeof useCreateSubpageMutation>;
export type CreateSubpageMutationResult = Apollo.MutationResult<Types.CreateSubpageMutation>;
export type CreateSubpageMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateSubpageMutation,
  Types.CreateSubpageMutationVariables
>;
export const CreateTeamDocument = gql`
  mutation createTeam($name: String!, $leaderId: ID!, $memberIds: [ID!]!) {
    createTeam(name: $name, leaderId: $leaderId, memberIds: $memberIds) {
      ...team
    }
  }
  ${TeamFragmentDoc}
  ${UserInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type CreateTeamMutationFn = Apollo.MutationFunction<
  Types.CreateTeamMutation,
  Types.CreateTeamMutationVariables
>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *      leaderId: // value for 'leaderId'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useCreateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTeamMutation,
    Types.CreateTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateTeamMutation, Types.CreateTeamMutationVariables>(
    CreateTeamDocument,
    options,
  );
}
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<Types.CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTeamMutation,
  Types.CreateTeamMutationVariables
>;
export const CreateTextOrderGuidelineDocument = gql`
  mutation createTextOrderGuideline($input: CreateTextOrderGuidelineInput!) {
    createTextOrderGuideline(input: $input) {
      ...textOrderGuideline
    }
  }
  ${TextOrderGuidelineFragmentDoc}
`;
export type CreateTextOrderGuidelineMutationFn = Apollo.MutationFunction<
  Types.CreateTextOrderGuidelineMutation,
  Types.CreateTextOrderGuidelineMutationVariables
>;

/**
 * __useCreateTextOrderGuidelineMutation__
 *
 * To run a mutation, you first call `useCreateTextOrderGuidelineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTextOrderGuidelineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTextOrderGuidelineMutation, { data, loading, error }] = useCreateTextOrderGuidelineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTextOrderGuidelineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTextOrderGuidelineMutation,
    Types.CreateTextOrderGuidelineMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateTextOrderGuidelineMutation,
    Types.CreateTextOrderGuidelineMutationVariables
  >(CreateTextOrderGuidelineDocument, options);
}
export type CreateTextOrderGuidelineMutationHookResult = ReturnType<
  typeof useCreateTextOrderGuidelineMutation
>;
export type CreateTextOrderGuidelineMutationResult =
  Apollo.MutationResult<Types.CreateTextOrderGuidelineMutation>;
export type CreateTextOrderGuidelineMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTextOrderGuidelineMutation,
  Types.CreateTextOrderGuidelineMutationVariables
>;
export const CreateTextOrdersDocument = gql`
  mutation createTextOrders($inputs: [CreateTextOrdersDTOInput!]!) {
    createTextOrders(inputs: $inputs) {
      ...textOrder
    }
  }
  ${TextOrderFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type CreateTextOrdersMutationFn = Apollo.MutationFunction<
  Types.CreateTextOrdersMutation,
  Types.CreateTextOrdersMutationVariables
>;

/**
 * __useCreateTextOrdersMutation__
 *
 * To run a mutation, you first call `useCreateTextOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTextOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTextOrdersMutation, { data, loading, error }] = useCreateTextOrdersMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateTextOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTextOrdersMutation,
    Types.CreateTextOrdersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateTextOrdersMutation,
    Types.CreateTextOrdersMutationVariables
  >(CreateTextOrdersDocument, options);
}
export type CreateTextOrdersMutationHookResult = ReturnType<typeof useCreateTextOrdersMutation>;
export type CreateTextOrdersMutationResult = Apollo.MutationResult<Types.CreateTextOrdersMutation>;
export type CreateTextOrdersMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTextOrdersMutation,
  Types.CreateTextOrdersMutationVariables
>;
export const CreateTicketDocument = gql`
  mutation createTicket(
    $title: String!
    $contentHtml: String
    $priorityId: ID!
    $typeId: ID!
    $statusId: ID!
    $projectId: ID!
    $assignedUserId: ID
    $deadline: DateTime
    $domainId: ID
    $start: DateTime
    $watchers: [ID!]
    $todos: [TodoInput!]
    $ownerTicketId: ID
    $sendingToClientTextOrders: [ID!]
    $implementationTextOrders: [ID!]
  ) {
    createTicket(
      title: $title
      contentHtml: $contentHtml
      priorityId: $priorityId
      typeId: $typeId
      statusId: $statusId
      projectId: $projectId
      assignedUserId: $assignedUserId
      deadline: $deadline
      domainId: $domainId
      start: $start
      watchers: $watchers
      todos: $todos
      ownerTicketId: $ownerTicketId
      sendingToClientTextOrders: $sendingToClientTextOrders
      implementationTextOrders: $implementationTextOrders
    ) {
      ...ticket
    }
  }
  ${TicketFragmentDoc}
  ${UserWithPermInfoFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketFileFragmentDoc}
`;
export type CreateTicketMutationFn = Apollo.MutationFunction<
  Types.CreateTicketMutation,
  Types.CreateTicketMutationVariables
>;

/**
 * __useCreateTicketMutation__
 *
 * To run a mutation, you first call `useCreateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketMutation, { data, loading, error }] = useCreateTicketMutation({
 *   variables: {
 *      title: // value for 'title'
 *      contentHtml: // value for 'contentHtml'
 *      priorityId: // value for 'priorityId'
 *      typeId: // value for 'typeId'
 *      statusId: // value for 'statusId'
 *      projectId: // value for 'projectId'
 *      assignedUserId: // value for 'assignedUserId'
 *      deadline: // value for 'deadline'
 *      domainId: // value for 'domainId'
 *      start: // value for 'start'
 *      watchers: // value for 'watchers'
 *      todos: // value for 'todos'
 *      ownerTicketId: // value for 'ownerTicketId'
 *      sendingToClientTextOrders: // value for 'sendingToClientTextOrders'
 *      implementationTextOrders: // value for 'implementationTextOrders'
 *   },
 * });
 */
export function useCreateTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTicketMutation,
    Types.CreateTicketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateTicketMutation, Types.CreateTicketMutationVariables>(
    CreateTicketDocument,
    options,
  );
}
export type CreateTicketMutationHookResult = ReturnType<typeof useCreateTicketMutation>;
export type CreateTicketMutationResult = Apollo.MutationResult<Types.CreateTicketMutation>;
export type CreateTicketMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTicketMutation,
  Types.CreateTicketMutationVariables
>;
export const CreateTicketCommentDocument = gql`
  mutation createTicketComment($ticketId: ID!, $contentHtml: String!) {
    createTicketComment(ticketId: $ticketId, contentHtml: $contentHtml) {
      ...ticketComment
    }
  }
  ${TicketCommentFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketCommentFileFragmentDoc}
`;
export type CreateTicketCommentMutationFn = Apollo.MutationFunction<
  Types.CreateTicketCommentMutation,
  Types.CreateTicketCommentMutationVariables
>;

/**
 * __useCreateTicketCommentMutation__
 *
 * To run a mutation, you first call `useCreateTicketCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketCommentMutation, { data, loading, error }] = useCreateTicketCommentMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      contentHtml: // value for 'contentHtml'
 *   },
 * });
 */
export function useCreateTicketCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTicketCommentMutation,
    Types.CreateTicketCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateTicketCommentMutation,
    Types.CreateTicketCommentMutationVariables
  >(CreateTicketCommentDocument, options);
}
export type CreateTicketCommentMutationHookResult = ReturnType<
  typeof useCreateTicketCommentMutation
>;
export type CreateTicketCommentMutationResult =
  Apollo.MutationResult<Types.CreateTicketCommentMutation>;
export type CreateTicketCommentMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTicketCommentMutation,
  Types.CreateTicketCommentMutationVariables
>;
export const CreateTicketDraftDocument = gql`
  mutation createTicketDraft {
    createTicketDraft {
      ...ticket
    }
  }
  ${TicketFragmentDoc}
  ${UserWithPermInfoFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketFileFragmentDoc}
`;
export type CreateTicketDraftMutationFn = Apollo.MutationFunction<
  Types.CreateTicketDraftMutation,
  Types.CreateTicketDraftMutationVariables
>;

/**
 * __useCreateTicketDraftMutation__
 *
 * To run a mutation, you first call `useCreateTicketDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketDraftMutation, { data, loading, error }] = useCreateTicketDraftMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateTicketDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTicketDraftMutation,
    Types.CreateTicketDraftMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateTicketDraftMutation,
    Types.CreateTicketDraftMutationVariables
  >(CreateTicketDraftDocument, options);
}
export type CreateTicketDraftMutationHookResult = ReturnType<typeof useCreateTicketDraftMutation>;
export type CreateTicketDraftMutationResult =
  Apollo.MutationResult<Types.CreateTicketDraftMutation>;
export type CreateTicketDraftMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTicketDraftMutation,
  Types.CreateTicketDraftMutationVariables
>;
export const CreateTicketNoDataDocument = gql`
  mutation createTicketNoData(
    $title: String!
    $contentHtml: String
    $priorityId: ID!
    $typeId: ID!
    $statusId: ID!
    $projectId: ID!
    $assignedUserId: ID
    $deadline: DateTime
    $domainId: ID
    $start: DateTime!
    $watchers: [ID!]
    $todos: [TodoInput!]
  ) {
    createTicket(
      title: $title
      contentHtml: $contentHtml
      priorityId: $priorityId
      typeId: $typeId
      statusId: $statusId
      projectId: $projectId
      assignedUserId: $assignedUserId
      deadline: $deadline
      domainId: $domainId
      start: $start
      watchers: $watchers
      todos: $todos
    ) {
      id
      title
      domain {
        id
        name
      }
    }
  }
`;
export type CreateTicketNoDataMutationFn = Apollo.MutationFunction<
  Types.CreateTicketNoDataMutation,
  Types.CreateTicketNoDataMutationVariables
>;

/**
 * __useCreateTicketNoDataMutation__
 *
 * To run a mutation, you first call `useCreateTicketNoDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTicketNoDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTicketNoDataMutation, { data, loading, error }] = useCreateTicketNoDataMutation({
 *   variables: {
 *      title: // value for 'title'
 *      contentHtml: // value for 'contentHtml'
 *      priorityId: // value for 'priorityId'
 *      typeId: // value for 'typeId'
 *      statusId: // value for 'statusId'
 *      projectId: // value for 'projectId'
 *      assignedUserId: // value for 'assignedUserId'
 *      deadline: // value for 'deadline'
 *      domainId: // value for 'domainId'
 *      start: // value for 'start'
 *      watchers: // value for 'watchers'
 *      todos: // value for 'todos'
 *   },
 * });
 */
export function useCreateTicketNoDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateTicketNoDataMutation,
    Types.CreateTicketNoDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateTicketNoDataMutation,
    Types.CreateTicketNoDataMutationVariables
  >(CreateTicketNoDataDocument, options);
}
export type CreateTicketNoDataMutationHookResult = ReturnType<typeof useCreateTicketNoDataMutation>;
export type CreateTicketNoDataMutationResult =
  Apollo.MutationResult<Types.CreateTicketNoDataMutation>;
export type CreateTicketNoDataMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateTicketNoDataMutation,
  Types.CreateTicketNoDataMutationVariables
>;
export const CreateUserDocument = gql`
  mutation createUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String
    $role: String
    $permissions: [PermissionEnum!]
  ) {
    createUser(
      userInput: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        role: $role
        permissions: $permissions
      }
    ) {
      ...user
    }
  }
  ${UserFragmentDoc}
  ${UserInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TeamBasicFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  Types.CreateUserMutation,
  Types.CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      role: // value for 'role'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateUserMutation,
    Types.CreateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateUserMutation, Types.CreateUserMutationVariables>(
    CreateUserDocument,
    options,
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<Types.CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateUserMutation,
  Types.CreateUserMutationVariables
>;
export const DeleteCmsItemDocument = gql`
  mutation deleteCMSItem($id: ID!) {
    deleteCMSItem(id: $id) {
      id
    }
  }
`;
export type DeleteCmsItemMutationFn = Apollo.MutationFunction<
  Types.DeleteCmsItemMutation,
  Types.DeleteCmsItemMutationVariables
>;

/**
 * __useDeleteCmsItemMutation__
 *
 * To run a mutation, you first call `useDeleteCmsItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCmsItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCmsItemMutation, { data, loading, error }] = useDeleteCmsItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCmsItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteCmsItemMutation,
    Types.DeleteCmsItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteCmsItemMutation, Types.DeleteCmsItemMutationVariables>(
    DeleteCmsItemDocument,
    options,
  );
}
export type DeleteCmsItemMutationHookResult = ReturnType<typeof useDeleteCmsItemMutation>;
export type DeleteCmsItemMutationResult = Apollo.MutationResult<Types.DeleteCmsItemMutation>;
export type DeleteCmsItemMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteCmsItemMutation,
  Types.DeleteCmsItemMutationVariables
>;
export const DeleteCredentialAttachmentDocument = gql`
  mutation deleteCredentialAttachment($id: ID!) {
    deleteCredentialAttachment(id: $id) {
      id
    }
  }
`;
export type DeleteCredentialAttachmentMutationFn = Apollo.MutationFunction<
  Types.DeleteCredentialAttachmentMutation,
  Types.DeleteCredentialAttachmentMutationVariables
>;

/**
 * __useDeleteCredentialAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteCredentialAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCredentialAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCredentialAttachmentMutation, { data, loading, error }] = useDeleteCredentialAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCredentialAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteCredentialAttachmentMutation,
    Types.DeleteCredentialAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteCredentialAttachmentMutation,
    Types.DeleteCredentialAttachmentMutationVariables
  >(DeleteCredentialAttachmentDocument, options);
}
export type DeleteCredentialAttachmentMutationHookResult = ReturnType<
  typeof useDeleteCredentialAttachmentMutation
>;
export type DeleteCredentialAttachmentMutationResult =
  Apollo.MutationResult<Types.DeleteCredentialAttachmentMutation>;
export type DeleteCredentialAttachmentMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteCredentialAttachmentMutation,
  Types.DeleteCredentialAttachmentMutationVariables
>;
export const DeleteCredentialsDocument = gql`
  mutation deleteCredentials($id: String!) {
    deleteCredentials(id: $id) {
      id
      name
    }
  }
`;
export type DeleteCredentialsMutationFn = Apollo.MutationFunction<
  Types.DeleteCredentialsMutation,
  Types.DeleteCredentialsMutationVariables
>;

/**
 * __useDeleteCredentialsMutation__
 *
 * To run a mutation, you first call `useDeleteCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCredentialsMutation, { data, loading, error }] = useDeleteCredentialsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteCredentialsMutation,
    Types.DeleteCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteCredentialsMutation,
    Types.DeleteCredentialsMutationVariables
  >(DeleteCredentialsDocument, options);
}
export type DeleteCredentialsMutationHookResult = ReturnType<typeof useDeleteCredentialsMutation>;
export type DeleteCredentialsMutationResult =
  Apollo.MutationResult<Types.DeleteCredentialsMutation>;
export type DeleteCredentialsMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteCredentialsMutation,
  Types.DeleteCredentialsMutationVariables
>;
export const DeleteGamInternalAccountDocument = gql`
  mutation deleteGAMInternalAccount($id: ID!) {
    deleteGAMInternalAccount(id: $id) {
      id
    }
  }
`;
export type DeleteGamInternalAccountMutationFn = Apollo.MutationFunction<
  Types.DeleteGamInternalAccountMutation,
  Types.DeleteGamInternalAccountMutationVariables
>;

/**
 * __useDeleteGamInternalAccountMutation__
 *
 * To run a mutation, you first call `useDeleteGamInternalAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGamInternalAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGamInternalAccountMutation, { data, loading, error }] = useDeleteGamInternalAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGamInternalAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteGamInternalAccountMutation,
    Types.DeleteGamInternalAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteGamInternalAccountMutation,
    Types.DeleteGamInternalAccountMutationVariables
  >(DeleteGamInternalAccountDocument, options);
}
export type DeleteGamInternalAccountMutationHookResult = ReturnType<
  typeof useDeleteGamInternalAccountMutation
>;
export type DeleteGamInternalAccountMutationResult =
  Apollo.MutationResult<Types.DeleteGamInternalAccountMutation>;
export type DeleteGamInternalAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteGamInternalAccountMutation,
  Types.DeleteGamInternalAccountMutationVariables
>;
export const DeleteLinkDocument = gql`
  mutation deleteLink($id: ID!) {
    deleteLink(id: $id) {
      id
    }
  }
`;
export type DeleteLinkMutationFn = Apollo.MutationFunction<
  Types.DeleteLinkMutation,
  Types.DeleteLinkMutationVariables
>;

/**
 * __useDeleteLinkMutation__
 *
 * To run a mutation, you first call `useDeleteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLinkMutation, { data, loading, error }] = useDeleteLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteLinkMutation,
    Types.DeleteLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteLinkMutation, Types.DeleteLinkMutationVariables>(
    DeleteLinkDocument,
    options,
  );
}
export type DeleteLinkMutationHookResult = ReturnType<typeof useDeleteLinkMutation>;
export type DeleteLinkMutationResult = Apollo.MutationResult<Types.DeleteLinkMutation>;
export type DeleteLinkMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteLinkMutation,
  Types.DeleteLinkMutationVariables
>;
export const DeleteLspTypeDocument = gql`
  mutation deleteLspType($id: ID!) {
    deleteLspType(id: $id) {
      id
    }
  }
`;
export type DeleteLspTypeMutationFn = Apollo.MutationFunction<
  Types.DeleteLspTypeMutation,
  Types.DeleteLspTypeMutationVariables
>;

/**
 * __useDeleteLspTypeMutation__
 *
 * To run a mutation, you first call `useDeleteLspTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLspTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLspTypeMutation, { data, loading, error }] = useDeleteLspTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLspTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteLspTypeMutation,
    Types.DeleteLspTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteLspTypeMutation, Types.DeleteLspTypeMutationVariables>(
    DeleteLspTypeDocument,
    options,
  );
}
export type DeleteLspTypeMutationHookResult = ReturnType<typeof useDeleteLspTypeMutation>;
export type DeleteLspTypeMutationResult = Apollo.MutationResult<Types.DeleteLspTypeMutation>;
export type DeleteLspTypeMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteLspTypeMutation,
  Types.DeleteLspTypeMutationVariables
>;
export const DeleteMonitorDocument = gql`
  mutation deleteMonitor($id: String!) {
    deleteMonitor(id: $id)
  }
`;
export type DeleteMonitorMutationFn = Apollo.MutationFunction<
  Types.DeleteMonitorMutation,
  Types.DeleteMonitorMutationVariables
>;

/**
 * __useDeleteMonitorMutation__
 *
 * To run a mutation, you first call `useDeleteMonitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMonitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMonitorMutation, { data, loading, error }] = useDeleteMonitorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMonitorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteMonitorMutation,
    Types.DeleteMonitorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteMonitorMutation, Types.DeleteMonitorMutationVariables>(
    DeleteMonitorDocument,
    options,
  );
}
export type DeleteMonitorMutationHookResult = ReturnType<typeof useDeleteMonitorMutation>;
export type DeleteMonitorMutationResult = Apollo.MutationResult<Types.DeleteMonitorMutation>;
export type DeleteMonitorMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteMonitorMutation,
  Types.DeleteMonitorMutationVariables
>;
export const DeletePhraseGroupDocument = gql`
  mutation deletePhraseGroup($groupId: String!) {
    deletePhraseGroup(groupId: $groupId) {
      id
    }
  }
`;
export type DeletePhraseGroupMutationFn = Apollo.MutationFunction<
  Types.DeletePhraseGroupMutation,
  Types.DeletePhraseGroupMutationVariables
>;

/**
 * __useDeletePhraseGroupMutation__
 *
 * To run a mutation, you first call `useDeletePhraseGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhraseGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhraseGroupMutation, { data, loading, error }] = useDeletePhraseGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useDeletePhraseGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeletePhraseGroupMutation,
    Types.DeletePhraseGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeletePhraseGroupMutation,
    Types.DeletePhraseGroupMutationVariables
  >(DeletePhraseGroupDocument, options);
}
export type DeletePhraseGroupMutationHookResult = ReturnType<typeof useDeletePhraseGroupMutation>;
export type DeletePhraseGroupMutationResult =
  Apollo.MutationResult<Types.DeletePhraseGroupMutation>;
export type DeletePhraseGroupMutationOptions = Apollo.BaseMutationOptions<
  Types.DeletePhraseGroupMutation,
  Types.DeletePhraseGroupMutationVariables
>;
export const DeletePhrasesDocument = gql`
  mutation deletePhrases($ids: [String!]!) {
    deletePhrases(ids: $ids) {
      id
    }
  }
`;
export type DeletePhrasesMutationFn = Apollo.MutationFunction<
  Types.DeletePhrasesMutation,
  Types.DeletePhrasesMutationVariables
>;

/**
 * __useDeletePhrasesMutation__
 *
 * To run a mutation, you first call `useDeletePhrasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhrasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhrasesMutation, { data, loading, error }] = useDeletePhrasesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeletePhrasesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeletePhrasesMutation,
    Types.DeletePhrasesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeletePhrasesMutation, Types.DeletePhrasesMutationVariables>(
    DeletePhrasesDocument,
    options,
  );
}
export type DeletePhrasesMutationHookResult = ReturnType<typeof useDeletePhrasesMutation>;
export type DeletePhrasesMutationResult = Apollo.MutationResult<Types.DeletePhrasesMutation>;
export type DeletePhrasesMutationOptions = Apollo.BaseMutationOptions<
  Types.DeletePhrasesMutation,
  Types.DeletePhrasesMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  Types.DeleteProjectMutation,
  Types.DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteProjectMutation,
    Types.DeleteProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteProjectMutation, Types.DeleteProjectMutationVariables>(
    DeleteProjectDocument,
    options,
  );
}
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<Types.DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteProjectMutation,
  Types.DeleteProjectMutationVariables
>;
export const DeleteProjectUserDocument = gql`
  mutation deleteProjectUser($projectId: ID!, $userId: String!) {
    deleteProjectUser(projectId: $projectId, userId: $userId) {
      ...projectUsers
    }
  }
  ${ProjectUsersFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type DeleteProjectUserMutationFn = Apollo.MutationFunction<
  Types.DeleteProjectUserMutation,
  Types.DeleteProjectUserMutationVariables
>;

/**
 * __useDeleteProjectUserMutation__
 *
 * To run a mutation, you first call `useDeleteProjectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectUserMutation, { data, loading, error }] = useDeleteProjectUserMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteProjectUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteProjectUserMutation,
    Types.DeleteProjectUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteProjectUserMutation,
    Types.DeleteProjectUserMutationVariables
  >(DeleteProjectUserDocument, options);
}
export type DeleteProjectUserMutationHookResult = ReturnType<typeof useDeleteProjectUserMutation>;
export type DeleteProjectUserMutationResult =
  Apollo.MutationResult<Types.DeleteProjectUserMutation>;
export type DeleteProjectUserMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteProjectUserMutation,
  Types.DeleteProjectUserMutationVariables
>;
export const DeleteQuarterOverrideDocument = gql`
  mutation deleteQuarterOverride($id: ID!) {
    deleteQuarterOverride(id: $id) {
      id
    }
  }
`;
export type DeleteQuarterOverrideMutationFn = Apollo.MutationFunction<
  Types.DeleteQuarterOverrideMutation,
  Types.DeleteQuarterOverrideMutationVariables
>;

/**
 * __useDeleteQuarterOverrideMutation__
 *
 * To run a mutation, you first call `useDeleteQuarterOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuarterOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuarterOverrideMutation, { data, loading, error }] = useDeleteQuarterOverrideMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuarterOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteQuarterOverrideMutation,
    Types.DeleteQuarterOverrideMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteQuarterOverrideMutation,
    Types.DeleteQuarterOverrideMutationVariables
  >(DeleteQuarterOverrideDocument, options);
}
export type DeleteQuarterOverrideMutationHookResult = ReturnType<
  typeof useDeleteQuarterOverrideMutation
>;
export type DeleteQuarterOverrideMutationResult =
  Apollo.MutationResult<Types.DeleteQuarterOverrideMutation>;
export type DeleteQuarterOverrideMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteQuarterOverrideMutation,
  Types.DeleteQuarterOverrideMutationVariables
>;
export const DeleteRelatedTicketDocument = gql`
  mutation deleteRelatedTicket($ticketId: String!, $relatedTicketId: String!) {
    deleteRelatedTicket(ticketId: $ticketId, relatedTicketId: $relatedTicketId) {
      ...ticket
    }
  }
  ${TicketFragmentDoc}
  ${UserWithPermInfoFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketFileFragmentDoc}
`;
export type DeleteRelatedTicketMutationFn = Apollo.MutationFunction<
  Types.DeleteRelatedTicketMutation,
  Types.DeleteRelatedTicketMutationVariables
>;

/**
 * __useDeleteRelatedTicketMutation__
 *
 * To run a mutation, you first call `useDeleteRelatedTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRelatedTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRelatedTicketMutation, { data, loading, error }] = useDeleteRelatedTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      relatedTicketId: // value for 'relatedTicketId'
 *   },
 * });
 */
export function useDeleteRelatedTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteRelatedTicketMutation,
    Types.DeleteRelatedTicketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteRelatedTicketMutation,
    Types.DeleteRelatedTicketMutationVariables
  >(DeleteRelatedTicketDocument, options);
}
export type DeleteRelatedTicketMutationHookResult = ReturnType<
  typeof useDeleteRelatedTicketMutation
>;
export type DeleteRelatedTicketMutationResult =
  Apollo.MutationResult<Types.DeleteRelatedTicketMutation>;
export type DeleteRelatedTicketMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteRelatedTicketMutation,
  Types.DeleteRelatedTicketMutationVariables
>;
export const DeleteRoleDocument = gql`
  mutation deleteRole($roleId: String!) {
    deleteRole(roleId: $roleId) {
      id
    }
  }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<
  Types.DeleteRoleMutation,
  Types.DeleteRoleMutationVariables
>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useDeleteRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteRoleMutation,
    Types.DeleteRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteRoleMutation, Types.DeleteRoleMutationVariables>(
    DeleteRoleDocument,
    options,
  );
}
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<Types.DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteRoleMutation,
  Types.DeleteRoleMutationVariables
>;
export const DeleteS4sInstanceDocument = gql`
  mutation deleteS4sInstance($id: ID!) {
    deleteS4sInstance(id: $id) {
      id
    }
  }
`;
export type DeleteS4sInstanceMutationFn = Apollo.MutationFunction<
  Types.DeleteS4sInstanceMutation,
  Types.DeleteS4sInstanceMutationVariables
>;

/**
 * __useDeleteS4sInstanceMutation__
 *
 * To run a mutation, you first call `useDeleteS4sInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteS4sInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteS4sInstanceMutation, { data, loading, error }] = useDeleteS4sInstanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteS4sInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteS4sInstanceMutation,
    Types.DeleteS4sInstanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteS4sInstanceMutation,
    Types.DeleteS4sInstanceMutationVariables
  >(DeleteS4sInstanceDocument, options);
}
export type DeleteS4sInstanceMutationHookResult = ReturnType<typeof useDeleteS4sInstanceMutation>;
export type DeleteS4sInstanceMutationResult =
  Apollo.MutationResult<Types.DeleteS4sInstanceMutation>;
export type DeleteS4sInstanceMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteS4sInstanceMutation,
  Types.DeleteS4sInstanceMutationVariables
>;
export const DeleteSubpageDocument = gql`
  mutation deleteSubpage($id: ID!) {
    deleteSubpage(id: $id) {
      id
    }
  }
`;
export type DeleteSubpageMutationFn = Apollo.MutationFunction<
  Types.DeleteSubpageMutation,
  Types.DeleteSubpageMutationVariables
>;

/**
 * __useDeleteSubpageMutation__
 *
 * To run a mutation, you first call `useDeleteSubpageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubpageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubpageMutation, { data, loading, error }] = useDeleteSubpageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSubpageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteSubpageMutation,
    Types.DeleteSubpageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteSubpageMutation, Types.DeleteSubpageMutationVariables>(
    DeleteSubpageDocument,
    options,
  );
}
export type DeleteSubpageMutationHookResult = ReturnType<typeof useDeleteSubpageMutation>;
export type DeleteSubpageMutationResult = Apollo.MutationResult<Types.DeleteSubpageMutation>;
export type DeleteSubpageMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteSubpageMutation,
  Types.DeleteSubpageMutationVariables
>;
export const DeleteSubpagesDocument = gql`
  mutation deleteSubpages($ids: [String!]!) {
    deleteSubpages(ids: $ids) {
      id
    }
  }
`;
export type DeleteSubpagesMutationFn = Apollo.MutationFunction<
  Types.DeleteSubpagesMutation,
  Types.DeleteSubpagesMutationVariables
>;

/**
 * __useDeleteSubpagesMutation__
 *
 * To run a mutation, you first call `useDeleteSubpagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSubpagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSubpagesMutation, { data, loading, error }] = useDeleteSubpagesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteSubpagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteSubpagesMutation,
    Types.DeleteSubpagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteSubpagesMutation, Types.DeleteSubpagesMutationVariables>(
    DeleteSubpagesDocument,
    options,
  );
}
export type DeleteSubpagesMutationHookResult = ReturnType<typeof useDeleteSubpagesMutation>;
export type DeleteSubpagesMutationResult = Apollo.MutationResult<Types.DeleteSubpagesMutation>;
export type DeleteSubpagesMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteSubpagesMutation,
  Types.DeleteSubpagesMutationVariables
>;
export const DeleteTeamDocument = gql`
  mutation deleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      id
      name
    }
  }
`;
export type DeleteTeamMutationFn = Apollo.MutationFunction<
  Types.DeleteTeamMutation,
  Types.DeleteTeamMutationVariables
>;

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTeamMutation,
    Types.DeleteTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteTeamMutation, Types.DeleteTeamMutationVariables>(
    DeleteTeamDocument,
    options,
  );
}
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = Apollo.MutationResult<Types.DeleteTeamMutation>;
export type DeleteTeamMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTeamMutation,
  Types.DeleteTeamMutationVariables
>;
export const DeleteTextOrdersDocument = gql`
  mutation deleteTextOrders($ids: [String!]!) {
    deleteTextOrders(ids: $ids) {
      id
    }
  }
`;
export type DeleteTextOrdersMutationFn = Apollo.MutationFunction<
  Types.DeleteTextOrdersMutation,
  Types.DeleteTextOrdersMutationVariables
>;

/**
 * __useDeleteTextOrdersMutation__
 *
 * To run a mutation, you first call `useDeleteTextOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTextOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTextOrdersMutation, { data, loading, error }] = useDeleteTextOrdersMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteTextOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTextOrdersMutation,
    Types.DeleteTextOrdersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteTextOrdersMutation,
    Types.DeleteTextOrdersMutationVariables
  >(DeleteTextOrdersDocument, options);
}
export type DeleteTextOrdersMutationHookResult = ReturnType<typeof useDeleteTextOrdersMutation>;
export type DeleteTextOrdersMutationResult = Apollo.MutationResult<Types.DeleteTextOrdersMutation>;
export type DeleteTextOrdersMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTextOrdersMutation,
  Types.DeleteTextOrdersMutationVariables
>;
export const DeleteTicketDocument = gql`
  mutation deleteTicket($ticketId: ID!) {
    deleteTicket(ticketId: $ticketId) {
      id
    }
  }
`;
export type DeleteTicketMutationFn = Apollo.MutationFunction<
  Types.DeleteTicketMutation,
  Types.DeleteTicketMutationVariables
>;

/**
 * __useDeleteTicketMutation__
 *
 * To run a mutation, you first call `useDeleteTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketMutation, { data, loading, error }] = useDeleteTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useDeleteTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTicketMutation,
    Types.DeleteTicketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteTicketMutation, Types.DeleteTicketMutationVariables>(
    DeleteTicketDocument,
    options,
  );
}
export type DeleteTicketMutationHookResult = ReturnType<typeof useDeleteTicketMutation>;
export type DeleteTicketMutationResult = Apollo.MutationResult<Types.DeleteTicketMutation>;
export type DeleteTicketMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTicketMutation,
  Types.DeleteTicketMutationVariables
>;
export const DeleteTicketAttachmentDocument = gql`
  mutation deleteTicketAttachment($id: ID!) {
    deleteTicketAttachment(id: $id) {
      id
    }
  }
`;
export type DeleteTicketAttachmentMutationFn = Apollo.MutationFunction<
  Types.DeleteTicketAttachmentMutation,
  Types.DeleteTicketAttachmentMutationVariables
>;

/**
 * __useDeleteTicketAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteTicketAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketAttachmentMutation, { data, loading, error }] = useDeleteTicketAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTicketAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTicketAttachmentMutation,
    Types.DeleteTicketAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteTicketAttachmentMutation,
    Types.DeleteTicketAttachmentMutationVariables
  >(DeleteTicketAttachmentDocument, options);
}
export type DeleteTicketAttachmentMutationHookResult = ReturnType<
  typeof useDeleteTicketAttachmentMutation
>;
export type DeleteTicketAttachmentMutationResult =
  Apollo.MutationResult<Types.DeleteTicketAttachmentMutation>;
export type DeleteTicketAttachmentMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTicketAttachmentMutation,
  Types.DeleteTicketAttachmentMutationVariables
>;
export const DeleteTicketCommentDocument = gql`
  mutation deleteTicketComment($id: ID!) {
    deleteTicketComment(id: $id) {
      id
    }
  }
`;
export type DeleteTicketCommentMutationFn = Apollo.MutationFunction<
  Types.DeleteTicketCommentMutation,
  Types.DeleteTicketCommentMutationVariables
>;

/**
 * __useDeleteTicketCommentMutation__
 *
 * To run a mutation, you first call `useDeleteTicketCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketCommentMutation, { data, loading, error }] = useDeleteTicketCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTicketCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTicketCommentMutation,
    Types.DeleteTicketCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteTicketCommentMutation,
    Types.DeleteTicketCommentMutationVariables
  >(DeleteTicketCommentDocument, options);
}
export type DeleteTicketCommentMutationHookResult = ReturnType<
  typeof useDeleteTicketCommentMutation
>;
export type DeleteTicketCommentMutationResult =
  Apollo.MutationResult<Types.DeleteTicketCommentMutation>;
export type DeleteTicketCommentMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTicketCommentMutation,
  Types.DeleteTicketCommentMutationVariables
>;
export const DeleteTicketCommentAttachmentDocument = gql`
  mutation deleteTicketCommentAttachment($id: ID!) {
    deleteTicketCommentAttachment(id: $id) {
      id
    }
  }
`;
export type DeleteTicketCommentAttachmentMutationFn = Apollo.MutationFunction<
  Types.DeleteTicketCommentAttachmentMutation,
  Types.DeleteTicketCommentAttachmentMutationVariables
>;

/**
 * __useDeleteTicketCommentAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteTicketCommentAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketCommentAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketCommentAttachmentMutation, { data, loading, error }] = useDeleteTicketCommentAttachmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTicketCommentAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTicketCommentAttachmentMutation,
    Types.DeleteTicketCommentAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteTicketCommentAttachmentMutation,
    Types.DeleteTicketCommentAttachmentMutationVariables
  >(DeleteTicketCommentAttachmentDocument, options);
}
export type DeleteTicketCommentAttachmentMutationHookResult = ReturnType<
  typeof useDeleteTicketCommentAttachmentMutation
>;
export type DeleteTicketCommentAttachmentMutationResult =
  Apollo.MutationResult<Types.DeleteTicketCommentAttachmentMutation>;
export type DeleteTicketCommentAttachmentMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTicketCommentAttachmentMutation,
  Types.DeleteTicketCommentAttachmentMutationVariables
>;
export const DeleteTicketPriorityDocument = gql`
  mutation deleteTicketPriority($id: ID!) {
    deleteTicketPriority(id: $id) {
      id
    }
  }
`;
export type DeleteTicketPriorityMutationFn = Apollo.MutationFunction<
  Types.DeleteTicketPriorityMutation,
  Types.DeleteTicketPriorityMutationVariables
>;

/**
 * __useDeleteTicketPriorityMutation__
 *
 * To run a mutation, you first call `useDeleteTicketPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketPriorityMutation, { data, loading, error }] = useDeleteTicketPriorityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTicketPriorityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTicketPriorityMutation,
    Types.DeleteTicketPriorityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteTicketPriorityMutation,
    Types.DeleteTicketPriorityMutationVariables
  >(DeleteTicketPriorityDocument, options);
}
export type DeleteTicketPriorityMutationHookResult = ReturnType<
  typeof useDeleteTicketPriorityMutation
>;
export type DeleteTicketPriorityMutationResult =
  Apollo.MutationResult<Types.DeleteTicketPriorityMutation>;
export type DeleteTicketPriorityMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTicketPriorityMutation,
  Types.DeleteTicketPriorityMutationVariables
>;
export const DeleteTicketStatusDocument = gql`
  mutation deleteTicketStatus($id: ID!) {
    deleteTicketStatus(id: $id) {
      id
    }
  }
`;
export type DeleteTicketStatusMutationFn = Apollo.MutationFunction<
  Types.DeleteTicketStatusMutation,
  Types.DeleteTicketStatusMutationVariables
>;

/**
 * __useDeleteTicketStatusMutation__
 *
 * To run a mutation, you first call `useDeleteTicketStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketStatusMutation, { data, loading, error }] = useDeleteTicketStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTicketStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTicketStatusMutation,
    Types.DeleteTicketStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteTicketStatusMutation,
    Types.DeleteTicketStatusMutationVariables
  >(DeleteTicketStatusDocument, options);
}
export type DeleteTicketStatusMutationHookResult = ReturnType<typeof useDeleteTicketStatusMutation>;
export type DeleteTicketStatusMutationResult =
  Apollo.MutationResult<Types.DeleteTicketStatusMutation>;
export type DeleteTicketStatusMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTicketStatusMutation,
  Types.DeleteTicketStatusMutationVariables
>;
export const DeleteTicketTimeDocument = gql`
  mutation deleteTicketTime($id: ID!) {
    deleteTicketTime(id: $id)
  }
`;
export type DeleteTicketTimeMutationFn = Apollo.MutationFunction<
  Types.DeleteTicketTimeMutation,
  Types.DeleteTicketTimeMutationVariables
>;

/**
 * __useDeleteTicketTimeMutation__
 *
 * To run a mutation, you first call `useDeleteTicketTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketTimeMutation, { data, loading, error }] = useDeleteTicketTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTicketTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTicketTimeMutation,
    Types.DeleteTicketTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteTicketTimeMutation,
    Types.DeleteTicketTimeMutationVariables
  >(DeleteTicketTimeDocument, options);
}
export type DeleteTicketTimeMutationHookResult = ReturnType<typeof useDeleteTicketTimeMutation>;
export type DeleteTicketTimeMutationResult = Apollo.MutationResult<Types.DeleteTicketTimeMutation>;
export type DeleteTicketTimeMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTicketTimeMutation,
  Types.DeleteTicketTimeMutationVariables
>;
export const DeleteTicketTypeDocument = gql`
  mutation deleteTicketType($id: ID!) {
    deleteTicketType(id: $id) {
      id
    }
  }
`;
export type DeleteTicketTypeMutationFn = Apollo.MutationFunction<
  Types.DeleteTicketTypeMutation,
  Types.DeleteTicketTypeMutationVariables
>;

/**
 * __useDeleteTicketTypeMutation__
 *
 * To run a mutation, you first call `useDeleteTicketTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTicketTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTicketTypeMutation, { data, loading, error }] = useDeleteTicketTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTicketTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTicketTypeMutation,
    Types.DeleteTicketTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteTicketTypeMutation,
    Types.DeleteTicketTypeMutationVariables
  >(DeleteTicketTypeDocument, options);
}
export type DeleteTicketTypeMutationHookResult = ReturnType<typeof useDeleteTicketTypeMutation>;
export type DeleteTicketTypeMutationResult = Apollo.MutationResult<Types.DeleteTicketTypeMutation>;
export type DeleteTicketTypeMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTicketTypeMutation,
  Types.DeleteTicketTypeMutationVariables
>;
export const DeleteTodoDocument = gql`
  mutation deleteTodo($id: ID!) {
    deleteTodo(id: $id) {
      id
    }
  }
`;
export type DeleteTodoMutationFn = Apollo.MutationFunction<
  Types.DeleteTodoMutation,
  Types.DeleteTodoMutationVariables
>;

/**
 * __useDeleteTodoMutation__
 *
 * To run a mutation, you first call `useDeleteTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTodoMutation, { data, loading, error }] = useDeleteTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteTodoMutation,
    Types.DeleteTodoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.DeleteTodoMutation, Types.DeleteTodoMutationVariables>(
    DeleteTodoDocument,
    options,
  );
}
export type DeleteTodoMutationHookResult = ReturnType<typeof useDeleteTodoMutation>;
export type DeleteTodoMutationResult = Apollo.MutationResult<Types.DeleteTodoMutation>;
export type DeleteTodoMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteTodoMutation,
  Types.DeleteTodoMutationVariables
>;
export const GroupPhrasesDocument = gql`
  mutation groupPhrases($groupId: String!, $ids: [String!]!) {
    groupPhrases(groupId: $groupId, ids: $ids) {
      ...phraseGroup
    }
  }
  ${PhraseGroupFragmentDoc}
`;
export type GroupPhrasesMutationFn = Apollo.MutationFunction<
  Types.GroupPhrasesMutation,
  Types.GroupPhrasesMutationVariables
>;

/**
 * __useGroupPhrasesMutation__
 *
 * To run a mutation, you first call `useGroupPhrasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupPhrasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupPhrasesMutation, { data, loading, error }] = useGroupPhrasesMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGroupPhrasesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.GroupPhrasesMutation,
    Types.GroupPhrasesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.GroupPhrasesMutation, Types.GroupPhrasesMutationVariables>(
    GroupPhrasesDocument,
    options,
  );
}
export type GroupPhrasesMutationHookResult = ReturnType<typeof useGroupPhrasesMutation>;
export type GroupPhrasesMutationResult = Apollo.MutationResult<Types.GroupPhrasesMutation>;
export type GroupPhrasesMutationOptions = Apollo.BaseMutationOptions<
  Types.GroupPhrasesMutation,
  Types.GroupPhrasesMutationVariables
>;
export const ImpersonateDocument = gql`
  mutation impersonate($userId: ID!) {
    impersonate(userId: $userId) {
      email
      first_name
      last_name
    }
  }
`;
export type ImpersonateMutationFn = Apollo.MutationFunction<
  Types.ImpersonateMutation,
  Types.ImpersonateMutationVariables
>;

/**
 * __useImpersonateMutation__
 *
 * To run a mutation, you first call `useImpersonateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateMutation, { data, loading, error }] = useImpersonateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useImpersonateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ImpersonateMutation,
    Types.ImpersonateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ImpersonateMutation, Types.ImpersonateMutationVariables>(
    ImpersonateDocument,
    options,
  );
}
export type ImpersonateMutationHookResult = ReturnType<typeof useImpersonateMutation>;
export type ImpersonateMutationResult = Apollo.MutationResult<Types.ImpersonateMutation>;
export type ImpersonateMutationOptions = Apollo.BaseMutationOptions<
  Types.ImpersonateMutation,
  Types.ImpersonateMutationVariables
>;
export const ImportPhrasesFromGoogleSheetsDocument = gql`
  mutation importPhrasesFromGoogleSheets($sheetId: String!, $domainId: String!) {
    importPhrasesFromGoogleSheets(sheetId: $sheetId, domainId: $domainId) {
      phrases {
        id
      }
      errors
    }
  }
`;
export type ImportPhrasesFromGoogleSheetsMutationFn = Apollo.MutationFunction<
  Types.ImportPhrasesFromGoogleSheetsMutation,
  Types.ImportPhrasesFromGoogleSheetsMutationVariables
>;

/**
 * __useImportPhrasesFromGoogleSheetsMutation__
 *
 * To run a mutation, you first call `useImportPhrasesFromGoogleSheetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPhrasesFromGoogleSheetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPhrasesFromGoogleSheetsMutation, { data, loading, error }] = useImportPhrasesFromGoogleSheetsMutation({
 *   variables: {
 *      sheetId: // value for 'sheetId'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useImportPhrasesFromGoogleSheetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ImportPhrasesFromGoogleSheetsMutation,
    Types.ImportPhrasesFromGoogleSheetsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ImportPhrasesFromGoogleSheetsMutation,
    Types.ImportPhrasesFromGoogleSheetsMutationVariables
  >(ImportPhrasesFromGoogleSheetsDocument, options);
}
export type ImportPhrasesFromGoogleSheetsMutationHookResult = ReturnType<
  typeof useImportPhrasesFromGoogleSheetsMutation
>;
export type ImportPhrasesFromGoogleSheetsMutationResult =
  Apollo.MutationResult<Types.ImportPhrasesFromGoogleSheetsMutation>;
export type ImportPhrasesFromGoogleSheetsMutationOptions = Apollo.BaseMutationOptions<
  Types.ImportPhrasesFromGoogleSheetsMutation,
  Types.ImportPhrasesFromGoogleSheetsMutationVariables
>;
export const ImportTextOrderCsvDocument = gql`
  mutation importTextOrderCsv($id: ID!, $headers: String!, $headerRow: Boolean!) {
    importTextOrderCsv(id: $id, headers: $headers, headerRow: $headerRow) {
      id
    }
  }
`;
export type ImportTextOrderCsvMutationFn = Apollo.MutationFunction<
  Types.ImportTextOrderCsvMutation,
  Types.ImportTextOrderCsvMutationVariables
>;

/**
 * __useImportTextOrderCsvMutation__
 *
 * To run a mutation, you first call `useImportTextOrderCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTextOrderCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTextOrderCsvMutation, { data, loading, error }] = useImportTextOrderCsvMutation({
 *   variables: {
 *      id: // value for 'id'
 *      headers: // value for 'headers'
 *      headerRow: // value for 'headerRow'
 *   },
 * });
 */
export function useImportTextOrderCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ImportTextOrderCsvMutation,
    Types.ImportTextOrderCsvMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ImportTextOrderCsvMutation,
    Types.ImportTextOrderCsvMutationVariables
  >(ImportTextOrderCsvDocument, options);
}
export type ImportTextOrderCsvMutationHookResult = ReturnType<typeof useImportTextOrderCsvMutation>;
export type ImportTextOrderCsvMutationResult =
  Apollo.MutationResult<Types.ImportTextOrderCsvMutation>;
export type ImportTextOrderCsvMutationOptions = Apollo.BaseMutationOptions<
  Types.ImportTextOrderCsvMutation,
  Types.ImportTextOrderCsvMutationVariables
>;
export const ImportTextOrderFromGoogleSheetsDocument = gql`
  mutation importTextOrderFromGoogleSheets($sheetId: String!, $domainId: String!) {
    importTextOrderFromGoogleSheets(sheetId: $sheetId, domainId: $domainId) {
      textOrders {
        ...textOrder
      }
      errors
    }
  }
  ${TextOrderFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type ImportTextOrderFromGoogleSheetsMutationFn = Apollo.MutationFunction<
  Types.ImportTextOrderFromGoogleSheetsMutation,
  Types.ImportTextOrderFromGoogleSheetsMutationVariables
>;

/**
 * __useImportTextOrderFromGoogleSheetsMutation__
 *
 * To run a mutation, you first call `useImportTextOrderFromGoogleSheetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportTextOrderFromGoogleSheetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importTextOrderFromGoogleSheetsMutation, { data, loading, error }] = useImportTextOrderFromGoogleSheetsMutation({
 *   variables: {
 *      sheetId: // value for 'sheetId'
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useImportTextOrderFromGoogleSheetsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ImportTextOrderFromGoogleSheetsMutation,
    Types.ImportTextOrderFromGoogleSheetsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ImportTextOrderFromGoogleSheetsMutation,
    Types.ImportTextOrderFromGoogleSheetsMutationVariables
  >(ImportTextOrderFromGoogleSheetsDocument, options);
}
export type ImportTextOrderFromGoogleSheetsMutationHookResult = ReturnType<
  typeof useImportTextOrderFromGoogleSheetsMutation
>;
export type ImportTextOrderFromGoogleSheetsMutationResult =
  Apollo.MutationResult<Types.ImportTextOrderFromGoogleSheetsMutation>;
export type ImportTextOrderFromGoogleSheetsMutationOptions = Apollo.BaseMutationOptions<
  Types.ImportTextOrderFromGoogleSheetsMutation,
  Types.ImportTextOrderFromGoogleSheetsMutationVariables
>;
export const LeaveImpersonationDocument = gql`
  mutation leaveImpersonation {
    leaveImpersonation {
      id
    }
  }
`;
export type LeaveImpersonationMutationFn = Apollo.MutationFunction<
  Types.LeaveImpersonationMutation,
  Types.LeaveImpersonationMutationVariables
>;

/**
 * __useLeaveImpersonationMutation__
 *
 * To run a mutation, you first call `useLeaveImpersonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveImpersonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveImpersonationMutation, { data, loading, error }] = useLeaveImpersonationMutation({
 *   variables: {
 *   },
 * });
 */
export function useLeaveImpersonationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.LeaveImpersonationMutation,
    Types.LeaveImpersonationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.LeaveImpersonationMutation,
    Types.LeaveImpersonationMutationVariables
  >(LeaveImpersonationDocument, options);
}
export type LeaveImpersonationMutationHookResult = ReturnType<typeof useLeaveImpersonationMutation>;
export type LeaveImpersonationMutationResult =
  Apollo.MutationResult<Types.LeaveImpersonationMutation>;
export type LeaveImpersonationMutationOptions = Apollo.BaseMutationOptions<
  Types.LeaveImpersonationMutation,
  Types.LeaveImpersonationMutationVariables
>;
export const LogTicketTimeDocument = gql`
  mutation logTicketTime($ticketId: ID!, $minutes: Int!) {
    logTicketTime(ticketId: $ticketId, minutes: $minutes) {
      ...timeLog
    }
  }
  ${TimeLogFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type LogTicketTimeMutationFn = Apollo.MutationFunction<
  Types.LogTicketTimeMutation,
  Types.LogTicketTimeMutationVariables
>;

/**
 * __useLogTicketTimeMutation__
 *
 * To run a mutation, you first call `useLogTicketTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogTicketTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logTicketTimeMutation, { data, loading, error }] = useLogTicketTimeMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      minutes: // value for 'minutes'
 *   },
 * });
 */
export function useLogTicketTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.LogTicketTimeMutation,
    Types.LogTicketTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.LogTicketTimeMutation, Types.LogTicketTimeMutationVariables>(
    LogTicketTimeDocument,
    options,
  );
}
export type LogTicketTimeMutationHookResult = ReturnType<typeof useLogTicketTimeMutation>;
export type LogTicketTimeMutationResult = Apollo.MutationResult<Types.LogTicketTimeMutation>;
export type LogTicketTimeMutationOptions = Apollo.BaseMutationOptions<
  Types.LogTicketTimeMutation,
  Types.LogTicketTimeMutationVariables
>;
export const LoginUserDocument = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      email
      first_name
      last_name
    }
  }
`;
export type LoginUserMutationFn = Apollo.MutationFunction<
  Types.LoginUserMutation,
  Types.LoginUserMutationVariables
>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.LoginUserMutation,
    Types.LoginUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.LoginUserMutation, Types.LoginUserMutationVariables>(
    LoginUserDocument,
    options,
  );
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<Types.LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<
  Types.LoginUserMutation,
  Types.LoginUserMutationVariables
>;
export const LogoutUserDocument = gql`
  mutation logoutUser {
    logoutUser
  }
`;
export type LogoutUserMutationFn = Apollo.MutationFunction<
  Types.LogoutUserMutation,
  Types.LogoutUserMutationVariables
>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.LogoutUserMutation,
    Types.LogoutUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.LogoutUserMutation, Types.LogoutUserMutationVariables>(
    LogoutUserDocument,
    options,
  );
}
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<Types.LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<
  Types.LogoutUserMutation,
  Types.LogoutUserMutationVariables
>;
export const MarkAllNotificationReadDocument = gql`
  mutation markAllNotificationRead {
    markAllNotificationRead
  }
`;
export type MarkAllNotificationReadMutationFn = Apollo.MutationFunction<
  Types.MarkAllNotificationReadMutation,
  Types.MarkAllNotificationReadMutationVariables
>;

/**
 * __useMarkAllNotificationReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationReadMutation, { data, loading, error }] = useMarkAllNotificationReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.MarkAllNotificationReadMutation,
    Types.MarkAllNotificationReadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.MarkAllNotificationReadMutation,
    Types.MarkAllNotificationReadMutationVariables
  >(MarkAllNotificationReadDocument, options);
}
export type MarkAllNotificationReadMutationHookResult = ReturnType<
  typeof useMarkAllNotificationReadMutation
>;
export type MarkAllNotificationReadMutationResult =
  Apollo.MutationResult<Types.MarkAllNotificationReadMutation>;
export type MarkAllNotificationReadMutationOptions = Apollo.BaseMutationOptions<
  Types.MarkAllNotificationReadMutation,
  Types.MarkAllNotificationReadMutationVariables
>;
export const MarkReadNotificationDocument = gql`
  mutation markReadNotification($id: ID!) {
    markReadNotification(id: $id)
  }
`;
export type MarkReadNotificationMutationFn = Apollo.MutationFunction<
  Types.MarkReadNotificationMutation,
  Types.MarkReadNotificationMutationVariables
>;

/**
 * __useMarkReadNotificationMutation__
 *
 * To run a mutation, you first call `useMarkReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReadNotificationMutation, { data, loading, error }] = useMarkReadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkReadNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.MarkReadNotificationMutation,
    Types.MarkReadNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.MarkReadNotificationMutation,
    Types.MarkReadNotificationMutationVariables
  >(MarkReadNotificationDocument, options);
}
export type MarkReadNotificationMutationHookResult = ReturnType<
  typeof useMarkReadNotificationMutation
>;
export type MarkReadNotificationMutationResult =
  Apollo.MutationResult<Types.MarkReadNotificationMutation>;
export type MarkReadNotificationMutationOptions = Apollo.BaseMutationOptions<
  Types.MarkReadNotificationMutation,
  Types.MarkReadNotificationMutationVariables
>;
export const MarkUnreadNotificationDocument = gql`
  mutation markUnreadNotification($id: ID!) {
    markUnreadNotification(id: $id)
  }
`;
export type MarkUnreadNotificationMutationFn = Apollo.MutationFunction<
  Types.MarkUnreadNotificationMutation,
  Types.MarkUnreadNotificationMutationVariables
>;

/**
 * __useMarkUnreadNotificationMutation__
 *
 * To run a mutation, you first call `useMarkUnreadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkUnreadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markUnreadNotificationMutation, { data, loading, error }] = useMarkUnreadNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkUnreadNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.MarkUnreadNotificationMutation,
    Types.MarkUnreadNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.MarkUnreadNotificationMutation,
    Types.MarkUnreadNotificationMutationVariables
  >(MarkUnreadNotificationDocument, options);
}
export type MarkUnreadNotificationMutationHookResult = ReturnType<
  typeof useMarkUnreadNotificationMutation
>;
export type MarkUnreadNotificationMutationResult =
  Apollo.MutationResult<Types.MarkUnreadNotificationMutation>;
export type MarkUnreadNotificationMutationOptions = Apollo.BaseMutationOptions<
  Types.MarkUnreadNotificationMutation,
  Types.MarkUnreadNotificationMutationVariables
>;
export const RefreshGaDocument = gql`
  mutation refreshGA {
    refreshGA
  }
`;
export type RefreshGaMutationFn = Apollo.MutationFunction<
  Types.RefreshGaMutation,
  Types.RefreshGaMutationVariables
>;

/**
 * __useRefreshGaMutation__
 *
 * To run a mutation, you first call `useRefreshGaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshGaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshGaMutation, { data, loading, error }] = useRefreshGaMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshGaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RefreshGaMutation,
    Types.RefreshGaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.RefreshGaMutation, Types.RefreshGaMutationVariables>(
    RefreshGaDocument,
    options,
  );
}
export type RefreshGaMutationHookResult = ReturnType<typeof useRefreshGaMutation>;
export type RefreshGaMutationResult = Apollo.MutationResult<Types.RefreshGaMutation>;
export type RefreshGaMutationOptions = Apollo.BaseMutationOptions<
  Types.RefreshGaMutation,
  Types.RefreshGaMutationVariables
>;
export const RefreshGamAccessTokenDocument = gql`
  mutation refreshGAMAccessToken($id: ID!) {
    refreshGAMAccessToken(id: $id) {
      ...gamInternalAccount
    }
  }
  ${GamInternalAccountFragmentDoc}
`;
export type RefreshGamAccessTokenMutationFn = Apollo.MutationFunction<
  Types.RefreshGamAccessTokenMutation,
  Types.RefreshGamAccessTokenMutationVariables
>;

/**
 * __useRefreshGamAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshGamAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshGamAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshGamAccessTokenMutation, { data, loading, error }] = useRefreshGamAccessTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRefreshGamAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RefreshGamAccessTokenMutation,
    Types.RefreshGamAccessTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RefreshGamAccessTokenMutation,
    Types.RefreshGamAccessTokenMutationVariables
  >(RefreshGamAccessTokenDocument, options);
}
export type RefreshGamAccessTokenMutationHookResult = ReturnType<
  typeof useRefreshGamAccessTokenMutation
>;
export type RefreshGamAccessTokenMutationResult =
  Apollo.MutationResult<Types.RefreshGamAccessTokenMutation>;
export type RefreshGamAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  Types.RefreshGamAccessTokenMutation,
  Types.RefreshGamAccessTokenMutationVariables
>;
export const RefreshGscDocument = gql`
  mutation refreshGSC {
    refreshGSC
  }
`;
export type RefreshGscMutationFn = Apollo.MutationFunction<
  Types.RefreshGscMutation,
  Types.RefreshGscMutationVariables
>;

/**
 * __useRefreshGscMutation__
 *
 * To run a mutation, you first call `useRefreshGscMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshGscMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshGscMutation, { data, loading, error }] = useRefreshGscMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshGscMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RefreshGscMutation,
    Types.RefreshGscMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.RefreshGscMutation, Types.RefreshGscMutationVariables>(
    RefreshGscDocument,
    options,
  );
}
export type RefreshGscMutationHookResult = ReturnType<typeof useRefreshGscMutation>;
export type RefreshGscMutationResult = Apollo.MutationResult<Types.RefreshGscMutation>;
export type RefreshGscMutationOptions = Apollo.BaseMutationOptions<
  Types.RefreshGscMutation,
  Types.RefreshGscMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($token: String!, $password: String!, $email: String!) {
    resetPassword(token: $token, password: $password, email: $email)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ResetPasswordMutation,
    Types.ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options,
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<Types.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>;
export const SaveSeoNoteDocument = gql`
  mutation saveSeoNote($domainId: String!, $language: String!, $note: String) {
    saveSeoNote(domainId: $domainId, language: $language, note: $note) {
      note
      success
    }
  }
`;
export type SaveSeoNoteMutationFn = Apollo.MutationFunction<
  Types.SaveSeoNoteMutation,
  Types.SaveSeoNoteMutationVariables
>;

/**
 * __useSaveSeoNoteMutation__
 *
 * To run a mutation, you first call `useSaveSeoNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSeoNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSeoNoteMutation, { data, loading, error }] = useSaveSeoNoteMutation({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      language: // value for 'language'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useSaveSeoNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SaveSeoNoteMutation,
    Types.SaveSeoNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SaveSeoNoteMutation, Types.SaveSeoNoteMutationVariables>(
    SaveSeoNoteDocument,
    options,
  );
}
export type SaveSeoNoteMutationHookResult = ReturnType<typeof useSaveSeoNoteMutation>;
export type SaveSeoNoteMutationResult = Apollo.MutationResult<Types.SaveSeoNoteMutation>;
export type SaveSeoNoteMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveSeoNoteMutation,
  Types.SaveSeoNoteMutationVariables
>;
export const SendResetPasswordDocument = gql`
  mutation sendResetPassword($email: String!) {
    sendResetPassword(email: $email)
  }
`;
export type SendResetPasswordMutationFn = Apollo.MutationFunction<
  Types.SendResetPasswordMutation,
  Types.SendResetPasswordMutationVariables
>;

/**
 * __useSendResetPasswordMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordMutation, { data, loading, error }] = useSendResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SendResetPasswordMutation,
    Types.SendResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SendResetPasswordMutation,
    Types.SendResetPasswordMutationVariables
  >(SendResetPasswordDocument, options);
}
export type SendResetPasswordMutationHookResult = ReturnType<typeof useSendResetPasswordMutation>;
export type SendResetPasswordMutationResult =
  Apollo.MutationResult<Types.SendResetPasswordMutation>;
export type SendResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.SendResetPasswordMutation,
  Types.SendResetPasswordMutationVariables
>;
export const SetPhrasesUserAssignedUrlDocument = gql`
  mutation setPhrasesUserAssignedUrl($ids: [String!]!) {
    setPhrasesUserAssignedUrl(ids: $ids) {
      id
    }
  }
`;
export type SetPhrasesUserAssignedUrlMutationFn = Apollo.MutationFunction<
  Types.SetPhrasesUserAssignedUrlMutation,
  Types.SetPhrasesUserAssignedUrlMutationVariables
>;

/**
 * __useSetPhrasesUserAssignedUrlMutation__
 *
 * To run a mutation, you first call `useSetPhrasesUserAssignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPhrasesUserAssignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPhrasesUserAssignedUrlMutation, { data, loading, error }] = useSetPhrasesUserAssignedUrlMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSetPhrasesUserAssignedUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SetPhrasesUserAssignedUrlMutation,
    Types.SetPhrasesUserAssignedUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SetPhrasesUserAssignedUrlMutation,
    Types.SetPhrasesUserAssignedUrlMutationVariables
  >(SetPhrasesUserAssignedUrlDocument, options);
}
export type SetPhrasesUserAssignedUrlMutationHookResult = ReturnType<
  typeof useSetPhrasesUserAssignedUrlMutation
>;
export type SetPhrasesUserAssignedUrlMutationResult =
  Apollo.MutationResult<Types.SetPhrasesUserAssignedUrlMutation>;
export type SetPhrasesUserAssignedUrlMutationOptions = Apollo.BaseMutationOptions<
  Types.SetPhrasesUserAssignedUrlMutation,
  Types.SetPhrasesUserAssignedUrlMutationVariables
>;
export const SetTemporaryGaAccessDocument = gql`
  mutation setTemporaryGAAccess($domainId: String!) {
    setTemporaryGAAccess(domainId: $domainId) {
      user {
        id
        first_name
        last_name
      }
      __typename
    }
  }
`;
export type SetTemporaryGaAccessMutationFn = Apollo.MutationFunction<
  Types.SetTemporaryGaAccessMutation,
  Types.SetTemporaryGaAccessMutationVariables
>;

/**
 * __useSetTemporaryGaAccessMutation__
 *
 * To run a mutation, you first call `useSetTemporaryGaAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTemporaryGaAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTemporaryGaAccessMutation, { data, loading, error }] = useSetTemporaryGaAccessMutation({
 *   variables: {
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useSetTemporaryGaAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SetTemporaryGaAccessMutation,
    Types.SetTemporaryGaAccessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SetTemporaryGaAccessMutation,
    Types.SetTemporaryGaAccessMutationVariables
  >(SetTemporaryGaAccessDocument, options);
}
export type SetTemporaryGaAccessMutationHookResult = ReturnType<
  typeof useSetTemporaryGaAccessMutation
>;
export type SetTemporaryGaAccessMutationResult =
  Apollo.MutationResult<Types.SetTemporaryGaAccessMutation>;
export type SetTemporaryGaAccessMutationOptions = Apollo.BaseMutationOptions<
  Types.SetTemporaryGaAccessMutation,
  Types.SetTemporaryGaAccessMutationVariables
>;
export const SetTicketPriorityDocument = gql`
  mutation setTicketPriority($id: ID!, $priorityValue: Float!) {
    setTicketPriority(id: $id, priorityValue: $priorityValue) {
      ...ticket
    }
  }
  ${TicketFragmentDoc}
  ${UserWithPermInfoFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketFileFragmentDoc}
`;
export type SetTicketPriorityMutationFn = Apollo.MutationFunction<
  Types.SetTicketPriorityMutation,
  Types.SetTicketPriorityMutationVariables
>;

/**
 * __useSetTicketPriorityMutation__
 *
 * To run a mutation, you first call `useSetTicketPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTicketPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTicketPriorityMutation, { data, loading, error }] = useSetTicketPriorityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      priorityValue: // value for 'priorityValue'
 *   },
 * });
 */
export function useSetTicketPriorityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SetTicketPriorityMutation,
    Types.SetTicketPriorityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SetTicketPriorityMutation,
    Types.SetTicketPriorityMutationVariables
  >(SetTicketPriorityDocument, options);
}
export type SetTicketPriorityMutationHookResult = ReturnType<typeof useSetTicketPriorityMutation>;
export type SetTicketPriorityMutationResult =
  Apollo.MutationResult<Types.SetTicketPriorityMutation>;
export type SetTicketPriorityMutationOptions = Apollo.BaseMutationOptions<
  Types.SetTicketPriorityMutation,
  Types.SetTicketPriorityMutationVariables
>;
export const SetTranslationsDocument = gql`
  mutation setTranslations($translations: [GroupedTranslatablesInput!]!) {
    setTranslations(translations: $translations)
  }
`;
export type SetTranslationsMutationFn = Apollo.MutationFunction<
  Types.SetTranslationsMutation,
  Types.SetTranslationsMutationVariables
>;

/**
 * __useSetTranslationsMutation__
 *
 * To run a mutation, you first call `useSetTranslationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTranslationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTranslationsMutation, { data, loading, error }] = useSetTranslationsMutation({
 *   variables: {
 *      translations: // value for 'translations'
 *   },
 * });
 */
export function useSetTranslationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SetTranslationsMutation,
    Types.SetTranslationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SetTranslationsMutation, Types.SetTranslationsMutationVariables>(
    SetTranslationsDocument,
    options,
  );
}
export type SetTranslationsMutationHookResult = ReturnType<typeof useSetTranslationsMutation>;
export type SetTranslationsMutationResult = Apollo.MutationResult<Types.SetTranslationsMutation>;
export type SetTranslationsMutationOptions = Apollo.BaseMutationOptions<
  Types.SetTranslationsMutation,
  Types.SetTranslationsMutationVariables
>;
export const SyncProjectPrioritiesDocument = gql`
  mutation syncProjectPriorities($projectId: ID!, $priorityIds: [ID!]!) {
    syncProjectPriorities(projectId: $projectId, priorityIds: $priorityIds) {
      ...project
    }
  }
  ${ProjectFragmentDoc}
  ${TypeFragmentDoc}
  ${StatusFragmentDoc}
  ${PriorityFragmentDoc}
`;
export type SyncProjectPrioritiesMutationFn = Apollo.MutationFunction<
  Types.SyncProjectPrioritiesMutation,
  Types.SyncProjectPrioritiesMutationVariables
>;

/**
 * __useSyncProjectPrioritiesMutation__
 *
 * To run a mutation, you first call `useSyncProjectPrioritiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncProjectPrioritiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncProjectPrioritiesMutation, { data, loading, error }] = useSyncProjectPrioritiesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      priorityIds: // value for 'priorityIds'
 *   },
 * });
 */
export function useSyncProjectPrioritiesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SyncProjectPrioritiesMutation,
    Types.SyncProjectPrioritiesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SyncProjectPrioritiesMutation,
    Types.SyncProjectPrioritiesMutationVariables
  >(SyncProjectPrioritiesDocument, options);
}
export type SyncProjectPrioritiesMutationHookResult = ReturnType<
  typeof useSyncProjectPrioritiesMutation
>;
export type SyncProjectPrioritiesMutationResult =
  Apollo.MutationResult<Types.SyncProjectPrioritiesMutation>;
export type SyncProjectPrioritiesMutationOptions = Apollo.BaseMutationOptions<
  Types.SyncProjectPrioritiesMutation,
  Types.SyncProjectPrioritiesMutationVariables
>;
export const SyncProjectStatusesDocument = gql`
  mutation syncProjectStatuses($projectId: ID!, $statusIds: [ID!]!) {
    syncProjectStatuses(projectId: $projectId, statusIds: $statusIds) {
      ...project
    }
  }
  ${ProjectFragmentDoc}
  ${TypeFragmentDoc}
  ${StatusFragmentDoc}
  ${PriorityFragmentDoc}
`;
export type SyncProjectStatusesMutationFn = Apollo.MutationFunction<
  Types.SyncProjectStatusesMutation,
  Types.SyncProjectStatusesMutationVariables
>;

/**
 * __useSyncProjectStatusesMutation__
 *
 * To run a mutation, you first call `useSyncProjectStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncProjectStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncProjectStatusesMutation, { data, loading, error }] = useSyncProjectStatusesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      statusIds: // value for 'statusIds'
 *   },
 * });
 */
export function useSyncProjectStatusesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SyncProjectStatusesMutation,
    Types.SyncProjectStatusesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SyncProjectStatusesMutation,
    Types.SyncProjectStatusesMutationVariables
  >(SyncProjectStatusesDocument, options);
}
export type SyncProjectStatusesMutationHookResult = ReturnType<
  typeof useSyncProjectStatusesMutation
>;
export type SyncProjectStatusesMutationResult =
  Apollo.MutationResult<Types.SyncProjectStatusesMutation>;
export type SyncProjectStatusesMutationOptions = Apollo.BaseMutationOptions<
  Types.SyncProjectStatusesMutation,
  Types.SyncProjectStatusesMutationVariables
>;
export const SyncRolePermissionsDocument = gql`
  mutation syncRolePermissions($roleId: String!, $permissions: [PermissionEnum!]!) {
    syncRolePermissions(roleId: $roleId, permissions: $permissions) {
      ...role
    }
  }
  ${RoleFragmentDoc}
`;
export type SyncRolePermissionsMutationFn = Apollo.MutationFunction<
  Types.SyncRolePermissionsMutation,
  Types.SyncRolePermissionsMutationVariables
>;

/**
 * __useSyncRolePermissionsMutation__
 *
 * To run a mutation, you first call `useSyncRolePermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncRolePermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncRolePermissionsMutation, { data, loading, error }] = useSyncRolePermissionsMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useSyncRolePermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SyncRolePermissionsMutation,
    Types.SyncRolePermissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SyncRolePermissionsMutation,
    Types.SyncRolePermissionsMutationVariables
  >(SyncRolePermissionsDocument, options);
}
export type SyncRolePermissionsMutationHookResult = ReturnType<
  typeof useSyncRolePermissionsMutation
>;
export type SyncRolePermissionsMutationResult =
  Apollo.MutationResult<Types.SyncRolePermissionsMutation>;
export type SyncRolePermissionsMutationOptions = Apollo.BaseMutationOptions<
  Types.SyncRolePermissionsMutation,
  Types.SyncRolePermissionsMutationVariables
>;
export const SyncUserPermissionsDocument = gql`
  mutation syncUserPermissions(
    $userId: String!
    $role: String!
    $directPermissions: [PermissionEnum!]!
  ) {
    syncUserPermissions(userId: $userId, role: $role, directPermissions: $directPermissions) {
      permissions
      user {
        id
      }
      role
    }
  }
`;
export type SyncUserPermissionsMutationFn = Apollo.MutationFunction<
  Types.SyncUserPermissionsMutation,
  Types.SyncUserPermissionsMutationVariables
>;

/**
 * __useSyncUserPermissionsMutation__
 *
 * To run a mutation, you first call `useSyncUserPermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncUserPermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncUserPermissionsMutation, { data, loading, error }] = useSyncUserPermissionsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      role: // value for 'role'
 *      directPermissions: // value for 'directPermissions'
 *   },
 * });
 */
export function useSyncUserPermissionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SyncUserPermissionsMutation,
    Types.SyncUserPermissionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SyncUserPermissionsMutation,
    Types.SyncUserPermissionsMutationVariables
  >(SyncUserPermissionsDocument, options);
}
export type SyncUserPermissionsMutationHookResult = ReturnType<
  typeof useSyncUserPermissionsMutation
>;
export type SyncUserPermissionsMutationResult =
  Apollo.MutationResult<Types.SyncUserPermissionsMutation>;
export type SyncUserPermissionsMutationOptions = Apollo.BaseMutationOptions<
  Types.SyncUserPermissionsMutation,
  Types.SyncUserPermissionsMutationVariables
>;
export const UnarchiveProjectDocument = gql`
  mutation unarchiveProject($id: ID!) {
    unarchiveProject(id: $id) {
      ...project
    }
  }
  ${ProjectFragmentDoc}
  ${TypeFragmentDoc}
  ${StatusFragmentDoc}
  ${PriorityFragmentDoc}
`;
export type UnarchiveProjectMutationFn = Apollo.MutationFunction<
  Types.UnarchiveProjectMutation,
  Types.UnarchiveProjectMutationVariables
>;

/**
 * __useUnarchiveProjectMutation__
 *
 * To run a mutation, you first call `useUnarchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveProjectMutation, { data, loading, error }] = useUnarchiveProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnarchiveProjectMutation,
    Types.UnarchiveProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UnarchiveProjectMutation,
    Types.UnarchiveProjectMutationVariables
  >(UnarchiveProjectDocument, options);
}
export type UnarchiveProjectMutationHookResult = ReturnType<typeof useUnarchiveProjectMutation>;
export type UnarchiveProjectMutationResult = Apollo.MutationResult<Types.UnarchiveProjectMutation>;
export type UnarchiveProjectMutationOptions = Apollo.BaseMutationOptions<
  Types.UnarchiveProjectMutation,
  Types.UnarchiveProjectMutationVariables
>;
export const UnblockUserDocument = gql`
  mutation unblockUser($id: ID!) {
    unblockUser(id: $id) {
      id
    }
  }
`;
export type UnblockUserMutationFn = Apollo.MutationFunction<
  Types.UnblockUserMutation,
  Types.UnblockUserMutationVariables
>;

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnblockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnblockUserMutation,
    Types.UnblockUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UnblockUserMutation, Types.UnblockUserMutationVariables>(
    UnblockUserDocument,
    options,
  );
}
export type UnblockUserMutationHookResult = ReturnType<typeof useUnblockUserMutation>;
export type UnblockUserMutationResult = Apollo.MutationResult<Types.UnblockUserMutation>;
export type UnblockUserMutationOptions = Apollo.BaseMutationOptions<
  Types.UnblockUserMutation,
  Types.UnblockUserMutationVariables
>;
export const UngroupPhrasesDocument = gql`
  mutation ungroupPhrases($ids: [String!]!) {
    ungroupPhrases(ids: $ids) {
      id
    }
  }
`;
export type UngroupPhrasesMutationFn = Apollo.MutationFunction<
  Types.UngroupPhrasesMutation,
  Types.UngroupPhrasesMutationVariables
>;

/**
 * __useUngroupPhrasesMutation__
 *
 * To run a mutation, you first call `useUngroupPhrasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUngroupPhrasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ungroupPhrasesMutation, { data, loading, error }] = useUngroupPhrasesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUngroupPhrasesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UngroupPhrasesMutation,
    Types.UngroupPhrasesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UngroupPhrasesMutation, Types.UngroupPhrasesMutationVariables>(
    UngroupPhrasesDocument,
    options,
  );
}
export type UngroupPhrasesMutationHookResult = ReturnType<typeof useUngroupPhrasesMutation>;
export type UngroupPhrasesMutationResult = Apollo.MutationResult<Types.UngroupPhrasesMutation>;
export type UngroupPhrasesMutationOptions = Apollo.BaseMutationOptions<
  Types.UngroupPhrasesMutation,
  Types.UngroupPhrasesMutationVariables
>;
export const UnwatchTicketDocument = gql`
  mutation unwatchTicket($ticketId: ID!, $userIds: [ID!]) {
    unwatchTicket(ticketId: $ticketId, userIds: $userIds) {
      ...ticket
    }
  }
  ${TicketFragmentDoc}
  ${UserWithPermInfoFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketFileFragmentDoc}
`;
export type UnwatchTicketMutationFn = Apollo.MutationFunction<
  Types.UnwatchTicketMutation,
  Types.UnwatchTicketMutationVariables
>;

/**
 * __useUnwatchTicketMutation__
 *
 * To run a mutation, you first call `useUnwatchTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnwatchTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unwatchTicketMutation, { data, loading, error }] = useUnwatchTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useUnwatchTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UnwatchTicketMutation,
    Types.UnwatchTicketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UnwatchTicketMutation, Types.UnwatchTicketMutationVariables>(
    UnwatchTicketDocument,
    options,
  );
}
export type UnwatchTicketMutationHookResult = ReturnType<typeof useUnwatchTicketMutation>;
export type UnwatchTicketMutationResult = Apollo.MutationResult<Types.UnwatchTicketMutation>;
export type UnwatchTicketMutationOptions = Apollo.BaseMutationOptions<
  Types.UnwatchTicketMutation,
  Types.UnwatchTicketMutationVariables
>;
export const UpdateCmsItemDocument = gql`
  mutation updateCMSItem($id: ID!, $value: String!) {
    updateCMSItem(id: $id, value: $value) {
      ...cms
    }
  }
  ${CmsFragmentDoc}
`;
export type UpdateCmsItemMutationFn = Apollo.MutationFunction<
  Types.UpdateCmsItemMutation,
  Types.UpdateCmsItemMutationVariables
>;

/**
 * __useUpdateCmsItemMutation__
 *
 * To run a mutation, you first call `useUpdateCmsItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCmsItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCmsItemMutation, { data, loading, error }] = useUpdateCmsItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateCmsItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCmsItemMutation,
    Types.UpdateCmsItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateCmsItemMutation, Types.UpdateCmsItemMutationVariables>(
    UpdateCmsItemDocument,
    options,
  );
}
export type UpdateCmsItemMutationHookResult = ReturnType<typeof useUpdateCmsItemMutation>;
export type UpdateCmsItemMutationResult = Apollo.MutationResult<Types.UpdateCmsItemMutation>;
export type UpdateCmsItemMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCmsItemMutation,
  Types.UpdateCmsItemMutationVariables
>;
export const UpdateClientSettingsDocument = gql`
  mutation updateClientSettings($id: ID!, $feSettings: String) {
    updateUser(id: $id, userInput: { feSettings: $feSettings }) {
      ...clientSettings
    }
  }
  ${ClientSettingsFragmentDoc}
`;
export type UpdateClientSettingsMutationFn = Apollo.MutationFunction<
  Types.UpdateClientSettingsMutation,
  Types.UpdateClientSettingsMutationVariables
>;

/**
 * __useUpdateClientSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateClientSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientSettingsMutation, { data, loading, error }] = useUpdateClientSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      feSettings: // value for 'feSettings'
 *   },
 * });
 */
export function useUpdateClientSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateClientSettingsMutation,
    Types.UpdateClientSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateClientSettingsMutation,
    Types.UpdateClientSettingsMutationVariables
  >(UpdateClientSettingsDocument, options);
}
export type UpdateClientSettingsMutationHookResult = ReturnType<
  typeof useUpdateClientSettingsMutation
>;
export type UpdateClientSettingsMutationResult =
  Apollo.MutationResult<Types.UpdateClientSettingsMutation>;
export type UpdateClientSettingsMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateClientSettingsMutation,
  Types.UpdateClientSettingsMutationVariables
>;
export const UpdateCredentialsDocument = gql`
  mutation updateCredentials(
    $id: String!
    $domainId: String
    $host: String
    $login: String
    $name: String
    $password: String
    $comment: String
  ) {
    updateCredentials(
      id: $id
      domainId: $domainId
      host: $host
      login: $login
      name: $name
      password: $password
      comment: $comment
    ) {
      ...credential
    }
  }
  ${CredentialFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type UpdateCredentialsMutationFn = Apollo.MutationFunction<
  Types.UpdateCredentialsMutation,
  Types.UpdateCredentialsMutationVariables
>;

/**
 * __useUpdateCredentialsMutation__
 *
 * To run a mutation, you first call `useUpdateCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCredentialsMutation, { data, loading, error }] = useUpdateCredentialsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      domainId: // value for 'domainId'
 *      host: // value for 'host'
 *      login: // value for 'login'
 *      name: // value for 'name'
 *      password: // value for 'password'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateCredentialsMutation,
    Types.UpdateCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateCredentialsMutation,
    Types.UpdateCredentialsMutationVariables
  >(UpdateCredentialsDocument, options);
}
export type UpdateCredentialsMutationHookResult = ReturnType<typeof useUpdateCredentialsMutation>;
export type UpdateCredentialsMutationResult =
  Apollo.MutationResult<Types.UpdateCredentialsMutation>;
export type UpdateCredentialsMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateCredentialsMutation,
  Types.UpdateCredentialsMutationVariables
>;
export const UpdateDealDocument = gql`
  mutation updateDeal(
    $id: ID!
    $ipressMonthly: Int
    $linkBuildingBudget: Float
    $diversificationLinksCount: Int
    $sponsoredArticlesCount: Int
    $lspTypeId: String
    $communicationLanguage: DomainLanguageEnum
    $status: DealStatusEnum
    $terminationShouldBeSaved: Boolean
    $suspensionDate: String
  ) {
    updateDeal(
      id: $id
      ipressMonthly: $ipressMonthly
      linkBuildingBudget: $linkBuildingBudget
      diversificationLinksCount: $diversificationLinksCount
      sponsoredArticlesCount: $sponsoredArticlesCount
      lspTypeId: $lspTypeId
      communicationLanguage: $communicationLanguage
      status: $status
      terminationShouldBeSaved: $terminationShouldBeSaved
      suspensionDate: $suspensionDate
    ) {
      ...deal
    }
  }
  ${DealFragmentDoc}
  ${LspTypeFragmentDoc}
  ${DiscountFragmentDoc}
  ${DealTerminationFragmentDoc}
`;
export type UpdateDealMutationFn = Apollo.MutationFunction<
  Types.UpdateDealMutation,
  Types.UpdateDealMutationVariables
>;

/**
 * __useUpdateDealMutation__
 *
 * To run a mutation, you first call `useUpdateDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealMutation, { data, loading, error }] = useUpdateDealMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ipressMonthly: // value for 'ipressMonthly'
 *      linkBuildingBudget: // value for 'linkBuildingBudget'
 *      diversificationLinksCount: // value for 'diversificationLinksCount'
 *      sponsoredArticlesCount: // value for 'sponsoredArticlesCount'
 *      lspTypeId: // value for 'lspTypeId'
 *      communicationLanguage: // value for 'communicationLanguage'
 *      status: // value for 'status'
 *      terminationShouldBeSaved: // value for 'terminationShouldBeSaved'
 *      suspensionDate: // value for 'suspensionDate'
 *   },
 * });
 */
export function useUpdateDealMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateDealMutation,
    Types.UpdateDealMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateDealMutation, Types.UpdateDealMutationVariables>(
    UpdateDealDocument,
    options,
  );
}
export type UpdateDealMutationHookResult = ReturnType<typeof useUpdateDealMutation>;
export type UpdateDealMutationResult = Apollo.MutationResult<Types.UpdateDealMutation>;
export type UpdateDealMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateDealMutation,
  Types.UpdateDealMutationVariables
>;
export const UpdateAdsBudgetDocument = gql`
  mutation updateAdsBudget($input: DomainAdsBudgetInput!, $id: String!) {
    updateDomainAdsBudget(input: $input, id: $id) {
      ...domainAdsBudget
    }
  }
  ${DomainAdsBudgetFragmentDoc}
`;
export type UpdateAdsBudgetMutationFn = Apollo.MutationFunction<
  Types.UpdateAdsBudgetMutation,
  Types.UpdateAdsBudgetMutationVariables
>;

/**
 * __useUpdateAdsBudgetMutation__
 *
 * To run a mutation, you first call `useUpdateAdsBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdsBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdsBudgetMutation, { data, loading, error }] = useUpdateAdsBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateAdsBudgetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateAdsBudgetMutation,
    Types.UpdateAdsBudgetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateAdsBudgetMutation, Types.UpdateAdsBudgetMutationVariables>(
    UpdateAdsBudgetDocument,
    options,
  );
}
export type UpdateAdsBudgetMutationHookResult = ReturnType<typeof useUpdateAdsBudgetMutation>;
export type UpdateAdsBudgetMutationResult = Apollo.MutationResult<Types.UpdateAdsBudgetMutation>;
export type UpdateAdsBudgetMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateAdsBudgetMutation,
  Types.UpdateAdsBudgetMutationVariables
>;
export const UpdateDomainDetailsDocument = gql`
  mutation updateDomainDetails(
    $id: ID!
    $maxPhrases: Int
    $name: String
    $s4sInstanceId: String
    $briefCopy: String
    $commentCopy: String
    $seoComment: String
    $csComment: String
    $adsComment: String
    $strategyLink: String
    $linkbuildingStrategy: LinkbuildingStrategyEnum
    $diversificationLinkbuildingStrategy: LinkbuildingStrategyEnum
    $contentMarketingSpreadsheet: String
    $linkBuildingDiversificationSpreadsheet: String
    $relatedDomainsIds: [ID!]
    $cms: String
    $country: CountryEnum
    $language: LanguageCodeEnum
    $categories: [CategoryEnum!]
    $countableTexts: [LinkGroupEnum!]
    $positionedDomain: String
    $fullUrl: String
    $fullUrlLocked: Boolean
    $serviceType: ServiceTypeEnum
    $textToBeWrittenBy: TextWriterEnum
  ) {
    updateDomain(
      id: $id
      maxPhrases: $maxPhrases
      name: $name
      s4sInstanceId: $s4sInstanceId
      briefCopy: $briefCopy
      commentCopy: $commentCopy
      seoComment: $seoComment
      csComment: $csComment
      adsComment: $adsComment
      strategyLink: $strategyLink
      linkbuildingStrategy: $linkbuildingStrategy
      diversificationLinkbuildingStrategy: $diversificationLinkbuildingStrategy
      categories: $categories
      countableTexts: $countableTexts
      language: $language
      cms: $cms
      contentMarketingSpreadsheet: $contentMarketingSpreadsheet
      linkBuildingDiversificationSpreadsheet: $linkBuildingDiversificationSpreadsheet
      country: $country
      relatedDomainsIds: $relatedDomainsIds
      positionedDomain: $positionedDomain
      fullUrl: $fullUrl
      fullUrlLocked: $fullUrlLocked
      serviceType: $serviceType
      textToBeWrittenBy: $textToBeWrittenBy
    ) {
      ...domainDetails
    }
  }
  ${DomainDetailsFragmentDoc}
  ${DomainBasicFragmentDoc}
  ${DealFragmentDoc}
  ${LspTypeFragmentDoc}
  ${DiscountFragmentDoc}
  ${DealTerminationFragmentDoc}
`;
export type UpdateDomainDetailsMutationFn = Apollo.MutationFunction<
  Types.UpdateDomainDetailsMutation,
  Types.UpdateDomainDetailsMutationVariables
>;

/**
 * __useUpdateDomainDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateDomainDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomainDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomainDetailsMutation, { data, loading, error }] = useUpdateDomainDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      maxPhrases: // value for 'maxPhrases'
 *      name: // value for 'name'
 *      s4sInstanceId: // value for 's4sInstanceId'
 *      briefCopy: // value for 'briefCopy'
 *      commentCopy: // value for 'commentCopy'
 *      seoComment: // value for 'seoComment'
 *      csComment: // value for 'csComment'
 *      adsComment: // value for 'adsComment'
 *      strategyLink: // value for 'strategyLink'
 *      linkbuildingStrategy: // value for 'linkbuildingStrategy'
 *      diversificationLinkbuildingStrategy: // value for 'diversificationLinkbuildingStrategy'
 *      contentMarketingSpreadsheet: // value for 'contentMarketingSpreadsheet'
 *      linkBuildingDiversificationSpreadsheet: // value for 'linkBuildingDiversificationSpreadsheet'
 *      relatedDomainsIds: // value for 'relatedDomainsIds'
 *      cms: // value for 'cms'
 *      country: // value for 'country'
 *      language: // value for 'language'
 *      categories: // value for 'categories'
 *      countableTexts: // value for 'countableTexts'
 *      positionedDomain: // value for 'positionedDomain'
 *      fullUrl: // value for 'fullUrl'
 *      fullUrlLocked: // value for 'fullUrlLocked'
 *      serviceType: // value for 'serviceType'
 *      textToBeWrittenBy: // value for 'textToBeWrittenBy'
 *   },
 * });
 */
export function useUpdateDomainDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateDomainDetailsMutation,
    Types.UpdateDomainDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateDomainDetailsMutation,
    Types.UpdateDomainDetailsMutationVariables
  >(UpdateDomainDetailsDocument, options);
}
export type UpdateDomainDetailsMutationHookResult = ReturnType<
  typeof useUpdateDomainDetailsMutation
>;
export type UpdateDomainDetailsMutationResult =
  Apollo.MutationResult<Types.UpdateDomainDetailsMutation>;
export type UpdateDomainDetailsMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateDomainDetailsMutation,
  Types.UpdateDomainDetailsMutationVariables
>;
export const UpdateDomainInfoDocument = gql`
  mutation updateDomainInfo(
    $id: ID!
    $name: String
    $adminUserIds: [ID!]
    $fulfillmentUserIds: [ID!]
    $salesUserIds: [ID!]
    $seoContactUserIds: [ID!]
    $semContactUserIds: [ID!]
    $semFulfillmentUserIds: [ID!]
    $uxUserIds: [ID!]
  ) {
    updateDomain(
      id: $id
      name: $name
      adminUserIds: $adminUserIds
      fulfillmentUserIds: $fulfillmentUserIds
      salesUserIds: $salesUserIds
      seoContactUserIds: $seoContactUserIds
      semContactUserIds: $semContactUserIds
      semFulfillmentUserIds: $semFulfillmentUserIds
      uxUserIds: $uxUserIds
    ) {
      ...domainView
    }
  }
  ${DomainViewFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type UpdateDomainInfoMutationFn = Apollo.MutationFunction<
  Types.UpdateDomainInfoMutation,
  Types.UpdateDomainInfoMutationVariables
>;

/**
 * __useUpdateDomainInfoMutation__
 *
 * To run a mutation, you first call `useUpdateDomainInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDomainInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDomainInfoMutation, { data, loading, error }] = useUpdateDomainInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      adminUserIds: // value for 'adminUserIds'
 *      fulfillmentUserIds: // value for 'fulfillmentUserIds'
 *      salesUserIds: // value for 'salesUserIds'
 *      seoContactUserIds: // value for 'seoContactUserIds'
 *      semContactUserIds: // value for 'semContactUserIds'
 *      semFulfillmentUserIds: // value for 'semFulfillmentUserIds'
 *      uxUserIds: // value for 'uxUserIds'
 *   },
 * });
 */
export function useUpdateDomainInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateDomainInfoMutation,
    Types.UpdateDomainInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateDomainInfoMutation,
    Types.UpdateDomainInfoMutationVariables
  >(UpdateDomainInfoDocument, options);
}
export type UpdateDomainInfoMutationHookResult = ReturnType<typeof useUpdateDomainInfoMutation>;
export type UpdateDomainInfoMutationResult = Apollo.MutationResult<Types.UpdateDomainInfoMutation>;
export type UpdateDomainInfoMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateDomainInfoMutation,
  Types.UpdateDomainInfoMutationVariables
>;
export const UpdateExternalReportSettingsDocument = gql`
  mutation updateExternalReportSettings(
    $id: ID!
    $sendEmail: Boolean
    $company: String
    $template: String
    $language: LanguageCodeEnum
    $country: CountryEnum
    $reportEmails: [String!]
    $reportLanguages: [String!]
    $brandedKeywords: [String!]
    $excludedKeywords: [String!]
    $ecommerce: Boolean
    $gamGaServiceId: String
    $gamSearchConsoleSiteId: String
    $gamGaServiceAudienceId: String
    $googleAdsAccountId: String
    $googleAdsReportLanguages: [String!]
    $googleAdsReportEmails: [String!]
    $googleAdsSendEmail: Boolean
    $startDate: String
  ) {
    updateExternalReportSettings(
      domainId: $id
      domainExternalReportSettingsInput: {
        sendEmail: $sendEmail
        company: $company
        template: $template
        language: $language
        country: $country
        reportEmails: $reportEmails
        reportLanguages: $reportLanguages
        brandedKeywords: $brandedKeywords
        excludedKeywords: $excludedKeywords
        ecommerce: $ecommerce
        gamGaServiceId: $gamGaServiceId
        gamSearchConsoleSiteId: $gamSearchConsoleSiteId
        gamGaServiceAudienceId: $gamGaServiceAudienceId
        googleAdsAccountId: $googleAdsAccountId
        googleAdsReportLanguages: $googleAdsReportLanguages
        googleAdsReportEmails: $googleAdsReportEmails
        googleAdsSendEmail: $googleAdsSendEmail
        startDate: $startDate
      }
    ) {
      ...externalReportSettings
    }
  }
  ${ExternalReportSettingsFragmentDoc}
`;
export type UpdateExternalReportSettingsMutationFn = Apollo.MutationFunction<
  Types.UpdateExternalReportSettingsMutation,
  Types.UpdateExternalReportSettingsMutationVariables
>;

/**
 * __useUpdateExternalReportSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateExternalReportSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExternalReportSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExternalReportSettingsMutation, { data, loading, error }] = useUpdateExternalReportSettingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sendEmail: // value for 'sendEmail'
 *      company: // value for 'company'
 *      template: // value for 'template'
 *      language: // value for 'language'
 *      country: // value for 'country'
 *      reportEmails: // value for 'reportEmails'
 *      reportLanguages: // value for 'reportLanguages'
 *      brandedKeywords: // value for 'brandedKeywords'
 *      excludedKeywords: // value for 'excludedKeywords'
 *      ecommerce: // value for 'ecommerce'
 *      gamGaServiceId: // value for 'gamGaServiceId'
 *      gamSearchConsoleSiteId: // value for 'gamSearchConsoleSiteId'
 *      gamGaServiceAudienceId: // value for 'gamGaServiceAudienceId'
 *      googleAdsAccountId: // value for 'googleAdsAccountId'
 *      googleAdsReportLanguages: // value for 'googleAdsReportLanguages'
 *      googleAdsReportEmails: // value for 'googleAdsReportEmails'
 *      googleAdsSendEmail: // value for 'googleAdsSendEmail'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useUpdateExternalReportSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateExternalReportSettingsMutation,
    Types.UpdateExternalReportSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateExternalReportSettingsMutation,
    Types.UpdateExternalReportSettingsMutationVariables
  >(UpdateExternalReportSettingsDocument, options);
}
export type UpdateExternalReportSettingsMutationHookResult = ReturnType<
  typeof useUpdateExternalReportSettingsMutation
>;
export type UpdateExternalReportSettingsMutationResult =
  Apollo.MutationResult<Types.UpdateExternalReportSettingsMutation>;
export type UpdateExternalReportSettingsMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateExternalReportSettingsMutation,
  Types.UpdateExternalReportSettingsMutationVariables
>;
export const UpdateGamInternalAccountDocument = gql`
  mutation updateGAMInternalAccount($id: ID!, $name: String!) {
    updateGAMInternalAccount(id: $id, name: $name) {
      ...gamInternalAccount
    }
  }
  ${GamInternalAccountFragmentDoc}
`;
export type UpdateGamInternalAccountMutationFn = Apollo.MutationFunction<
  Types.UpdateGamInternalAccountMutation,
  Types.UpdateGamInternalAccountMutationVariables
>;

/**
 * __useUpdateGamInternalAccountMutation__
 *
 * To run a mutation, you first call `useUpdateGamInternalAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGamInternalAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGamInternalAccountMutation, { data, loading, error }] = useUpdateGamInternalAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateGamInternalAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateGamInternalAccountMutation,
    Types.UpdateGamInternalAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateGamInternalAccountMutation,
    Types.UpdateGamInternalAccountMutationVariables
  >(UpdateGamInternalAccountDocument, options);
}
export type UpdateGamInternalAccountMutationHookResult = ReturnType<
  typeof useUpdateGamInternalAccountMutation
>;
export type UpdateGamInternalAccountMutationResult =
  Apollo.MutationResult<Types.UpdateGamInternalAccountMutation>;
export type UpdateGamInternalAccountMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateGamInternalAccountMutation,
  Types.UpdateGamInternalAccountMutationVariables
>;
export const UpdateImagesDocument = gql`
  mutation updateImages($images: [UpdateImageDTOInput!]!) {
    updateImages(images: $images) {
      id
    }
  }
`;
export type UpdateImagesMutationFn = Apollo.MutationFunction<
  Types.UpdateImagesMutation,
  Types.UpdateImagesMutationVariables
>;

/**
 * __useUpdateImagesMutation__
 *
 * To run a mutation, you first call `useUpdateImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImagesMutation, { data, loading, error }] = useUpdateImagesMutation({
 *   variables: {
 *      images: // value for 'images'
 *   },
 * });
 */
export function useUpdateImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateImagesMutation,
    Types.UpdateImagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateImagesMutation, Types.UpdateImagesMutationVariables>(
    UpdateImagesDocument,
    options,
  );
}
export type UpdateImagesMutationHookResult = ReturnType<typeof useUpdateImagesMutation>;
export type UpdateImagesMutationResult = Apollo.MutationResult<Types.UpdateImagesMutation>;
export type UpdateImagesMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateImagesMutation,
  Types.UpdateImagesMutationVariables
>;
export const UpdateLinkDocument = gql`
  mutation updateLink(
    $id: ID!
    $group: LinkGroupEnum
    $status: LinkStatusEnum
    $portal: String
    $articleUrl: String
    $characterCount: Int
    $price: Float
    $ownerId: ID
    $plannedPublicationDate: DateTime
    $realPublicationDate: DateTime
    $orderDate: DateTime
    $textDeliveryDate: DateTime
    $note: String
    $textUrl: String
    $copywriterId: ID
    $domainId: ID
    $anchors: [LinkAnchorDTOInput!]
  ) {
    updateLink(
      id: $id
      group: $group
      status: $status
      portal: $portal
      articleUrl: $articleUrl
      characterCount: $characterCount
      price: $price
      ownerId: $ownerId
      plannedPublicationDate: $plannedPublicationDate
      realPublicationDate: $realPublicationDate
      orderDate: $orderDate
      textDeliveryDate: $textDeliveryDate
      note: $note
      textUrl: $textUrl
      copywriterId: $copywriterId
      domainId: $domainId
      anchors: $anchors
    ) {
      ...link
    }
  }
  ${LinkFragmentDoc}
  ${LinkInTheListFragmentDoc}
  ${LinkBasicFragmentDoc}
  ${LinkAnchorBasicFragmentDoc}
  ${DomainBasicFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type UpdateLinkMutationFn = Apollo.MutationFunction<
  Types.UpdateLinkMutation,
  Types.UpdateLinkMutationVariables
>;

/**
 * __useUpdateLinkMutation__
 *
 * To run a mutation, you first call `useUpdateLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkMutation, { data, loading, error }] = useUpdateLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      group: // value for 'group'
 *      status: // value for 'status'
 *      portal: // value for 'portal'
 *      articleUrl: // value for 'articleUrl'
 *      characterCount: // value for 'characterCount'
 *      price: // value for 'price'
 *      ownerId: // value for 'ownerId'
 *      plannedPublicationDate: // value for 'plannedPublicationDate'
 *      realPublicationDate: // value for 'realPublicationDate'
 *      orderDate: // value for 'orderDate'
 *      textDeliveryDate: // value for 'textDeliveryDate'
 *      note: // value for 'note'
 *      textUrl: // value for 'textUrl'
 *      copywriterId: // value for 'copywriterId'
 *      domainId: // value for 'domainId'
 *      anchors: // value for 'anchors'
 *   },
 * });
 */
export function useUpdateLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateLinkMutation,
    Types.UpdateLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateLinkMutation, Types.UpdateLinkMutationVariables>(
    UpdateLinkDocument,
    options,
  );
}
export type UpdateLinkMutationHookResult = ReturnType<typeof useUpdateLinkMutation>;
export type UpdateLinkMutationResult = Apollo.MutationResult<Types.UpdateLinkMutation>;
export type UpdateLinkMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateLinkMutation,
  Types.UpdateLinkMutationVariables
>;
export const UpdateLspTypeDocument = gql`
  mutation updateLspType($id: ID!, $value: String!) {
    updateLspType(id: $id, value: $value) {
      ...lspType
    }
  }
  ${LspTypeFragmentDoc}
`;
export type UpdateLspTypeMutationFn = Apollo.MutationFunction<
  Types.UpdateLspTypeMutation,
  Types.UpdateLspTypeMutationVariables
>;

/**
 * __useUpdateLspTypeMutation__
 *
 * To run a mutation, you first call `useUpdateLspTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLspTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLspTypeMutation, { data, loading, error }] = useUpdateLspTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateLspTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateLspTypeMutation,
    Types.UpdateLspTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateLspTypeMutation, Types.UpdateLspTypeMutationVariables>(
    UpdateLspTypeDocument,
    options,
  );
}
export type UpdateLspTypeMutationHookResult = ReturnType<typeof useUpdateLspTypeMutation>;
export type UpdateLspTypeMutationResult = Apollo.MutationResult<Types.UpdateLspTypeMutation>;
export type UpdateLspTypeMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateLspTypeMutation,
  Types.UpdateLspTypeMutationVariables
>;
export const UpdateMonitorDocument = gql`
  mutation updateMonitor($id: String!, $newUrl: String!) {
    updateMonitor(id: $id, newUrl: $newUrl) {
      ...monitor
    }
  }
  ${MonitorFragmentDoc}
`;
export type UpdateMonitorMutationFn = Apollo.MutationFunction<
  Types.UpdateMonitorMutation,
  Types.UpdateMonitorMutationVariables
>;

/**
 * __useUpdateMonitorMutation__
 *
 * To run a mutation, you first call `useUpdateMonitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonitorMutation, { data, loading, error }] = useUpdateMonitorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newUrl: // value for 'newUrl'
 *   },
 * });
 */
export function useUpdateMonitorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateMonitorMutation,
    Types.UpdateMonitorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateMonitorMutation, Types.UpdateMonitorMutationVariables>(
    UpdateMonitorDocument,
    options,
  );
}
export type UpdateMonitorMutationHookResult = ReturnType<typeof useUpdateMonitorMutation>;
export type UpdateMonitorMutationResult = Apollo.MutationResult<Types.UpdateMonitorMutation>;
export type UpdateMonitorMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateMonitorMutation,
  Types.UpdateMonitorMutationVariables
>;
export const UpdateNotificationSettingDocument = gql`
  mutation updateNotificationSetting($id: ID!, $channel: String!, $value: Boolean!) {
    updateNotificationSetting(id: $id, channel: $channel, value: $value)
  }
`;
export type UpdateNotificationSettingMutationFn = Apollo.MutationFunction<
  Types.UpdateNotificationSettingMutation,
  Types.UpdateNotificationSettingMutationVariables
>;

/**
 * __useUpdateNotificationSettingMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationSettingMutation, { data, loading, error }] = useUpdateNotificationSettingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      channel: // value for 'channel'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateNotificationSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateNotificationSettingMutation,
    Types.UpdateNotificationSettingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateNotificationSettingMutation,
    Types.UpdateNotificationSettingMutationVariables
  >(UpdateNotificationSettingDocument, options);
}
export type UpdateNotificationSettingMutationHookResult = ReturnType<
  typeof useUpdateNotificationSettingMutation
>;
export type UpdateNotificationSettingMutationResult =
  Apollo.MutationResult<Types.UpdateNotificationSettingMutation>;
export type UpdateNotificationSettingMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateNotificationSettingMutation,
  Types.UpdateNotificationSettingMutationVariables
>;
export const UpdatePhraseDocument = gql`
  mutation updatePhrase($id: String!, $userAssignedUrl: String, $excludeFromLinking: Boolean) {
    updatePhrase(
      id: $id
      userAssignedUrl: $userAssignedUrl
      excludeFromLinking: $excludeFromLinking
    ) {
      id
      user_assigned_url
      exclude_from_linking
    }
  }
`;
export type UpdatePhraseMutationFn = Apollo.MutationFunction<
  Types.UpdatePhraseMutation,
  Types.UpdatePhraseMutationVariables
>;

/**
 * __useUpdatePhraseMutation__
 *
 * To run a mutation, you first call `useUpdatePhraseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhraseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhraseMutation, { data, loading, error }] = useUpdatePhraseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userAssignedUrl: // value for 'userAssignedUrl'
 *      excludeFromLinking: // value for 'excludeFromLinking'
 *   },
 * });
 */
export function useUpdatePhraseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdatePhraseMutation,
    Types.UpdatePhraseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdatePhraseMutation, Types.UpdatePhraseMutationVariables>(
    UpdatePhraseDocument,
    options,
  );
}
export type UpdatePhraseMutationHookResult = ReturnType<typeof useUpdatePhraseMutation>;
export type UpdatePhraseMutationResult = Apollo.MutationResult<Types.UpdatePhraseMutation>;
export type UpdatePhraseMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdatePhraseMutation,
  Types.UpdatePhraseMutationVariables
>;
export const UpdatePhraseGroupDocument = gql`
  mutation updatePhraseGroup($groupId: String!, $name: String, $phraseIds: [String!]) {
    updatePhraseGroup(groupId: $groupId, name: $name, phraseIds: $phraseIds) {
      ...phraseGroup
    }
  }
  ${PhraseGroupFragmentDoc}
`;
export type UpdatePhraseGroupMutationFn = Apollo.MutationFunction<
  Types.UpdatePhraseGroupMutation,
  Types.UpdatePhraseGroupMutationVariables
>;

/**
 * __useUpdatePhraseGroupMutation__
 *
 * To run a mutation, you first call `useUpdatePhraseGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhraseGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhraseGroupMutation, { data, loading, error }] = useUpdatePhraseGroupMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      name: // value for 'name'
 *      phraseIds: // value for 'phraseIds'
 *   },
 * });
 */
export function useUpdatePhraseGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdatePhraseGroupMutation,
    Types.UpdatePhraseGroupMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdatePhraseGroupMutation,
    Types.UpdatePhraseGroupMutationVariables
  >(UpdatePhraseGroupDocument, options);
}
export type UpdatePhraseGroupMutationHookResult = ReturnType<typeof useUpdatePhraseGroupMutation>;
export type UpdatePhraseGroupMutationResult =
  Apollo.MutationResult<Types.UpdatePhraseGroupMutation>;
export type UpdatePhraseGroupMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdatePhraseGroupMutation,
  Types.UpdatePhraseGroupMutationVariables
>;
export const UpdatePhraseUrlDocument = gql`
  mutation updatePhraseUrl($id: String!, $userAssignedUrl: String) {
    updatePhrase(id: $id, userAssignedUrl: $userAssignedUrl) {
      id
      user_assigned_url
    }
  }
`;
export type UpdatePhraseUrlMutationFn = Apollo.MutationFunction<
  Types.UpdatePhraseUrlMutation,
  Types.UpdatePhraseUrlMutationVariables
>;

/**
 * __useUpdatePhraseUrlMutation__
 *
 * To run a mutation, you first call `useUpdatePhraseUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhraseUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhraseUrlMutation, { data, loading, error }] = useUpdatePhraseUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userAssignedUrl: // value for 'userAssignedUrl'
 *   },
 * });
 */
export function useUpdatePhraseUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdatePhraseUrlMutation,
    Types.UpdatePhraseUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdatePhraseUrlMutation, Types.UpdatePhraseUrlMutationVariables>(
    UpdatePhraseUrlDocument,
    options,
  );
}
export type UpdatePhraseUrlMutationHookResult = ReturnType<typeof useUpdatePhraseUrlMutation>;
export type UpdatePhraseUrlMutationResult = Apollo.MutationResult<Types.UpdatePhraseUrlMutation>;
export type UpdatePhraseUrlMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdatePhraseUrlMutation,
  Types.UpdatePhraseUrlMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation updateProject($id: ID!, $name: String, $content: String, $priority: Float) {
    updateProject(id: $id, name: $name, content: $content, priority: $priority) {
      ...project
    }
  }
  ${ProjectFragmentDoc}
  ${TypeFragmentDoc}
  ${StatusFragmentDoc}
  ${PriorityFragmentDoc}
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  Types.UpdateProjectMutation,
  Types.UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      content: // value for 'content'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateProjectMutation,
    Types.UpdateProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateProjectMutation, Types.UpdateProjectMutationVariables>(
    UpdateProjectDocument,
    options,
  );
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<Types.UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateProjectMutation,
  Types.UpdateProjectMutationVariables
>;
export const UpdateQuarterOverrideDocument = gql`
  mutation updateQuarterOverride(
    $id: ID!
    $startDate: DateTime
    $endDate: DateTime
    $type: QuarterTypeEnum
    $charactersToCreate: Int
  ) {
    updateQuarterOverride(
      id: $id
      startDate: $startDate
      endDate: $endDate
      type: $type
      charactersToCreate: $charactersToCreate
    ) {
      ...quarterOverride
    }
  }
  ${QuarterOverrideFragmentDoc}
`;
export type UpdateQuarterOverrideMutationFn = Apollo.MutationFunction<
  Types.UpdateQuarterOverrideMutation,
  Types.UpdateQuarterOverrideMutationVariables
>;

/**
 * __useUpdateQuarterOverrideMutation__
 *
 * To run a mutation, you first call `useUpdateQuarterOverrideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuarterOverrideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuarterOverrideMutation, { data, loading, error }] = useUpdateQuarterOverrideMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      type: // value for 'type'
 *      charactersToCreate: // value for 'charactersToCreate'
 *   },
 * });
 */
export function useUpdateQuarterOverrideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateQuarterOverrideMutation,
    Types.UpdateQuarterOverrideMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateQuarterOverrideMutation,
    Types.UpdateQuarterOverrideMutationVariables
  >(UpdateQuarterOverrideDocument, options);
}
export type UpdateQuarterOverrideMutationHookResult = ReturnType<
  typeof useUpdateQuarterOverrideMutation
>;
export type UpdateQuarterOverrideMutationResult =
  Apollo.MutationResult<Types.UpdateQuarterOverrideMutation>;
export type UpdateQuarterOverrideMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateQuarterOverrideMutation,
  Types.UpdateQuarterOverrideMutationVariables
>;
export const UpdateRoleDocument = gql`
  mutation updateRole($id: ID!, $title: String!) {
    updateRole(id: $id, title: $title) {
      ...role
    }
  }
  ${RoleFragmentDoc}
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<
  Types.UpdateRoleMutation,
  Types.UpdateRoleMutationVariables
>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateRoleMutation,
    Types.UpdateRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateRoleMutation, Types.UpdateRoleMutationVariables>(
    UpdateRoleDocument,
    options,
  );
}
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<Types.UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateRoleMutation,
  Types.UpdateRoleMutationVariables
>;
export const UpdateS4sInstanceDocument = gql`
  mutation updateS4sInstance(
    $id: String!
    $name: String
    $host: String
    $port: Int
    $user: String
    $password: String
    $db: String
    $prefix: String
  ) {
    updateS4sInstance(
      id: $id
      name: $name
      host: $host
      port: $port
      user: $user
      password: $password
      db: $db
      prefix: $prefix
    ) {
      ...s4sInstance
    }
  }
  ${S4sInstanceFragmentDoc}
`;
export type UpdateS4sInstanceMutationFn = Apollo.MutationFunction<
  Types.UpdateS4sInstanceMutation,
  Types.UpdateS4sInstanceMutationVariables
>;

/**
 * __useUpdateS4sInstanceMutation__
 *
 * To run a mutation, you first call `useUpdateS4sInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateS4sInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateS4sInstanceMutation, { data, loading, error }] = useUpdateS4sInstanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      host: // value for 'host'
 *      port: // value for 'port'
 *      user: // value for 'user'
 *      password: // value for 'password'
 *      db: // value for 'db'
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useUpdateS4sInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateS4sInstanceMutation,
    Types.UpdateS4sInstanceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateS4sInstanceMutation,
    Types.UpdateS4sInstanceMutationVariables
  >(UpdateS4sInstanceDocument, options);
}
export type UpdateS4sInstanceMutationHookResult = ReturnType<typeof useUpdateS4sInstanceMutation>;
export type UpdateS4sInstanceMutationResult =
  Apollo.MutationResult<Types.UpdateS4sInstanceMutation>;
export type UpdateS4sInstanceMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateS4sInstanceMutation,
  Types.UpdateS4sInstanceMutationVariables
>;
export const UpdateSubpageDocument = gql`
  mutation updateSubpage(
    $id: ID!
    $url: String
    $type: SubpageTypeEnum
    $status: SubpageStatusEnum
    $priority: SubpagePriorityEnum
    $title: String
    $heading: String
    $description: String
    $characterCount: Int
    $targetTitle: String
    $targetHeading: String
    $targetDescription: String
    $targetCharacterCount: Int
    $domainId: String
    $keywords: [String!]
  ) {
    updateSubpage(
      id: $id
      input: {
        url: $url
        type: $type
        status: $status
        priority: $priority
        title: $title
        heading: $heading
        description: $description
        characterCount: $characterCount
        targetTitle: $targetTitle
        targetHeading: $targetHeading
        targetDescription: $targetDescription
        targetCharacterCount: $targetCharacterCount
        domainId: $domainId
        keywords: $keywords
      }
    ) {
      ...subpage
    }
  }
  ${SubpageFragmentDoc}
`;
export type UpdateSubpageMutationFn = Apollo.MutationFunction<
  Types.UpdateSubpageMutation,
  Types.UpdateSubpageMutationVariables
>;

/**
 * __useUpdateSubpageMutation__
 *
 * To run a mutation, you first call `useUpdateSubpageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubpageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubpageMutation, { data, loading, error }] = useUpdateSubpageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      url: // value for 'url'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      priority: // value for 'priority'
 *      title: // value for 'title'
 *      heading: // value for 'heading'
 *      description: // value for 'description'
 *      characterCount: // value for 'characterCount'
 *      targetTitle: // value for 'targetTitle'
 *      targetHeading: // value for 'targetHeading'
 *      targetDescription: // value for 'targetDescription'
 *      targetCharacterCount: // value for 'targetCharacterCount'
 *      domainId: // value for 'domainId'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useUpdateSubpageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateSubpageMutation,
    Types.UpdateSubpageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateSubpageMutation, Types.UpdateSubpageMutationVariables>(
    UpdateSubpageDocument,
    options,
  );
}
export type UpdateSubpageMutationHookResult = ReturnType<typeof useUpdateSubpageMutation>;
export type UpdateSubpageMutationResult = Apollo.MutationResult<Types.UpdateSubpageMutation>;
export type UpdateSubpageMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateSubpageMutation,
  Types.UpdateSubpageMutationVariables
>;
export const UpdateTeamDocument = gql`
  mutation updateTeam($id: ID!, $name: String, $leaderId: ID, $memberIds: [ID!]!) {
    updateTeam(id: $id, name: $name, leaderId: $leaderId, memberIds: $memberIds) {
      ...team
    }
  }
  ${TeamFragmentDoc}
  ${UserInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type UpdateTeamMutationFn = Apollo.MutationFunction<
  Types.UpdateTeamMutation,
  Types.UpdateTeamMutationVariables
>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      leaderId: // value for 'leaderId'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useUpdateTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTeamMutation,
    Types.UpdateTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateTeamMutation, Types.UpdateTeamMutationVariables>(
    UpdateTeamDocument,
    options,
  );
}
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<Types.UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTeamMutation,
  Types.UpdateTeamMutationVariables
>;
export const UpdateTextOrdersDocument = gql`
  mutation updateTextOrders($inputs: [UpdateTextOrdersDTOInput!]!) {
    updateTextOrders(inputs: $inputs) {
      ...textOrder
    }
  }
  ${TextOrderFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type UpdateTextOrdersMutationFn = Apollo.MutationFunction<
  Types.UpdateTextOrdersMutation,
  Types.UpdateTextOrdersMutationVariables
>;

/**
 * __useUpdateTextOrdersMutation__
 *
 * To run a mutation, you first call `useUpdateTextOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTextOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTextOrdersMutation, { data, loading, error }] = useUpdateTextOrdersMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateTextOrdersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTextOrdersMutation,
    Types.UpdateTextOrdersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateTextOrdersMutation,
    Types.UpdateTextOrdersMutationVariables
  >(UpdateTextOrdersDocument, options);
}
export type UpdateTextOrdersMutationHookResult = ReturnType<typeof useUpdateTextOrdersMutation>;
export type UpdateTextOrdersMutationResult = Apollo.MutationResult<Types.UpdateTextOrdersMutation>;
export type UpdateTextOrdersMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTextOrdersMutation,
  Types.UpdateTextOrdersMutationVariables
>;
export const UpdateTicketDocument = gql`
  mutation updateTicket(
    $id: ID!
    $title: String
    $contentHtml: String
    $priorityId: ID
    $projectId: ID
    $typeId: ID
    $statusId: ID
    $domainId: ID
    $ownerId: ID
    $deadline: DateTime
    $start: DateTime
    $isDraft: Boolean
  ) {
    updateTicket(
      id: $id
      title: $title
      contentHtml: $contentHtml
      priorityId: $priorityId
      projectId: $projectId
      typeId: $typeId
      statusId: $statusId
      domainId: $domainId
      ownerId: $ownerId
      deadline: $deadline
      start: $start
      isDraft: $isDraft
    ) {
      ...ticket
    }
  }
  ${TicketFragmentDoc}
  ${UserWithPermInfoFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketFileFragmentDoc}
`;
export type UpdateTicketMutationFn = Apollo.MutationFunction<
  Types.UpdateTicketMutation,
  Types.UpdateTicketMutationVariables
>;

/**
 * __useUpdateTicketMutation__
 *
 * To run a mutation, you first call `useUpdateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketMutation, { data, loading, error }] = useUpdateTicketMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      contentHtml: // value for 'contentHtml'
 *      priorityId: // value for 'priorityId'
 *      projectId: // value for 'projectId'
 *      typeId: // value for 'typeId'
 *      statusId: // value for 'statusId'
 *      domainId: // value for 'domainId'
 *      ownerId: // value for 'ownerId'
 *      deadline: // value for 'deadline'
 *      start: // value for 'start'
 *      isDraft: // value for 'isDraft'
 *   },
 * });
 */
export function useUpdateTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTicketMutation,
    Types.UpdateTicketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateTicketMutation, Types.UpdateTicketMutationVariables>(
    UpdateTicketDocument,
    options,
  );
}
export type UpdateTicketMutationHookResult = ReturnType<typeof useUpdateTicketMutation>;
export type UpdateTicketMutationResult = Apollo.MutationResult<Types.UpdateTicketMutation>;
export type UpdateTicketMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTicketMutation,
  Types.UpdateTicketMutationVariables
>;
export const UpdateTicketCommentDocument = gql`
  mutation updateTicketComment($id: ID!, $contentHtml: String!) {
    updateTicketComment(id: $id, contentHtml: $contentHtml) {
      ...ticketComment
    }
  }
  ${TicketCommentFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketCommentFileFragmentDoc}
`;
export type UpdateTicketCommentMutationFn = Apollo.MutationFunction<
  Types.UpdateTicketCommentMutation,
  Types.UpdateTicketCommentMutationVariables
>;

/**
 * __useUpdateTicketCommentMutation__
 *
 * To run a mutation, you first call `useUpdateTicketCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketCommentMutation, { data, loading, error }] = useUpdateTicketCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contentHtml: // value for 'contentHtml'
 *   },
 * });
 */
export function useUpdateTicketCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTicketCommentMutation,
    Types.UpdateTicketCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateTicketCommentMutation,
    Types.UpdateTicketCommentMutationVariables
  >(UpdateTicketCommentDocument, options);
}
export type UpdateTicketCommentMutationHookResult = ReturnType<
  typeof useUpdateTicketCommentMutation
>;
export type UpdateTicketCommentMutationResult =
  Apollo.MutationResult<Types.UpdateTicketCommentMutation>;
export type UpdateTicketCommentMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTicketCommentMutation,
  Types.UpdateTicketCommentMutationVariables
>;
export const UpdateTicketPriorityDocument = gql`
  mutation updateTicketPriority(
    $id: ID!
    $value: String
    $archived: Boolean
    $color: ColorPaletteEnum
    $priority: Float
  ) {
    updateTicketPriority(
      id: $id
      value: $value
      archived: $archived
      color: $color
      priority: $priority
    ) {
      ...priority
    }
  }
  ${PriorityFragmentDoc}
`;
export type UpdateTicketPriorityMutationFn = Apollo.MutationFunction<
  Types.UpdateTicketPriorityMutation,
  Types.UpdateTicketPriorityMutationVariables
>;

/**
 * __useUpdateTicketPriorityMutation__
 *
 * To run a mutation, you first call `useUpdateTicketPriorityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketPriorityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketPriorityMutation, { data, loading, error }] = useUpdateTicketPriorityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *      archived: // value for 'archived'
 *      color: // value for 'color'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useUpdateTicketPriorityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTicketPriorityMutation,
    Types.UpdateTicketPriorityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateTicketPriorityMutation,
    Types.UpdateTicketPriorityMutationVariables
  >(UpdateTicketPriorityDocument, options);
}
export type UpdateTicketPriorityMutationHookResult = ReturnType<
  typeof useUpdateTicketPriorityMutation
>;
export type UpdateTicketPriorityMutationResult =
  Apollo.MutationResult<Types.UpdateTicketPriorityMutation>;
export type UpdateTicketPriorityMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTicketPriorityMutation,
  Types.UpdateTicketPriorityMutationVariables
>;
export const UpdateTicketStatusDocument = gql`
  mutation updateTicketStatus(
    $id: ID!
    $value: String
    $treatAsClosed: Boolean
    $archived: Boolean
    $color: ColorPaletteEnum
    $visibleInKanban: Boolean
    $priority: Float
  ) {
    updateTicketStatus(
      id: $id
      value: $value
      treatAsClosed: $treatAsClosed
      archived: $archived
      color: $color
      visibleInKanban: $visibleInKanban
      priority: $priority
    ) {
      ...status
    }
  }
  ${StatusFragmentDoc}
`;
export type UpdateTicketStatusMutationFn = Apollo.MutationFunction<
  Types.UpdateTicketStatusMutation,
  Types.UpdateTicketStatusMutationVariables
>;

/**
 * __useUpdateTicketStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTicketStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketStatusMutation, { data, loading, error }] = useUpdateTicketStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *      treatAsClosed: // value for 'treatAsClosed'
 *      archived: // value for 'archived'
 *      color: // value for 'color'
 *      visibleInKanban: // value for 'visibleInKanban'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useUpdateTicketStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTicketStatusMutation,
    Types.UpdateTicketStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateTicketStatusMutation,
    Types.UpdateTicketStatusMutationVariables
  >(UpdateTicketStatusDocument, options);
}
export type UpdateTicketStatusMutationHookResult = ReturnType<typeof useUpdateTicketStatusMutation>;
export type UpdateTicketStatusMutationResult =
  Apollo.MutationResult<Types.UpdateTicketStatusMutation>;
export type UpdateTicketStatusMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTicketStatusMutation,
  Types.UpdateTicketStatusMutationVariables
>;
export const UpdateTicketTimeDocument = gql`
  mutation updateTicketTime($id: ID!, $minutes: Int!) {
    updateTicketTime(id: $id, minutes: $minutes) {
      ...timeLog
    }
  }
  ${TimeLogFragmentDoc}
  ${UserBasicFragmentDoc}
`;
export type UpdateTicketTimeMutationFn = Apollo.MutationFunction<
  Types.UpdateTicketTimeMutation,
  Types.UpdateTicketTimeMutationVariables
>;

/**
 * __useUpdateTicketTimeMutation__
 *
 * To run a mutation, you first call `useUpdateTicketTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketTimeMutation, { data, loading, error }] = useUpdateTicketTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      minutes: // value for 'minutes'
 *   },
 * });
 */
export function useUpdateTicketTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTicketTimeMutation,
    Types.UpdateTicketTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateTicketTimeMutation,
    Types.UpdateTicketTimeMutationVariables
  >(UpdateTicketTimeDocument, options);
}
export type UpdateTicketTimeMutationHookResult = ReturnType<typeof useUpdateTicketTimeMutation>;
export type UpdateTicketTimeMutationResult = Apollo.MutationResult<Types.UpdateTicketTimeMutation>;
export type UpdateTicketTimeMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTicketTimeMutation,
  Types.UpdateTicketTimeMutationVariables
>;
export const UpdateTicketTypeDocument = gql`
  mutation updateTicketType(
    $id: ID!
    $projectId: ID
    $value: String
    $archived: Boolean
    $color: ColorPaletteEnum
    $priority: Float
  ) {
    updateTicketType(
      id: $id
      projectId: $projectId
      value: $value
      archived: $archived
      color: $color
      priority: $priority
    ) {
      ...type
    }
  }
  ${TypeFragmentDoc}
`;
export type UpdateTicketTypeMutationFn = Apollo.MutationFunction<
  Types.UpdateTicketTypeMutation,
  Types.UpdateTicketTypeMutationVariables
>;

/**
 * __useUpdateTicketTypeMutation__
 *
 * To run a mutation, you first call `useUpdateTicketTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTicketTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTicketTypeMutation, { data, loading, error }] = useUpdateTicketTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *      value: // value for 'value'
 *      archived: // value for 'archived'
 *      color: // value for 'color'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useUpdateTicketTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTicketTypeMutation,
    Types.UpdateTicketTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateTicketTypeMutation,
    Types.UpdateTicketTypeMutationVariables
  >(UpdateTicketTypeDocument, options);
}
export type UpdateTicketTypeMutationHookResult = ReturnType<typeof useUpdateTicketTypeMutation>;
export type UpdateTicketTypeMutationResult = Apollo.MutationResult<Types.UpdateTicketTypeMutation>;
export type UpdateTicketTypeMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTicketTypeMutation,
  Types.UpdateTicketTypeMutationVariables
>;
export const UpdateTodoDocument = gql`
  mutation updateTodo($id: ID!, $text: String, $priority: Float, $status: TodoStatusEnum) {
    updateTodo(id: $id, text: $text, priority: $priority, status: $status) {
      ...ticketTodo
    }
  }
  ${TicketTodoFragmentDoc}
`;
export type UpdateTodoMutationFn = Apollo.MutationFunction<
  Types.UpdateTodoMutation,
  Types.UpdateTodoMutationVariables
>;

/**
 * __useUpdateTodoMutation__
 *
 * To run a mutation, you first call `useUpdateTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTodoMutation, { data, loading, error }] = useUpdateTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *      priority: // value for 'priority'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateTodoMutation,
    Types.UpdateTodoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateTodoMutation, Types.UpdateTodoMutationVariables>(
    UpdateTodoDocument,
    options,
  );
}
export type UpdateTodoMutationHookResult = ReturnType<typeof useUpdateTodoMutation>;
export type UpdateTodoMutationResult = Apollo.MutationResult<Types.UpdateTodoMutation>;
export type UpdateTodoMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateTodoMutation,
  Types.UpdateTodoMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser(
    $id: ID!
    $email: String
    $firstName: String
    $lastName: String
    $phone: String
    $role: String
    $permissions: [PermissionEnum!]
    $password: String
    $crmImportId: Int
    $appLocale: LocaleEnum
  ) {
    updateUser(
      id: $id
      userInput: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        role: $role
        permissions: $permissions
        password: $password
        crmImportId: $crmImportId
        appLocale: $appLocale
      }
    ) {
      ...user
    }
  }
  ${UserFragmentDoc}
  ${UserInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TeamBasicFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  Types.UpdateUserMutation,
  Types.UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      role: // value for 'role'
 *      permissions: // value for 'permissions'
 *      password: // value for 'password'
 *      crmImportId: // value for 'crmImportId'
 *      appLocale: // value for 'appLocale'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateUserMutation,
    Types.UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdateUserMutation, Types.UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<Types.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateUserMutation,
  Types.UpdateUserMutationVariables
>;
export const WatchTicketDocument = gql`
  mutation watchTicket($ticketId: ID!, $userIds: [ID!]) {
    watchTicket(ticketId: $ticketId, userIds: $userIds) {
      ...ticket
    }
  }
  ${TicketFragmentDoc}
  ${UserWithPermInfoFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketFileFragmentDoc}
`;
export type WatchTicketMutationFn = Apollo.MutationFunction<
  Types.WatchTicketMutation,
  Types.WatchTicketMutationVariables
>;

/**
 * __useWatchTicketMutation__
 *
 * To run a mutation, you first call `useWatchTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWatchTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [watchTicketMutation, { data, loading, error }] = useWatchTicketMutation({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useWatchTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.WatchTicketMutation,
    Types.WatchTicketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.WatchTicketMutation, Types.WatchTicketMutationVariables>(
    WatchTicketDocument,
    options,
  );
}
export type WatchTicketMutationHookResult = ReturnType<typeof useWatchTicketMutation>;
export type WatchTicketMutationResult = Apollo.MutationResult<Types.WatchTicketMutation>;
export type WatchTicketMutationOptions = Apollo.BaseMutationOptions<
  Types.WatchTicketMutation,
  Types.WatchTicketMutationVariables
>;
export const ActiveUserDocument = gql`
  query activeUser {
    activeUser {
      ...user
    }
  }
  ${UserFragmentDoc}
  ${UserInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TeamBasicFragmentDoc}
`;

/**
 * __useActiveUserQuery__
 *
 * To run a query within a React component, call `useActiveUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveUserQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ActiveUserQuery, Types.ActiveUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ActiveUserQuery, Types.ActiveUserQueryVariables>(
    ActiveUserDocument,
    options,
  );
}
export function useActiveUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ActiveUserQuery, Types.ActiveUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ActiveUserQuery, Types.ActiveUserQueryVariables>(
    ActiveUserDocument,
    options,
  );
}
export type ActiveUserQueryHookResult = ReturnType<typeof useActiveUserQuery>;
export type ActiveUserLazyQueryHookResult = ReturnType<typeof useActiveUserLazyQuery>;
export type ActiveUserQueryResult = Apollo.QueryResult<
  Types.ActiveUserQuery,
  Types.ActiveUserQueryVariables
>;
export function refetchActiveUserQuery(variables?: Types.ActiveUserQueryVariables) {
  return { query: ActiveUserDocument, variables: variables };
}
export const ClientSettingsDocument = gql`
  query clientSettings($id: ID!) {
    user(id: $id) {
      ...clientSettings
    }
  }
  ${ClientSettingsFragmentDoc}
`;

/**
 * __useClientSettingsQuery__
 *
 * To run a query within a React component, call `useClientSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ClientSettingsQuery,
    Types.ClientSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ClientSettingsQuery, Types.ClientSettingsQueryVariables>(
    ClientSettingsDocument,
    options,
  );
}
export function useClientSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ClientSettingsQuery,
    Types.ClientSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ClientSettingsQuery, Types.ClientSettingsQueryVariables>(
    ClientSettingsDocument,
    options,
  );
}
export type ClientSettingsQueryHookResult = ReturnType<typeof useClientSettingsQuery>;
export type ClientSettingsLazyQueryHookResult = ReturnType<typeof useClientSettingsLazyQuery>;
export type ClientSettingsQueryResult = Apollo.QueryResult<
  Types.ClientSettingsQuery,
  Types.ClientSettingsQueryVariables
>;
export function refetchClientSettingsQuery(variables: Types.ClientSettingsQueryVariables) {
  return { query: ClientSettingsDocument, variables: variables };
}
export const ColorPaletteDocument = gql`
  query colorPalette {
    colorPalette {
      id
      key
    }
  }
`;

/**
 * __useColorPaletteQuery__
 *
 * To run a query within a React component, call `useColorPaletteQuery` and pass it any options that fit your needs.
 * When your component renders, `useColorPaletteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useColorPaletteQuery({
 *   variables: {
 *   },
 * });
 */
export function useColorPaletteQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ColorPaletteQuery, Types.ColorPaletteQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ColorPaletteQuery, Types.ColorPaletteQueryVariables>(
    ColorPaletteDocument,
    options,
  );
}
export function useColorPaletteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ColorPaletteQuery,
    Types.ColorPaletteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ColorPaletteQuery, Types.ColorPaletteQueryVariables>(
    ColorPaletteDocument,
    options,
  );
}
export type ColorPaletteQueryHookResult = ReturnType<typeof useColorPaletteQuery>;
export type ColorPaletteLazyQueryHookResult = ReturnType<typeof useColorPaletteLazyQuery>;
export type ColorPaletteQueryResult = Apollo.QueryResult<
  Types.ColorPaletteQuery,
  Types.ColorPaletteQueryVariables
>;
export function refetchColorPaletteQuery(variables?: Types.ColorPaletteQueryVariables) {
  return { query: ColorPaletteDocument, variables: variables };
}
export const CommunicationLanguagesDocument = gql`
  query communicationLanguages {
    communicationLanguages {
      id
      key
      value
    }
  }
`;

/**
 * __useCommunicationLanguagesQuery__
 *
 * To run a query within a React component, call `useCommunicationLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunicationLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunicationLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommunicationLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CommunicationLanguagesQuery,
    Types.CommunicationLanguagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CommunicationLanguagesQuery,
    Types.CommunicationLanguagesQueryVariables
  >(CommunicationLanguagesDocument, options);
}
export function useCommunicationLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CommunicationLanguagesQuery,
    Types.CommunicationLanguagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CommunicationLanguagesQuery,
    Types.CommunicationLanguagesQueryVariables
  >(CommunicationLanguagesDocument, options);
}
export type CommunicationLanguagesQueryHookResult = ReturnType<
  typeof useCommunicationLanguagesQuery
>;
export type CommunicationLanguagesLazyQueryHookResult = ReturnType<
  typeof useCommunicationLanguagesLazyQuery
>;
export type CommunicationLanguagesQueryResult = Apollo.QueryResult<
  Types.CommunicationLanguagesQuery,
  Types.CommunicationLanguagesQueryVariables
>;
export function refetchCommunicationLanguagesQuery(
  variables?: Types.CommunicationLanguagesQueryVariables,
) {
  return { query: CommunicationLanguagesDocument, variables: variables };
}
export const CompaniesExternalReportDocument = gql`
  query companiesExternalReport {
    companiesExternalReport {
      id
      key
    }
  }
`;

/**
 * __useCompaniesExternalReportQuery__
 *
 * To run a query within a React component, call `useCompaniesExternalReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesExternalReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesExternalReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesExternalReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.CompaniesExternalReportQuery,
    Types.CompaniesExternalReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CompaniesExternalReportQuery,
    Types.CompaniesExternalReportQueryVariables
  >(CompaniesExternalReportDocument, options);
}
export function useCompaniesExternalReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CompaniesExternalReportQuery,
    Types.CompaniesExternalReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CompaniesExternalReportQuery,
    Types.CompaniesExternalReportQueryVariables
  >(CompaniesExternalReportDocument, options);
}
export type CompaniesExternalReportQueryHookResult = ReturnType<
  typeof useCompaniesExternalReportQuery
>;
export type CompaniesExternalReportLazyQueryHookResult = ReturnType<
  typeof useCompaniesExternalReportLazyQuery
>;
export type CompaniesExternalReportQueryResult = Apollo.QueryResult<
  Types.CompaniesExternalReportQuery,
  Types.CompaniesExternalReportQueryVariables
>;
export function refetchCompaniesExternalReportQuery(
  variables?: Types.CompaniesExternalReportQueryVariables,
) {
  return { query: CompaniesExternalReportDocument, variables: variables };
}
export const CountriesDocument = gql`
  query countries {
    countries {
      id
      key
      value
    }
  }
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CountriesQuery, Types.CountriesQueryVariables>(
    CountriesDocument,
    options,
  );
}
export function useCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CountriesQuery, Types.CountriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CountriesQuery, Types.CountriesQueryVariables>(
    CountriesDocument,
    options,
  );
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<
  Types.CountriesQuery,
  Types.CountriesQueryVariables
>;
export function refetchCountriesQuery(variables?: Types.CountriesQueryVariables) {
  return { query: CountriesDocument, variables: variables };
}
export const CredentialLoginDocument = gql`
  query credentialLogin($id: ID!) {
    credential(id: $id) {
      ...credentialLogin
    }
  }
  ${CredentialLoginFragmentDoc}
  ${CredentialFilesFragmentDoc}
`;

/**
 * __useCredentialLoginQuery__
 *
 * To run a query within a React component, call `useCredentialLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useCredentialLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCredentialLoginQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCredentialLoginQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CredentialLoginQuery,
    Types.CredentialLoginQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CredentialLoginQuery, Types.CredentialLoginQueryVariables>(
    CredentialLoginDocument,
    options,
  );
}
export function useCredentialLoginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CredentialLoginQuery,
    Types.CredentialLoginQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CredentialLoginQuery, Types.CredentialLoginQueryVariables>(
    CredentialLoginDocument,
    options,
  );
}
export type CredentialLoginQueryHookResult = ReturnType<typeof useCredentialLoginQuery>;
export type CredentialLoginLazyQueryHookResult = ReturnType<typeof useCredentialLoginLazyQuery>;
export type CredentialLoginQueryResult = Apollo.QueryResult<
  Types.CredentialLoginQuery,
  Types.CredentialLoginQueryVariables
>;
export function refetchCredentialLoginQuery(variables: Types.CredentialLoginQueryVariables) {
  return { query: CredentialLoginDocument, variables: variables };
}
export const CredentialsDocument = gql`
  query credentials($domainId: String!) {
    credentials(domainId: $domainId) {
      ...credential
    }
  }
  ${CredentialFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useCredentialsQuery__
 *
 * To run a query within a React component, call `useCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCredentialsQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useCredentialsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.CredentialsQuery, Types.CredentialsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CredentialsQuery, Types.CredentialsQueryVariables>(
    CredentialsDocument,
    options,
  );
}
export function useCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CredentialsQuery,
    Types.CredentialsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CredentialsQuery, Types.CredentialsQueryVariables>(
    CredentialsDocument,
    options,
  );
}
export type CredentialsQueryHookResult = ReturnType<typeof useCredentialsQuery>;
export type CredentialsLazyQueryHookResult = ReturnType<typeof useCredentialsLazyQuery>;
export type CredentialsQueryResult = Apollo.QueryResult<
  Types.CredentialsQuery,
  Types.CredentialsQueryVariables
>;
export function refetchCredentialsQuery(variables: Types.CredentialsQueryVariables) {
  return { query: CredentialsDocument, variables: variables };
}
export const DealDocument = gql`
  query deal($id: ID!) {
    deal(id: $id) {
      ...deal
    }
  }
  ${DealFragmentDoc}
  ${LspTypeFragmentDoc}
  ${DiscountFragmentDoc}
  ${DealTerminationFragmentDoc}
`;

/**
 * __useDealQuery__
 *
 * To run a query within a React component, call `useDealQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDealQuery(
  baseOptions: Apollo.QueryHookOptions<Types.DealQuery, Types.DealQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealQuery, Types.DealQueryVariables>(DealDocument, options);
}
export function useDealLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.DealQuery, Types.DealQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealQuery, Types.DealQueryVariables>(DealDocument, options);
}
export type DealQueryHookResult = ReturnType<typeof useDealQuery>;
export type DealLazyQueryHookResult = ReturnType<typeof useDealLazyQuery>;
export type DealQueryResult = Apollo.QueryResult<Types.DealQuery, Types.DealQueryVariables>;
export function refetchDealQuery(variables: Types.DealQueryVariables) {
  return { query: DealDocument, variables: variables };
}
export const DealBasketsDocument = gql`
  query dealBaskets {
    dealBaskets {
      id
      key
    }
  }
`;

/**
 * __useDealBasketsQuery__
 *
 * To run a query within a React component, call `useDealBasketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealBasketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealBasketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealBasketsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.DealBasketsQuery, Types.DealBasketsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealBasketsQuery, Types.DealBasketsQueryVariables>(
    DealBasketsDocument,
    options,
  );
}
export function useDealBasketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DealBasketsQuery,
    Types.DealBasketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealBasketsQuery, Types.DealBasketsQueryVariables>(
    DealBasketsDocument,
    options,
  );
}
export type DealBasketsQueryHookResult = ReturnType<typeof useDealBasketsQuery>;
export type DealBasketsLazyQueryHookResult = ReturnType<typeof useDealBasketsLazyQuery>;
export type DealBasketsQueryResult = Apollo.QueryResult<
  Types.DealBasketsQuery,
  Types.DealBasketsQueryVariables
>;
export function refetchDealBasketsQuery(variables?: Types.DealBasketsQueryVariables) {
  return { query: DealBasketsDocument, variables: variables };
}
export const DealProductsDocument = gql`
  query dealProducts {
    dealProducts {
      id
      key
      value
    }
  }
`;

/**
 * __useDealProductsQuery__
 *
 * To run a query within a React component, call `useDealProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.DealProductsQuery, Types.DealProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealProductsQuery, Types.DealProductsQueryVariables>(
    DealProductsDocument,
    options,
  );
}
export function useDealProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DealProductsQuery,
    Types.DealProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealProductsQuery, Types.DealProductsQueryVariables>(
    DealProductsDocument,
    options,
  );
}
export type DealProductsQueryHookResult = ReturnType<typeof useDealProductsQuery>;
export type DealProductsLazyQueryHookResult = ReturnType<typeof useDealProductsLazyQuery>;
export type DealProductsQueryResult = Apollo.QueryResult<
  Types.DealProductsQuery,
  Types.DealProductsQueryVariables
>;
export function refetchDealProductsQuery(variables?: Types.DealProductsQueryVariables) {
  return { query: DealProductsDocument, variables: variables };
}
export const DealStatusesDocument = gql`
  query dealStatuses {
    dealStatuses {
      id
      key
      value
      description
    }
  }
`;

/**
 * __useDealStatusesQuery__
 *
 * To run a query within a React component, call `useDealStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.DealStatusesQuery, Types.DealStatusesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealStatusesQuery, Types.DealStatusesQueryVariables>(
    DealStatusesDocument,
    options,
  );
}
export function useDealStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DealStatusesQuery,
    Types.DealStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealStatusesQuery, Types.DealStatusesQueryVariables>(
    DealStatusesDocument,
    options,
  );
}
export type DealStatusesQueryHookResult = ReturnType<typeof useDealStatusesQuery>;
export type DealStatusesLazyQueryHookResult = ReturnType<typeof useDealStatusesLazyQuery>;
export type DealStatusesQueryResult = Apollo.QueryResult<
  Types.DealStatusesQuery,
  Types.DealStatusesQueryVariables
>;
export function refetchDealStatusesQuery(variables?: Types.DealStatusesQueryVariables) {
  return { query: DealStatusesDocument, variables: variables };
}
export const DealTypesDocument = gql`
  query dealTypes {
    dealTypes {
      id
      key
      value
    }
  }
`;

/**
 * __useDealTypesQuery__
 *
 * To run a query within a React component, call `useDealTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.DealTypesQuery, Types.DealTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DealTypesQuery, Types.DealTypesQueryVariables>(
    DealTypesDocument,
    options,
  );
}
export function useDealTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.DealTypesQuery, Types.DealTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DealTypesQuery, Types.DealTypesQueryVariables>(
    DealTypesDocument,
    options,
  );
}
export type DealTypesQueryHookResult = ReturnType<typeof useDealTypesQuery>;
export type DealTypesLazyQueryHookResult = ReturnType<typeof useDealTypesLazyQuery>;
export type DealTypesQueryResult = Apollo.QueryResult<
  Types.DealTypesQuery,
  Types.DealTypesQueryVariables
>;
export function refetchDealTypesQuery(variables?: Types.DealTypesQueryVariables) {
  return { query: DealTypesDocument, variables: variables };
}
export const DomainAdsBudgetsDocument = gql`
  query domainAdsBudgets($domainId: ID!) {
    domainAdsBudgets(domainId: $domainId) {
      ...domainAdsBudget
    }
  }
  ${DomainAdsBudgetFragmentDoc}
`;

/**
 * __useDomainAdsBudgetsQuery__
 *
 * To run a query within a React component, call `useDomainAdsBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainAdsBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainAdsBudgetsQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useDomainAdsBudgetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DomainAdsBudgetsQuery,
    Types.DomainAdsBudgetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainAdsBudgetsQuery, Types.DomainAdsBudgetsQueryVariables>(
    DomainAdsBudgetsDocument,
    options,
  );
}
export function useDomainAdsBudgetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DomainAdsBudgetsQuery,
    Types.DomainAdsBudgetsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainAdsBudgetsQuery, Types.DomainAdsBudgetsQueryVariables>(
    DomainAdsBudgetsDocument,
    options,
  );
}
export type DomainAdsBudgetsQueryHookResult = ReturnType<typeof useDomainAdsBudgetsQuery>;
export type DomainAdsBudgetsLazyQueryHookResult = ReturnType<typeof useDomainAdsBudgetsLazyQuery>;
export type DomainAdsBudgetsQueryResult = Apollo.QueryResult<
  Types.DomainAdsBudgetsQuery,
  Types.DomainAdsBudgetsQueryVariables
>;
export function refetchDomainAdsBudgetsQuery(variables: Types.DomainAdsBudgetsQueryVariables) {
  return { query: DomainAdsBudgetsDocument, variables: variables };
}
export const DomainAdsDepositsDocument = gql`
  query domainAdsDeposits($domainId: ID!) {
    domainAdsDeposits(domainId: $domainId) {
      ...domainAdsDeposit
    }
  }
  ${DomainAdsDepositFragmentDoc}
`;

/**
 * __useDomainAdsDepositsQuery__
 *
 * To run a query within a React component, call `useDomainAdsDepositsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainAdsDepositsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainAdsDepositsQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useDomainAdsDepositsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DomainAdsDepositsQuery,
    Types.DomainAdsDepositsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainAdsDepositsQuery, Types.DomainAdsDepositsQueryVariables>(
    DomainAdsDepositsDocument,
    options,
  );
}
export function useDomainAdsDepositsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DomainAdsDepositsQuery,
    Types.DomainAdsDepositsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainAdsDepositsQuery, Types.DomainAdsDepositsQueryVariables>(
    DomainAdsDepositsDocument,
    options,
  );
}
export type DomainAdsDepositsQueryHookResult = ReturnType<typeof useDomainAdsDepositsQuery>;
export type DomainAdsDepositsLazyQueryHookResult = ReturnType<typeof useDomainAdsDepositsLazyQuery>;
export type DomainAdsDepositsQueryResult = Apollo.QueryResult<
  Types.DomainAdsDepositsQuery,
  Types.DomainAdsDepositsQueryVariables
>;
export function refetchDomainAdsDepositsQuery(variables: Types.DomainAdsDepositsQueryVariables) {
  return { query: DomainAdsDepositsDocument, variables: variables };
}
export const DomainCategoriesDocument = gql`
  query domainCategories {
    domainCategories {
      id
      key
      value
    }
  }
`;

/**
 * __useDomainCategoriesQuery__
 *
 * To run a query within a React component, call `useDomainCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDomainCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.DomainCategoriesQuery,
    Types.DomainCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainCategoriesQuery, Types.DomainCategoriesQueryVariables>(
    DomainCategoriesDocument,
    options,
  );
}
export function useDomainCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DomainCategoriesQuery,
    Types.DomainCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainCategoriesQuery, Types.DomainCategoriesQueryVariables>(
    DomainCategoriesDocument,
    options,
  );
}
export type DomainCategoriesQueryHookResult = ReturnType<typeof useDomainCategoriesQuery>;
export type DomainCategoriesLazyQueryHookResult = ReturnType<typeof useDomainCategoriesLazyQuery>;
export type DomainCategoriesQueryResult = Apollo.QueryResult<
  Types.DomainCategoriesQuery,
  Types.DomainCategoriesQueryVariables
>;
export function refetchDomainCategoriesQuery(variables?: Types.DomainCategoriesQueryVariables) {
  return { query: DomainCategoriesDocument, variables: variables };
}
export const DomainCmsDocument = gql`
  query domainCms {
    domainCms {
      id
      value
    }
  }
`;

/**
 * __useDomainCmsQuery__
 *
 * To run a query within a React component, call `useDomainCmsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainCmsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainCmsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDomainCmsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.DomainCmsQuery, Types.DomainCmsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainCmsQuery, Types.DomainCmsQueryVariables>(
    DomainCmsDocument,
    options,
  );
}
export function useDomainCmsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.DomainCmsQuery, Types.DomainCmsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainCmsQuery, Types.DomainCmsQueryVariables>(
    DomainCmsDocument,
    options,
  );
}
export type DomainCmsQueryHookResult = ReturnType<typeof useDomainCmsQuery>;
export type DomainCmsLazyQueryHookResult = ReturnType<typeof useDomainCmsLazyQuery>;
export type DomainCmsQueryResult = Apollo.QueryResult<
  Types.DomainCmsQuery,
  Types.DomainCmsQueryVariables
>;
export function refetchDomainCmsQuery(variables?: Types.DomainCmsQueryVariables) {
  return { query: DomainCmsDocument, variables: variables };
}
export const DomainDetailsDocument = gql`
  query domainDetails($id: ID!) {
    domain(id: $id) {
      ...domainDetails
    }
  }
  ${DomainDetailsFragmentDoc}
  ${DomainBasicFragmentDoc}
  ${DealFragmentDoc}
  ${LspTypeFragmentDoc}
  ${DiscountFragmentDoc}
  ${DealTerminationFragmentDoc}
`;

/**
 * __useDomainDetailsQuery__
 *
 * To run a query within a React component, call `useDomainDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDomainDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.DomainDetailsQuery, Types.DomainDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainDetailsQuery, Types.DomainDetailsQueryVariables>(
    DomainDetailsDocument,
    options,
  );
}
export function useDomainDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DomainDetailsQuery,
    Types.DomainDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainDetailsQuery, Types.DomainDetailsQueryVariables>(
    DomainDetailsDocument,
    options,
  );
}
export type DomainDetailsQueryHookResult = ReturnType<typeof useDomainDetailsQuery>;
export type DomainDetailsLazyQueryHookResult = ReturnType<typeof useDomainDetailsLazyQuery>;
export type DomainDetailsQueryResult = Apollo.QueryResult<
  Types.DomainDetailsQuery,
  Types.DomainDetailsQueryVariables
>;
export function refetchDomainDetailsQuery(variables: Types.DomainDetailsQueryVariables) {
  return { query: DomainDetailsDocument, variables: variables };
}
export const DomainListDocument = gql`
  query domainList($grid: DomainGridInput) {
    domains(grid: $grid) {
      total
      rows {
        ...domainInTheList
      }
    }
  }
  ${DomainInTheListFragmentDoc}
  ${DomainViewFragmentDoc}
  ${UserBasicFragmentDoc}
  ${DomainBasicFragmentDoc}
  ${DealInTheDomainListFragmentDoc}
`;

/**
 * __useDomainListQuery__
 *
 * To run a query within a React component, call `useDomainListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainListQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useDomainListQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.DomainListQuery, Types.DomainListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainListQuery, Types.DomainListQueryVariables>(
    DomainListDocument,
    options,
  );
}
export function useDomainListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.DomainListQuery, Types.DomainListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainListQuery, Types.DomainListQueryVariables>(
    DomainListDocument,
    options,
  );
}
export type DomainListQueryHookResult = ReturnType<typeof useDomainListQuery>;
export type DomainListLazyQueryHookResult = ReturnType<typeof useDomainListLazyQuery>;
export type DomainListQueryResult = Apollo.QueryResult<
  Types.DomainListQuery,
  Types.DomainListQueryVariables
>;
export function refetchDomainListQuery(variables?: Types.DomainListQueryVariables) {
  return { query: DomainListDocument, variables: variables };
}
export const DomainNamesDocument = gql`
  query domainNames($grid: DomainNamesGridInput) {
    domainNames(grid: $grid) {
      rows {
        id
        name
      }
    }
  }
`;

/**
 * __useDomainNamesQuery__
 *
 * To run a query within a React component, call `useDomainNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainNamesQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useDomainNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.DomainNamesQuery, Types.DomainNamesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainNamesQuery, Types.DomainNamesQueryVariables>(
    DomainNamesDocument,
    options,
  );
}
export function useDomainNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DomainNamesQuery,
    Types.DomainNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainNamesQuery, Types.DomainNamesQueryVariables>(
    DomainNamesDocument,
    options,
  );
}
export type DomainNamesQueryHookResult = ReturnType<typeof useDomainNamesQuery>;
export type DomainNamesLazyQueryHookResult = ReturnType<typeof useDomainNamesLazyQuery>;
export type DomainNamesQueryResult = Apollo.QueryResult<
  Types.DomainNamesQuery,
  Types.DomainNamesQueryVariables
>;
export function refetchDomainNamesQuery(variables?: Types.DomainNamesQueryVariables) {
  return { query: DomainNamesDocument, variables: variables };
}
export const DomainReportFieldsDocument = gql`
  query domainReportFields($reportType: String!) {
    domainReportFields(reportType: $reportType) {
      id
      key
    }
  }
`;

/**
 * __useDomainReportFieldsQuery__
 *
 * To run a query within a React component, call `useDomainReportFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainReportFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainReportFieldsQuery({
 *   variables: {
 *      reportType: // value for 'reportType'
 *   },
 * });
 */
export function useDomainReportFieldsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DomainReportFieldsQuery,
    Types.DomainReportFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainReportFieldsQuery, Types.DomainReportFieldsQueryVariables>(
    DomainReportFieldsDocument,
    options,
  );
}
export function useDomainReportFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DomainReportFieldsQuery,
    Types.DomainReportFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainReportFieldsQuery, Types.DomainReportFieldsQueryVariables>(
    DomainReportFieldsDocument,
    options,
  );
}
export type DomainReportFieldsQueryHookResult = ReturnType<typeof useDomainReportFieldsQuery>;
export type DomainReportFieldsLazyQueryHookResult = ReturnType<
  typeof useDomainReportFieldsLazyQuery
>;
export type DomainReportFieldsQueryResult = Apollo.QueryResult<
  Types.DomainReportFieldsQuery,
  Types.DomainReportFieldsQueryVariables
>;
export function refetchDomainReportFieldsQuery(variables: Types.DomainReportFieldsQueryVariables) {
  return { query: DomainReportFieldsDocument, variables: variables };
}
export const DomainReportPossibleValuesDocument = gql`
  query domainReportPossibleValues {
    domainReportPossibleValues {
      id
      key
      value
    }
  }
`;

/**
 * __useDomainReportPossibleValuesQuery__
 *
 * To run a query within a React component, call `useDomainReportPossibleValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainReportPossibleValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainReportPossibleValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDomainReportPossibleValuesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.DomainReportPossibleValuesQuery,
    Types.DomainReportPossibleValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.DomainReportPossibleValuesQuery,
    Types.DomainReportPossibleValuesQueryVariables
  >(DomainReportPossibleValuesDocument, options);
}
export function useDomainReportPossibleValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DomainReportPossibleValuesQuery,
    Types.DomainReportPossibleValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.DomainReportPossibleValuesQuery,
    Types.DomainReportPossibleValuesQueryVariables
  >(DomainReportPossibleValuesDocument, options);
}
export type DomainReportPossibleValuesQueryHookResult = ReturnType<
  typeof useDomainReportPossibleValuesQuery
>;
export type DomainReportPossibleValuesLazyQueryHookResult = ReturnType<
  typeof useDomainReportPossibleValuesLazyQuery
>;
export type DomainReportPossibleValuesQueryResult = Apollo.QueryResult<
  Types.DomainReportPossibleValuesQuery,
  Types.DomainReportPossibleValuesQueryVariables
>;
export function refetchDomainReportPossibleValuesQuery(
  variables?: Types.DomainReportPossibleValuesQueryVariables,
) {
  return { query: DomainReportPossibleValuesDocument, variables: variables };
}
export const DomainReportTypeDocument = gql`
  query domainReportType {
    domainReportType {
      id
      key
      value
    }
  }
`;

/**
 * __useDomainReportTypeQuery__
 *
 * To run a query within a React component, call `useDomainReportTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainReportTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainReportTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useDomainReportTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.DomainReportTypeQuery,
    Types.DomainReportTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainReportTypeQuery, Types.DomainReportTypeQueryVariables>(
    DomainReportTypeDocument,
    options,
  );
}
export function useDomainReportTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DomainReportTypeQuery,
    Types.DomainReportTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainReportTypeQuery, Types.DomainReportTypeQueryVariables>(
    DomainReportTypeDocument,
    options,
  );
}
export type DomainReportTypeQueryHookResult = ReturnType<typeof useDomainReportTypeQuery>;
export type DomainReportTypeLazyQueryHookResult = ReturnType<typeof useDomainReportTypeLazyQuery>;
export type DomainReportTypeQueryResult = Apollo.QueryResult<
  Types.DomainReportTypeQuery,
  Types.DomainReportTypeQueryVariables
>;
export function refetchDomainReportTypeQuery(variables?: Types.DomainReportTypeQueryVariables) {
  return { query: DomainReportTypeDocument, variables: variables };
}
export const DomainReportValuesDocument = gql`
  query domainReportValues($id: ID!) {
    domainReport(id: $id) {
      id
      language
      data {
        field {
          id
        }
        value
      }
    }
  }
`;

/**
 * __useDomainReportValuesQuery__
 *
 * To run a query within a React component, call `useDomainReportValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainReportValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainReportValuesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDomainReportValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.DomainReportValuesQuery,
    Types.DomainReportValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainReportValuesQuery, Types.DomainReportValuesQueryVariables>(
    DomainReportValuesDocument,
    options,
  );
}
export function useDomainReportValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DomainReportValuesQuery,
    Types.DomainReportValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainReportValuesQuery, Types.DomainReportValuesQueryVariables>(
    DomainReportValuesDocument,
    options,
  );
}
export type DomainReportValuesQueryHookResult = ReturnType<typeof useDomainReportValuesQuery>;
export type DomainReportValuesLazyQueryHookResult = ReturnType<
  typeof useDomainReportValuesLazyQuery
>;
export type DomainReportValuesQueryResult = Apollo.QueryResult<
  Types.DomainReportValuesQuery,
  Types.DomainReportValuesQueryVariables
>;
export function refetchDomainReportValuesQuery(variables: Types.DomainReportValuesQueryVariables) {
  return { query: DomainReportValuesDocument, variables: variables };
}
export const DomainServiceTypesDocument = gql`
  query domainServiceTypes {
    domainServiceTypes {
      key
      id
      value
    }
  }
`;

/**
 * __useDomainServiceTypesQuery__
 *
 * To run a query within a React component, call `useDomainServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainServiceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDomainServiceTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.DomainServiceTypesQuery,
    Types.DomainServiceTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainServiceTypesQuery, Types.DomainServiceTypesQueryVariables>(
    DomainServiceTypesDocument,
    options,
  );
}
export function useDomainServiceTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.DomainServiceTypesQuery,
    Types.DomainServiceTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainServiceTypesQuery, Types.DomainServiceTypesQueryVariables>(
    DomainServiceTypesDocument,
    options,
  );
}
export type DomainServiceTypesQueryHookResult = ReturnType<typeof useDomainServiceTypesQuery>;
export type DomainServiceTypesLazyQueryHookResult = ReturnType<
  typeof useDomainServiceTypesLazyQuery
>;
export type DomainServiceTypesQueryResult = Apollo.QueryResult<
  Types.DomainServiceTypesQuery,
  Types.DomainServiceTypesQueryVariables
>;
export function refetchDomainServiceTypesQuery(variables?: Types.DomainServiceTypesQueryVariables) {
  return { query: DomainServiceTypesDocument, variables: variables };
}
export const DomainViewDocument = gql`
  query domainView($id: ID!) {
    domain(id: $id) {
      ...domainView
    }
  }
  ${DomainViewFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useDomainViewQuery__
 *
 * To run a query within a React component, call `useDomainViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDomainViewQuery(
  baseOptions: Apollo.QueryHookOptions<Types.DomainViewQuery, Types.DomainViewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainViewQuery, Types.DomainViewQueryVariables>(
    DomainViewDocument,
    options,
  );
}
export function useDomainViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.DomainViewQuery, Types.DomainViewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainViewQuery, Types.DomainViewQueryVariables>(
    DomainViewDocument,
    options,
  );
}
export type DomainViewQueryHookResult = ReturnType<typeof useDomainViewQuery>;
export type DomainViewLazyQueryHookResult = ReturnType<typeof useDomainViewLazyQuery>;
export type DomainViewQueryResult = Apollo.QueryResult<
  Types.DomainViewQuery,
  Types.DomainViewQueryVariables
>;
export function refetchDomainViewQuery(variables: Types.DomainViewQueryVariables) {
  return { query: DomainViewDocument, variables: variables };
}
export const DomainsDocument = gql`
  query domains($grid: DomainGridInput) {
    domains(grid: $grid) {
      rows {
        ...domainBasic
      }
    }
  }
  ${DomainBasicFragmentDoc}
`;

/**
 * __useDomainsQuery__
 *
 * To run a query within a React component, call `useDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainsQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useDomainsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.DomainsQuery, Types.DomainsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.DomainsQuery, Types.DomainsQueryVariables>(DomainsDocument, options);
}
export function useDomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.DomainsQuery, Types.DomainsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.DomainsQuery, Types.DomainsQueryVariables>(
    DomainsDocument,
    options,
  );
}
export type DomainsQueryHookResult = ReturnType<typeof useDomainsQuery>;
export type DomainsLazyQueryHookResult = ReturnType<typeof useDomainsLazyQuery>;
export type DomainsQueryResult = Apollo.QueryResult<
  Types.DomainsQuery,
  Types.DomainsQueryVariables
>;
export function refetchDomainsQuery(variables?: Types.DomainsQueryVariables) {
  return { query: DomainsDocument, variables: variables };
}
export const EventTypesDocument = gql`
  query eventTypes {
    eventTypes
  }
`;

/**
 * __useEventTypesQuery__
 *
 * To run a query within a React component, call `useEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.EventTypesQuery, Types.EventTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.EventTypesQuery, Types.EventTypesQueryVariables>(
    EventTypesDocument,
    options,
  );
}
export function useEventTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.EventTypesQuery, Types.EventTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.EventTypesQuery, Types.EventTypesQueryVariables>(
    EventTypesDocument,
    options,
  );
}
export type EventTypesQueryHookResult = ReturnType<typeof useEventTypesQuery>;
export type EventTypesLazyQueryHookResult = ReturnType<typeof useEventTypesLazyQuery>;
export type EventTypesQueryResult = Apollo.QueryResult<
  Types.EventTypesQuery,
  Types.EventTypesQueryVariables
>;
export function refetchEventTypesQuery(variables?: Types.EventTypesQueryVariables) {
  return { query: EventTypesDocument, variables: variables };
}
export const ExternalReportSettingsDocument = gql`
  query externalReportSettings($domainId: ID!) {
    externalReportSettings(domainId: $domainId) {
      ...externalReportSettings
    }
  }
  ${ExternalReportSettingsFragmentDoc}
`;

/**
 * __useExternalReportSettingsQuery__
 *
 * To run a query within a React component, call `useExternalReportSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalReportSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalReportSettingsQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useExternalReportSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.ExternalReportSettingsQuery,
    Types.ExternalReportSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ExternalReportSettingsQuery,
    Types.ExternalReportSettingsQueryVariables
  >(ExternalReportSettingsDocument, options);
}
export function useExternalReportSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ExternalReportSettingsQuery,
    Types.ExternalReportSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ExternalReportSettingsQuery,
    Types.ExternalReportSettingsQueryVariables
  >(ExternalReportSettingsDocument, options);
}
export type ExternalReportSettingsQueryHookResult = ReturnType<
  typeof useExternalReportSettingsQuery
>;
export type ExternalReportSettingsLazyQueryHookResult = ReturnType<
  typeof useExternalReportSettingsLazyQuery
>;
export type ExternalReportSettingsQueryResult = Apollo.QueryResult<
  Types.ExternalReportSettingsQuery,
  Types.ExternalReportSettingsQueryVariables
>;
export function refetchExternalReportSettingsQuery(
  variables: Types.ExternalReportSettingsQueryVariables,
) {
  return { query: ExternalReportSettingsDocument, variables: variables };
}
export const ExternalReportsDocument = gql`
  query externalReports($domainId: ID) {
    externalReports(domainId: $domainId) {
      id
      emailSent
      language
      link
      reportDate
      note
      type
    }
  }
`;

/**
 * __useExternalReportsQuery__
 *
 * To run a query within a React component, call `useExternalReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalReportsQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useExternalReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ExternalReportsQuery,
    Types.ExternalReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ExternalReportsQuery, Types.ExternalReportsQueryVariables>(
    ExternalReportsDocument,
    options,
  );
}
export function useExternalReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ExternalReportsQuery,
    Types.ExternalReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ExternalReportsQuery, Types.ExternalReportsQueryVariables>(
    ExternalReportsDocument,
    options,
  );
}
export type ExternalReportsQueryHookResult = ReturnType<typeof useExternalReportsQuery>;
export type ExternalReportsLazyQueryHookResult = ReturnType<typeof useExternalReportsLazyQuery>;
export type ExternalReportsQueryResult = Apollo.QueryResult<
  Types.ExternalReportsQuery,
  Types.ExternalReportsQueryVariables
>;
export function refetchExternalReportsQuery(variables?: Types.ExternalReportsQueryVariables) {
  return { query: ExternalReportsDocument, variables: variables };
}
export const FullDomainViewDocument = gql`
  query fullDomainView($id: ID!) {
    domain(id: $id) {
      ...fullDomainView
    }
  }
  ${FullDomainViewFragmentDoc}
  ${DomainContactsFragmentDoc}
  ${UserBasicFragmentDoc}
  ${GamgaServiceFragmentDoc}
  ${GamSearchConsoleSiteFragmentDoc}
`;

/**
 * __useFullDomainViewQuery__
 *
 * To run a query within a React component, call `useFullDomainViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFullDomainViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFullDomainViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFullDomainViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.FullDomainViewQuery,
    Types.FullDomainViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.FullDomainViewQuery, Types.FullDomainViewQueryVariables>(
    FullDomainViewDocument,
    options,
  );
}
export function useFullDomainViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FullDomainViewQuery,
    Types.FullDomainViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.FullDomainViewQuery, Types.FullDomainViewQueryVariables>(
    FullDomainViewDocument,
    options,
  );
}
export type FullDomainViewQueryHookResult = ReturnType<typeof useFullDomainViewQuery>;
export type FullDomainViewLazyQueryHookResult = ReturnType<typeof useFullDomainViewLazyQuery>;
export type FullDomainViewQueryResult = Apollo.QueryResult<
  Types.FullDomainViewQuery,
  Types.FullDomainViewQueryVariables
>;
export function refetchFullDomainViewQuery(variables: Types.FullDomainViewQueryVariables) {
  return { query: FullDomainViewDocument, variables: variables };
}
export const GetTemporaryGaAccessTimeRemainingDocument = gql`
  query getTemporaryGAAccessTimeRemaining($domainId: String!) {
    temporaryGAAccessTimeRemaining(domainId: $domainId)
  }
`;

/**
 * __useGetTemporaryGaAccessTimeRemainingQuery__
 *
 * To run a query within a React component, call `useGetTemporaryGaAccessTimeRemainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemporaryGaAccessTimeRemainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemporaryGaAccessTimeRemainingQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function useGetTemporaryGaAccessTimeRemainingQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetTemporaryGaAccessTimeRemainingQuery,
    Types.GetTemporaryGaAccessTimeRemainingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetTemporaryGaAccessTimeRemainingQuery,
    Types.GetTemporaryGaAccessTimeRemainingQueryVariables
  >(GetTemporaryGaAccessTimeRemainingDocument, options);
}
export function useGetTemporaryGaAccessTimeRemainingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetTemporaryGaAccessTimeRemainingQuery,
    Types.GetTemporaryGaAccessTimeRemainingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetTemporaryGaAccessTimeRemainingQuery,
    Types.GetTemporaryGaAccessTimeRemainingQueryVariables
  >(GetTemporaryGaAccessTimeRemainingDocument, options);
}
export type GetTemporaryGaAccessTimeRemainingQueryHookResult = ReturnType<
  typeof useGetTemporaryGaAccessTimeRemainingQuery
>;
export type GetTemporaryGaAccessTimeRemainingLazyQueryHookResult = ReturnType<
  typeof useGetTemporaryGaAccessTimeRemainingLazyQuery
>;
export type GetTemporaryGaAccessTimeRemainingQueryResult = Apollo.QueryResult<
  Types.GetTemporaryGaAccessTimeRemainingQuery,
  Types.GetTemporaryGaAccessTimeRemainingQueryVariables
>;
export function refetchGetTemporaryGaAccessTimeRemainingQuery(
  variables: Types.GetTemporaryGaAccessTimeRemainingQueryVariables,
) {
  return { query: GetTemporaryGaAccessTimeRemainingDocument, variables: variables };
}
export const GamInternalAccountsDocument = gql`
  query gamInternalAccounts {
    gamInternalAccounts {
      rows {
        ...gamInternalAccount
      }
      total
    }
  }
  ${GamInternalAccountFragmentDoc}
`;

/**
 * __useGamInternalAccountsQuery__
 *
 * To run a query within a React component, call `useGamInternalAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamInternalAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamInternalAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGamInternalAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GamInternalAccountsQuery,
    Types.GamInternalAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GamInternalAccountsQuery, Types.GamInternalAccountsQueryVariables>(
    GamInternalAccountsDocument,
    options,
  );
}
export function useGamInternalAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GamInternalAccountsQuery,
    Types.GamInternalAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GamInternalAccountsQuery,
    Types.GamInternalAccountsQueryVariables
  >(GamInternalAccountsDocument, options);
}
export type GamInternalAccountsQueryHookResult = ReturnType<typeof useGamInternalAccountsQuery>;
export type GamInternalAccountsLazyQueryHookResult = ReturnType<
  typeof useGamInternalAccountsLazyQuery
>;
export type GamInternalAccountsQueryResult = Apollo.QueryResult<
  Types.GamInternalAccountsQuery,
  Types.GamInternalAccountsQueryVariables
>;
export function refetchGamInternalAccountsQuery(
  variables?: Types.GamInternalAccountsQueryVariables,
) {
  return { query: GamInternalAccountsDocument, variables: variables };
}
export const GlobalSearchDocument = gql`
  query globalSearch(
    $domainsGrid: DomainGridInput
    $usersGrid: UserGridInput
    $ticketsGrid: TicketGridInput
    $domainContactsGrid: DomainContactsGridInput
  ) {
    domains(grid: $domainsGrid) {
      rows {
        id
        name
      }
    }
    users(grid: $usersGrid) {
      rows {
        id
        first_name
        last_name
      }
    }
    tickets(grid: $ticketsGrid) {
      rows {
        id
        title
      }
    }
    domainsContacts(grid: $domainContactsGrid) {
      rows {
        id
        domain {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGlobalSearchQuery__
 *
 * To run a query within a React component, call `useGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSearchQuery({
 *   variables: {
 *      domainsGrid: // value for 'domainsGrid'
 *      usersGrid: // value for 'usersGrid'
 *      ticketsGrid: // value for 'ticketsGrid'
 *      domainContactsGrid: // value for 'domainContactsGrid'
 *   },
 * });
 */
export function useGlobalSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GlobalSearchQuery, Types.GlobalSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GlobalSearchQuery, Types.GlobalSearchQueryVariables>(
    GlobalSearchDocument,
    options,
  );
}
export function useGlobalSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GlobalSearchQuery,
    Types.GlobalSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GlobalSearchQuery, Types.GlobalSearchQueryVariables>(
    GlobalSearchDocument,
    options,
  );
}
export type GlobalSearchQueryHookResult = ReturnType<typeof useGlobalSearchQuery>;
export type GlobalSearchLazyQueryHookResult = ReturnType<typeof useGlobalSearchLazyQuery>;
export type GlobalSearchQueryResult = Apollo.QueryResult<
  Types.GlobalSearchQuery,
  Types.GlobalSearchQueryVariables
>;
export function refetchGlobalSearchQuery(variables?: Types.GlobalSearchQueryVariables) {
  return { query: GlobalSearchDocument, variables: variables };
}
export const GroupedPermissionsDocument = gql`
  query groupedPermissions {
    groupedPermissions {
      id
      value
      options {
        id
        key
        value
      }
    }
  }
`;

/**
 * __useGroupedPermissionsQuery__
 *
 * To run a query within a React component, call `useGroupedPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupedPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupedPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupedPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GroupedPermissionsQuery,
    Types.GroupedPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GroupedPermissionsQuery, Types.GroupedPermissionsQueryVariables>(
    GroupedPermissionsDocument,
    options,
  );
}
export function useGroupedPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GroupedPermissionsQuery,
    Types.GroupedPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GroupedPermissionsQuery, Types.GroupedPermissionsQueryVariables>(
    GroupedPermissionsDocument,
    options,
  );
}
export type GroupedPermissionsQueryHookResult = ReturnType<typeof useGroupedPermissionsQuery>;
export type GroupedPermissionsLazyQueryHookResult = ReturnType<
  typeof useGroupedPermissionsLazyQuery
>;
export type GroupedPermissionsQueryResult = Apollo.QueryResult<
  Types.GroupedPermissionsQuery,
  Types.GroupedPermissionsQueryVariables
>;
export function refetchGroupedPermissionsQuery(variables?: Types.GroupedPermissionsQueryVariables) {
  return { query: GroupedPermissionsDocument, variables: variables };
}
export const GroupedPhrasesDocument = gql`
  query groupedPhrases($grid: GroupedPhraseGridInput) {
    groupedPhrases(grid: $grid) {
      ...groupedPhrases
    }
  }
  ${GroupedPhrasesFragmentDoc}
  ${GroupedPhraseFragmentDoc}
  ${PhrasePositionOrderedFragmentDoc}
`;

/**
 * __useGroupedPhrasesQuery__
 *
 * To run a query within a React component, call `useGroupedPhrasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupedPhrasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupedPhrasesQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useGroupedPhrasesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GroupedPhrasesQuery,
    Types.GroupedPhrasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GroupedPhrasesQuery, Types.GroupedPhrasesQueryVariables>(
    GroupedPhrasesDocument,
    options,
  );
}
export function useGroupedPhrasesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GroupedPhrasesQuery,
    Types.GroupedPhrasesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GroupedPhrasesQuery, Types.GroupedPhrasesQueryVariables>(
    GroupedPhrasesDocument,
    options,
  );
}
export type GroupedPhrasesQueryHookResult = ReturnType<typeof useGroupedPhrasesQuery>;
export type GroupedPhrasesLazyQueryHookResult = ReturnType<typeof useGroupedPhrasesLazyQuery>;
export type GroupedPhrasesQueryResult = Apollo.QueryResult<
  Types.GroupedPhrasesQuery,
  Types.GroupedPhrasesQueryVariables
>;
export function refetchGroupedPhrasesQuery(variables?: Types.GroupedPhrasesQueryVariables) {
  return { query: GroupedPhrasesDocument, variables: variables };
}
export const GroupedPhrasesMonthsViewDocument = gql`
  query groupedPhrasesMonthsView($grid: GroupedPhraseGridInput) {
    groupedPhrases(grid: $grid) {
      ...groupedPhrasesMonthsView
    }
  }
  ${GroupedPhrasesMonthsViewFragmentDoc}
  ${GroupedPhraseMonthsViewFragmentDoc}
`;

/**
 * __useGroupedPhrasesMonthsViewQuery__
 *
 * To run a query within a React component, call `useGroupedPhrasesMonthsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupedPhrasesMonthsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupedPhrasesMonthsViewQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useGroupedPhrasesMonthsViewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.GroupedPhrasesMonthsViewQuery,
    Types.GroupedPhrasesMonthsViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GroupedPhrasesMonthsViewQuery,
    Types.GroupedPhrasesMonthsViewQueryVariables
  >(GroupedPhrasesMonthsViewDocument, options);
}
export function useGroupedPhrasesMonthsViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GroupedPhrasesMonthsViewQuery,
    Types.GroupedPhrasesMonthsViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GroupedPhrasesMonthsViewQuery,
    Types.GroupedPhrasesMonthsViewQueryVariables
  >(GroupedPhrasesMonthsViewDocument, options);
}
export type GroupedPhrasesMonthsViewQueryHookResult = ReturnType<
  typeof useGroupedPhrasesMonthsViewQuery
>;
export type GroupedPhrasesMonthsViewLazyQueryHookResult = ReturnType<
  typeof useGroupedPhrasesMonthsViewLazyQuery
>;
export type GroupedPhrasesMonthsViewQueryResult = Apollo.QueryResult<
  Types.GroupedPhrasesMonthsViewQuery,
  Types.GroupedPhrasesMonthsViewQueryVariables
>;
export function refetchGroupedPhrasesMonthsViewQuery(
  variables?: Types.GroupedPhrasesMonthsViewQueryVariables,
) {
  return { query: GroupedPhrasesMonthsViewDocument, variables: variables };
}
export const LanguageCodesDocument = gql`
  query languageCodes {
    languageCodes {
      id
      key
      value
    }
  }
`;

/**
 * __useLanguageCodesQuery__
 *
 * To run a query within a React component, call `useLanguageCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguageCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguageCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguageCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.LanguageCodesQuery,
    Types.LanguageCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LanguageCodesQuery, Types.LanguageCodesQueryVariables>(
    LanguageCodesDocument,
    options,
  );
}
export function useLanguageCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.LanguageCodesQuery,
    Types.LanguageCodesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LanguageCodesQuery, Types.LanguageCodesQueryVariables>(
    LanguageCodesDocument,
    options,
  );
}
export type LanguageCodesQueryHookResult = ReturnType<typeof useLanguageCodesQuery>;
export type LanguageCodesLazyQueryHookResult = ReturnType<typeof useLanguageCodesLazyQuery>;
export type LanguageCodesQueryResult = Apollo.QueryResult<
  Types.LanguageCodesQuery,
  Types.LanguageCodesQueryVariables
>;
export function refetchLanguageCodesQuery(variables?: Types.LanguageCodesQueryVariables) {
  return { query: LanguageCodesDocument, variables: variables };
}
export const LanguagesExternalReportDocument = gql`
  query languagesExternalReport {
    languagesExternalReport {
      id
      key
      value
    }
  }
`;

/**
 * __useLanguagesExternalReportQuery__
 *
 * To run a query within a React component, call `useLanguagesExternalReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useLanguagesExternalReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLanguagesExternalReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useLanguagesExternalReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.LanguagesExternalReportQuery,
    Types.LanguagesExternalReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.LanguagesExternalReportQuery,
    Types.LanguagesExternalReportQueryVariables
  >(LanguagesExternalReportDocument, options);
}
export function useLanguagesExternalReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.LanguagesExternalReportQuery,
    Types.LanguagesExternalReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.LanguagesExternalReportQuery,
    Types.LanguagesExternalReportQueryVariables
  >(LanguagesExternalReportDocument, options);
}
export type LanguagesExternalReportQueryHookResult = ReturnType<
  typeof useLanguagesExternalReportQuery
>;
export type LanguagesExternalReportLazyQueryHookResult = ReturnType<
  typeof useLanguagesExternalReportLazyQuery
>;
export type LanguagesExternalReportQueryResult = Apollo.QueryResult<
  Types.LanguagesExternalReportQuery,
  Types.LanguagesExternalReportQueryVariables
>;
export function refetchLanguagesExternalReportQuery(
  variables?: Types.LanguagesExternalReportQueryVariables,
) {
  return { query: LanguagesExternalReportDocument, variables: variables };
}
export const LinkDocument = gql`
  query link($id: ID!) {
    link(id: $id) {
      ...link
    }
  }
  ${LinkFragmentDoc}
  ${LinkInTheListFragmentDoc}
  ${LinkBasicFragmentDoc}
  ${LinkAnchorBasicFragmentDoc}
  ${DomainBasicFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useLinkQuery__
 *
 * To run a query within a React component, call `useLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLinkQuery(
  baseOptions: Apollo.QueryHookOptions<Types.LinkQuery, Types.LinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LinkQuery, Types.LinkQueryVariables>(LinkDocument, options);
}
export function useLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.LinkQuery, Types.LinkQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LinkQuery, Types.LinkQueryVariables>(LinkDocument, options);
}
export type LinkQueryHookResult = ReturnType<typeof useLinkQuery>;
export type LinkLazyQueryHookResult = ReturnType<typeof useLinkLazyQuery>;
export type LinkQueryResult = Apollo.QueryResult<Types.LinkQuery, Types.LinkQueryVariables>;
export function refetchLinkQuery(variables: Types.LinkQueryVariables) {
  return { query: LinkDocument, variables: variables };
}
export const LinkGroupsDocument = gql`
  query linkGroups {
    linkGroups {
      key
      id
      value
    }
  }
`;

/**
 * __useLinkGroupsQuery__
 *
 * To run a query within a React component, call `useLinkGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinkGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.LinkGroupsQuery, Types.LinkGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LinkGroupsQuery, Types.LinkGroupsQueryVariables>(
    LinkGroupsDocument,
    options,
  );
}
export function useLinkGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.LinkGroupsQuery, Types.LinkGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LinkGroupsQuery, Types.LinkGroupsQueryVariables>(
    LinkGroupsDocument,
    options,
  );
}
export type LinkGroupsQueryHookResult = ReturnType<typeof useLinkGroupsQuery>;
export type LinkGroupsLazyQueryHookResult = ReturnType<typeof useLinkGroupsLazyQuery>;
export type LinkGroupsQueryResult = Apollo.QueryResult<
  Types.LinkGroupsQuery,
  Types.LinkGroupsQueryVariables
>;
export function refetchLinkGroupsQuery(variables?: Types.LinkGroupsQueryVariables) {
  return { query: LinkGroupsDocument, variables: variables };
}
export const LinkListDocument = gql`
  query linkList($grid: LinkGridInput) {
    links(grid: $grid) {
      total
      rows {
        ...linkInTheList
      }
    }
  }
  ${LinkInTheListFragmentDoc}
  ${LinkBasicFragmentDoc}
  ${LinkAnchorBasicFragmentDoc}
  ${DomainBasicFragmentDoc}
`;

/**
 * __useLinkListQuery__
 *
 * To run a query within a React component, call `useLinkListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkListQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useLinkListQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.LinkListQuery, Types.LinkListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LinkListQuery, Types.LinkListQueryVariables>(
    LinkListDocument,
    options,
  );
}
export function useLinkListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.LinkListQuery, Types.LinkListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LinkListQuery, Types.LinkListQueryVariables>(
    LinkListDocument,
    options,
  );
}
export type LinkListQueryHookResult = ReturnType<typeof useLinkListQuery>;
export type LinkListLazyQueryHookResult = ReturnType<typeof useLinkListLazyQuery>;
export type LinkListQueryResult = Apollo.QueryResult<
  Types.LinkListQuery,
  Types.LinkListQueryVariables
>;
export function refetchLinkListQuery(variables?: Types.LinkListQueryVariables) {
  return { query: LinkListDocument, variables: variables };
}
export const LinkStatusesDocument = gql`
  query linkStatuses {
    linkStatuses {
      key
      id
      value
    }
  }
`;

/**
 * __useLinkStatusesQuery__
 *
 * To run a query within a React component, call `useLinkStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinkStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.LinkStatusesQuery, Types.LinkStatusesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LinkStatusesQuery, Types.LinkStatusesQueryVariables>(
    LinkStatusesDocument,
    options,
  );
}
export function useLinkStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.LinkStatusesQuery,
    Types.LinkStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LinkStatusesQuery, Types.LinkStatusesQueryVariables>(
    LinkStatusesDocument,
    options,
  );
}
export type LinkStatusesQueryHookResult = ReturnType<typeof useLinkStatusesQuery>;
export type LinkStatusesLazyQueryHookResult = ReturnType<typeof useLinkStatusesLazyQuery>;
export type LinkStatusesQueryResult = Apollo.QueryResult<
  Types.LinkStatusesQuery,
  Types.LinkStatusesQueryVariables
>;
export function refetchLinkStatusesQuery(variables?: Types.LinkStatusesQueryVariables) {
  return { query: LinkStatusesDocument, variables: variables };
}
export const LinkbuildingStrategiesDocument = gql`
  query linkbuildingStrategies {
    linkbuildingStrategies {
      id
      key
      value
    }
  }
`;

/**
 * __useLinkbuildingStrategiesQuery__
 *
 * To run a query within a React component, call `useLinkbuildingStrategiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkbuildingStrategiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkbuildingStrategiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinkbuildingStrategiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.LinkbuildingStrategiesQuery,
    Types.LinkbuildingStrategiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.LinkbuildingStrategiesQuery,
    Types.LinkbuildingStrategiesQueryVariables
  >(LinkbuildingStrategiesDocument, options);
}
export function useLinkbuildingStrategiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.LinkbuildingStrategiesQuery,
    Types.LinkbuildingStrategiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.LinkbuildingStrategiesQuery,
    Types.LinkbuildingStrategiesQueryVariables
  >(LinkbuildingStrategiesDocument, options);
}
export type LinkbuildingStrategiesQueryHookResult = ReturnType<
  typeof useLinkbuildingStrategiesQuery
>;
export type LinkbuildingStrategiesLazyQueryHookResult = ReturnType<
  typeof useLinkbuildingStrategiesLazyQuery
>;
export type LinkbuildingStrategiesQueryResult = Apollo.QueryResult<
  Types.LinkbuildingStrategiesQuery,
  Types.LinkbuildingStrategiesQueryVariables
>;
export function refetchLinkbuildingStrategiesQuery(
  variables?: Types.LinkbuildingStrategiesQueryVariables,
) {
  return { query: LinkbuildingStrategiesDocument, variables: variables };
}
export const LinksDocument = gql`
  query links($grid: LinkGridInput) {
    links(grid: $grid) {
      rows {
        ...linkBasic
      }
    }
  }
  ${LinkBasicFragmentDoc}
`;

/**
 * __useLinksQuery__
 *
 * To run a query within a React component, call `useLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.LinksQuery, Types.LinksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LinksQuery, Types.LinksQueryVariables>(LinksDocument, options);
}
export function useLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.LinksQuery, Types.LinksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LinksQuery, Types.LinksQueryVariables>(LinksDocument, options);
}
export type LinksQueryHookResult = ReturnType<typeof useLinksQuery>;
export type LinksLazyQueryHookResult = ReturnType<typeof useLinksLazyQuery>;
export type LinksQueryResult = Apollo.QueryResult<Types.LinksQuery, Types.LinksQueryVariables>;
export function refetchLinksQuery(variables?: Types.LinksQueryVariables) {
  return { query: LinksDocument, variables: variables };
}
export const LocalesDocument = gql`
  query locales {
    locales {
      id
      key
      value
    }
  }
`;

/**
 * __useLocalesQuery__
 *
 * To run a query within a React component, call `useLocalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocalesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.LocalesQuery, Types.LocalesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LocalesQuery, Types.LocalesQueryVariables>(LocalesDocument, options);
}
export function useLocalesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.LocalesQuery, Types.LocalesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LocalesQuery, Types.LocalesQueryVariables>(
    LocalesDocument,
    options,
  );
}
export type LocalesQueryHookResult = ReturnType<typeof useLocalesQuery>;
export type LocalesLazyQueryHookResult = ReturnType<typeof useLocalesLazyQuery>;
export type LocalesQueryResult = Apollo.QueryResult<
  Types.LocalesQuery,
  Types.LocalesQueryVariables
>;
export function refetchLocalesQuery(variables?: Types.LocalesQueryVariables) {
  return { query: LocalesDocument, variables: variables };
}
export const LocationsDocument = gql`
  query locations {
    locations {
      id
      key
    }
  }
`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.LocationsQuery, Types.LocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LocationsQuery, Types.LocationsQueryVariables>(
    LocationsDocument,
    options,
  );
}
export function useLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.LocationsQuery, Types.LocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LocationsQuery, Types.LocationsQueryVariables>(
    LocationsDocument,
    options,
  );
}
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<
  Types.LocationsQuery,
  Types.LocationsQueryVariables
>;
export function refetchLocationsQuery(variables?: Types.LocationsQueryVariables) {
  return { query: LocationsDocument, variables: variables };
}
export const LspTypesDocument = gql`
  query lspTypes {
    lspTypes {
      id
      value
    }
  }
`;

/**
 * __useLspTypesQuery__
 *
 * To run a query within a React component, call `useLspTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLspTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLspTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLspTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.LspTypesQuery, Types.LspTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LspTypesQuery, Types.LspTypesQueryVariables>(
    LspTypesDocument,
    options,
  );
}
export function useLspTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.LspTypesQuery, Types.LspTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LspTypesQuery, Types.LspTypesQueryVariables>(
    LspTypesDocument,
    options,
  );
}
export type LspTypesQueryHookResult = ReturnType<typeof useLspTypesQuery>;
export type LspTypesLazyQueryHookResult = ReturnType<typeof useLspTypesLazyQuery>;
export type LspTypesQueryResult = Apollo.QueryResult<
  Types.LspTypesQuery,
  Types.LspTypesQueryVariables
>;
export function refetchLspTypesQuery(variables?: Types.LspTypesQueryVariables) {
  return { query: LspTypesDocument, variables: variables };
}
export const MonitorDocument = gql`
  query monitor($id: String!) {
    monitor(id: $id) {
      ...monitor
    }
  }
  ${MonitorFragmentDoc}
`;

/**
 * __useMonitorQuery__
 *
 * To run a query within a React component, call `useMonitorQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMonitorQuery(
  baseOptions: Apollo.QueryHookOptions<Types.MonitorQuery, Types.MonitorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.MonitorQuery, Types.MonitorQueryVariables>(MonitorDocument, options);
}
export function useMonitorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.MonitorQuery, Types.MonitorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.MonitorQuery, Types.MonitorQueryVariables>(
    MonitorDocument,
    options,
  );
}
export type MonitorQueryHookResult = ReturnType<typeof useMonitorQuery>;
export type MonitorLazyQueryHookResult = ReturnType<typeof useMonitorLazyQuery>;
export type MonitorQueryResult = Apollo.QueryResult<
  Types.MonitorQuery,
  Types.MonitorQueryVariables
>;
export function refetchMonitorQuery(variables: Types.MonitorQueryVariables) {
  return { query: MonitorDocument, variables: variables };
}
export const MonitorLimitsDocument = gql`
  query monitorLimits {
    monitorLimits {
      monitors
      monitorsLimit
      upMonitors
      downMonitors
    }
  }
`;

/**
 * __useMonitorLimitsQuery__
 *
 * To run a query within a React component, call `useMonitorLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitorLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitorLimitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMonitorLimitsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.MonitorLimitsQuery,
    Types.MonitorLimitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.MonitorLimitsQuery, Types.MonitorLimitsQueryVariables>(
    MonitorLimitsDocument,
    options,
  );
}
export function useMonitorLimitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.MonitorLimitsQuery,
    Types.MonitorLimitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.MonitorLimitsQuery, Types.MonitorLimitsQueryVariables>(
    MonitorLimitsDocument,
    options,
  );
}
export type MonitorLimitsQueryHookResult = ReturnType<typeof useMonitorLimitsQuery>;
export type MonitorLimitsLazyQueryHookResult = ReturnType<typeof useMonitorLimitsLazyQuery>;
export type MonitorLimitsQueryResult = Apollo.QueryResult<
  Types.MonitorLimitsQuery,
  Types.MonitorLimitsQueryVariables
>;
export function refetchMonitorLimitsQuery(variables?: Types.MonitorLimitsQueryVariables) {
  return { query: MonitorLimitsDocument, variables: variables };
}
export const MonitorsDocument = gql`
  query monitors($grid: MonitorGridInput) {
    monitors(grid: $grid) {
      total
      rows {
        ...monitor
      }
    }
  }
  ${MonitorFragmentDoc}
`;

/**
 * __useMonitorsQuery__
 *
 * To run a query within a React component, call `useMonitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitorsQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useMonitorsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.MonitorsQuery, Types.MonitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.MonitorsQuery, Types.MonitorsQueryVariables>(
    MonitorsDocument,
    options,
  );
}
export function useMonitorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.MonitorsQuery, Types.MonitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.MonitorsQuery, Types.MonitorsQueryVariables>(
    MonitorsDocument,
    options,
  );
}
export type MonitorsQueryHookResult = ReturnType<typeof useMonitorsQuery>;
export type MonitorsLazyQueryHookResult = ReturnType<typeof useMonitorsLazyQuery>;
export type MonitorsQueryResult = Apollo.QueryResult<
  Types.MonitorsQuery,
  Types.MonitorsQueryVariables
>;
export function refetchMonitorsQuery(variables?: Types.MonitorsQueryVariables) {
  return { query: MonitorsDocument, variables: variables };
}
export const NotificationGroupsDocument = gql`
  query notificationGroups {
    notificationGroups {
      id
      key
      value
    }
  }
`;

/**
 * __useNotificationGroupsQuery__
 *
 * To run a query within a React component, call `useNotificationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.NotificationGroupsQuery,
    Types.NotificationGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.NotificationGroupsQuery, Types.NotificationGroupsQueryVariables>(
    NotificationGroupsDocument,
    options,
  );
}
export function useNotificationGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.NotificationGroupsQuery,
    Types.NotificationGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.NotificationGroupsQuery, Types.NotificationGroupsQueryVariables>(
    NotificationGroupsDocument,
    options,
  );
}
export type NotificationGroupsQueryHookResult = ReturnType<typeof useNotificationGroupsQuery>;
export type NotificationGroupsLazyQueryHookResult = ReturnType<
  typeof useNotificationGroupsLazyQuery
>;
export type NotificationGroupsQueryResult = Apollo.QueryResult<
  Types.NotificationGroupsQuery,
  Types.NotificationGroupsQueryVariables
>;
export function refetchNotificationGroupsQuery(variables?: Types.NotificationGroupsQueryVariables) {
  return { query: NotificationGroupsDocument, variables: variables };
}
export const NotificationSettingsDocument = gql`
  query notificationSettings {
    notificationSettings {
      group
      groupName
      settings {
        id
        key
        name
        channel
        value
        group
      }
    }
  }
`;

/**
 * __useNotificationSettingsQuery__
 *
 * To run a query within a React component, call `useNotificationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.NotificationSettingsQuery,
    Types.NotificationSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.NotificationSettingsQuery, Types.NotificationSettingsQueryVariables>(
    NotificationSettingsDocument,
    options,
  );
}
export function useNotificationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.NotificationSettingsQuery,
    Types.NotificationSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.NotificationSettingsQuery,
    Types.NotificationSettingsQueryVariables
  >(NotificationSettingsDocument, options);
}
export type NotificationSettingsQueryHookResult = ReturnType<typeof useNotificationSettingsQuery>;
export type NotificationSettingsLazyQueryHookResult = ReturnType<
  typeof useNotificationSettingsLazyQuery
>;
export type NotificationSettingsQueryResult = Apollo.QueryResult<
  Types.NotificationSettingsQuery,
  Types.NotificationSettingsQueryVariables
>;
export function refetchNotificationSettingsQuery(
  variables?: Types.NotificationSettingsQueryVariables,
) {
  return { query: NotificationSettingsDocument, variables: variables };
}
export const PermissionsDocument = gql`
  query permissions {
    permissions {
      id
      key
      value
    }
  }
`;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.PermissionsQuery, Types.PermissionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PermissionsQuery, Types.PermissionsQueryVariables>(
    PermissionsDocument,
    options,
  );
}
export function usePermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.PermissionsQuery,
    Types.PermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PermissionsQuery, Types.PermissionsQueryVariables>(
    PermissionsDocument,
    options,
  );
}
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = Apollo.QueryResult<
  Types.PermissionsQuery,
  Types.PermissionsQueryVariables
>;
export function refetchPermissionsQuery(variables?: Types.PermissionsQueryVariables) {
  return { query: PermissionsDocument, variables: variables };
}
export const PhraseGroupsDocument = gql`
  query phraseGroups($domainId: String!) {
    phraseGroups(domainId: $domainId) {
      ...phraseGroup
    }
  }
  ${PhraseGroupFragmentDoc}
`;

/**
 * __usePhraseGroupsQuery__
 *
 * To run a query within a React component, call `usePhraseGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhraseGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhraseGroupsQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *   },
 * });
 */
export function usePhraseGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.PhraseGroupsQuery, Types.PhraseGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PhraseGroupsQuery, Types.PhraseGroupsQueryVariables>(
    PhraseGroupsDocument,
    options,
  );
}
export function usePhraseGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.PhraseGroupsQuery,
    Types.PhraseGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PhraseGroupsQuery, Types.PhraseGroupsQueryVariables>(
    PhraseGroupsDocument,
    options,
  );
}
export type PhraseGroupsQueryHookResult = ReturnType<typeof usePhraseGroupsQuery>;
export type PhraseGroupsLazyQueryHookResult = ReturnType<typeof usePhraseGroupsLazyQuery>;
export type PhraseGroupsQueryResult = Apollo.QueryResult<
  Types.PhraseGroupsQuery,
  Types.PhraseGroupsQueryVariables
>;
export function refetchPhraseGroupsQuery(variables: Types.PhraseGroupsQueryVariables) {
  return { query: PhraseGroupsDocument, variables: variables };
}
export const ProjectDocument = gql`
  query project($id: ID!) {
    project(id: $id) {
      ...project
    }
  }
  ${ProjectFragmentDoc}
  ${TypeFragmentDoc}
  ${StatusFragmentDoc}
  ${PriorityFragmentDoc}
`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(
  baseOptions: Apollo.QueryHookOptions<Types.ProjectQuery, Types.ProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ProjectQuery, Types.ProjectQueryVariables>(ProjectDocument, options);
}
export function useProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectQuery, Types.ProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ProjectQuery, Types.ProjectQueryVariables>(
    ProjectDocument,
    options,
  );
}
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<
  Types.ProjectQuery,
  Types.ProjectQueryVariables
>;
export function refetchProjectQuery(variables: Types.ProjectQueryVariables) {
  return { query: ProjectDocument, variables: variables };
}
export const ProjectsDocument = gql`
  query projects {
    projects {
      ...project
    }
  }
  ${ProjectFragmentDoc}
  ${TypeFragmentDoc}
  ${StatusFragmentDoc}
  ${PriorityFragmentDoc}
`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ProjectsQuery, Types.ProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ProjectsQuery, Types.ProjectsQueryVariables>(
    ProjectsDocument,
    options,
  );
}
export function useProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProjectsQuery, Types.ProjectsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ProjectsQuery, Types.ProjectsQueryVariables>(
    ProjectsDocument,
    options,
  );
}
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<
  Types.ProjectsQuery,
  Types.ProjectsQueryVariables
>;
export function refetchProjectsQuery(variables?: Types.ProjectsQueryVariables) {
  return { query: ProjectsDocument, variables: variables };
}
export const ProjectsUsersDocument = gql`
  query projectsUsers {
    projects {
      ...projectUsers
    }
  }
  ${ProjectUsersFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useProjectsUsersQuery__
 *
 * To run a query within a React component, call `useProjectsUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ProjectsUsersQuery,
    Types.ProjectsUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ProjectsUsersQuery, Types.ProjectsUsersQueryVariables>(
    ProjectsUsersDocument,
    options,
  );
}
export function useProjectsUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ProjectsUsersQuery,
    Types.ProjectsUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ProjectsUsersQuery, Types.ProjectsUsersQueryVariables>(
    ProjectsUsersDocument,
    options,
  );
}
export type ProjectsUsersQueryHookResult = ReturnType<typeof useProjectsUsersQuery>;
export type ProjectsUsersLazyQueryHookResult = ReturnType<typeof useProjectsUsersLazyQuery>;
export type ProjectsUsersQueryResult = Apollo.QueryResult<
  Types.ProjectsUsersQuery,
  Types.ProjectsUsersQueryVariables
>;
export function refetchProjectsUsersQuery(variables?: Types.ProjectsUsersQueryVariables) {
  return { query: ProjectsUsersDocument, variables: variables };
}
export const QuartersDocument = gql`
  query quarters($id: ID!) {
    domain(id: $id) {
      id
      quarters {
        ...quarter
      }
      quarterOverrides {
        ...quarterOverride
      }
    }
  }
  ${QuarterFragmentDoc}
  ${QuarterOverrideFragmentDoc}
`;

/**
 * __useQuartersQuery__
 *
 * To run a query within a React component, call `useQuartersQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuartersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuartersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuartersQuery(
  baseOptions: Apollo.QueryHookOptions<Types.QuartersQuery, Types.QuartersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.QuartersQuery, Types.QuartersQueryVariables>(
    QuartersDocument,
    options,
  );
}
export function useQuartersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.QuartersQuery, Types.QuartersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.QuartersQuery, Types.QuartersQueryVariables>(
    QuartersDocument,
    options,
  );
}
export type QuartersQueryHookResult = ReturnType<typeof useQuartersQuery>;
export type QuartersLazyQueryHookResult = ReturnType<typeof useQuartersLazyQuery>;
export type QuartersQueryResult = Apollo.QueryResult<
  Types.QuartersQuery,
  Types.QuartersQueryVariables
>;
export function refetchQuartersQuery(variables: Types.QuartersQueryVariables) {
  return { query: QuartersDocument, variables: variables };
}
export const RegionsDocument = gql`
  query regions($country: String!) {
    regions(country: $country)
  }
`;

/**
 * __useRegionsQuery__
 *
 * To run a query within a React component, call `useRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsQuery({
 *   variables: {
 *      country: // value for 'country'
 *   },
 * });
 */
export function useRegionsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.RegionsQuery, Types.RegionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.RegionsQuery, Types.RegionsQueryVariables>(RegionsDocument, options);
}
export function useRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.RegionsQuery, Types.RegionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.RegionsQuery, Types.RegionsQueryVariables>(
    RegionsDocument,
    options,
  );
}
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>;
export type RegionsLazyQueryHookResult = ReturnType<typeof useRegionsLazyQuery>;
export type RegionsQueryResult = Apollo.QueryResult<
  Types.RegionsQuery,
  Types.RegionsQueryVariables
>;
export function refetchRegionsQuery(variables: Types.RegionsQueryVariables) {
  return { query: RegionsDocument, variables: variables };
}
export const ReportLanguagesExternalReportDocument = gql`
  query reportLanguagesExternalReport {
    reportLanguagesExternalReport {
      id
      key
    }
  }
`;

/**
 * __useReportLanguagesExternalReportQuery__
 *
 * To run a query within a React component, call `useReportLanguagesExternalReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportLanguagesExternalReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportLanguagesExternalReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportLanguagesExternalReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.ReportLanguagesExternalReportQuery,
    Types.ReportLanguagesExternalReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.ReportLanguagesExternalReportQuery,
    Types.ReportLanguagesExternalReportQueryVariables
  >(ReportLanguagesExternalReportDocument, options);
}
export function useReportLanguagesExternalReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.ReportLanguagesExternalReportQuery,
    Types.ReportLanguagesExternalReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.ReportLanguagesExternalReportQuery,
    Types.ReportLanguagesExternalReportQueryVariables
  >(ReportLanguagesExternalReportDocument, options);
}
export type ReportLanguagesExternalReportQueryHookResult = ReturnType<
  typeof useReportLanguagesExternalReportQuery
>;
export type ReportLanguagesExternalReportLazyQueryHookResult = ReturnType<
  typeof useReportLanguagesExternalReportLazyQuery
>;
export type ReportLanguagesExternalReportQueryResult = Apollo.QueryResult<
  Types.ReportLanguagesExternalReportQuery,
  Types.ReportLanguagesExternalReportQueryVariables
>;
export function refetchReportLanguagesExternalReportQuery(
  variables?: Types.ReportLanguagesExternalReportQueryVariables,
) {
  return { query: ReportLanguagesExternalReportDocument, variables: variables };
}
export const RolesDocument = gql`
  query roles {
    roles {
      ...roleInTheList
    }
  }
  ${RoleInTheListFragmentDoc}
`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.RolesQuery, Types.RolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.RolesQuery, Types.RolesQueryVariables>(RolesDocument, options);
}
export function useRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.RolesQuery, Types.RolesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.RolesQuery, Types.RolesQueryVariables>(RolesDocument, options);
}
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<Types.RolesQuery, Types.RolesQueryVariables>;
export function refetchRolesQuery(variables?: Types.RolesQueryVariables) {
  return { query: RolesDocument, variables: variables };
}
export const S4sInstanceDocument = gql`
  query s4sInstance($id: ID!) {
    s4sInstance(id: $id) {
      ...s4sInstance
    }
  }
  ${S4sInstanceFragmentDoc}
`;

/**
 * __useS4sInstanceQuery__
 *
 * To run a query within a React component, call `useS4sInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useS4sInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useS4sInstanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useS4sInstanceQuery(
  baseOptions: Apollo.QueryHookOptions<Types.S4sInstanceQuery, Types.S4sInstanceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.S4sInstanceQuery, Types.S4sInstanceQueryVariables>(
    S4sInstanceDocument,
    options,
  );
}
export function useS4sInstanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.S4sInstanceQuery,
    Types.S4sInstanceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.S4sInstanceQuery, Types.S4sInstanceQueryVariables>(
    S4sInstanceDocument,
    options,
  );
}
export type S4sInstanceQueryHookResult = ReturnType<typeof useS4sInstanceQuery>;
export type S4sInstanceLazyQueryHookResult = ReturnType<typeof useS4sInstanceLazyQuery>;
export type S4sInstanceQueryResult = Apollo.QueryResult<
  Types.S4sInstanceQuery,
  Types.S4sInstanceQueryVariables
>;
export function refetchS4sInstanceQuery(variables: Types.S4sInstanceQueryVariables) {
  return { query: S4sInstanceDocument, variables: variables };
}
export const S4sInstancesDocument = gql`
  query s4sInstances($grid: S4sGridInput) {
    s4sInstances(grid: $grid) {
      total
      rows {
        ...s4sInstance
      }
    }
  }
  ${S4sInstanceFragmentDoc}
`;

/**
 * __useS4sInstancesQuery__
 *
 * To run a query within a React component, call `useS4sInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useS4sInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useS4sInstancesQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useS4sInstancesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.S4sInstancesQuery, Types.S4sInstancesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.S4sInstancesQuery, Types.S4sInstancesQueryVariables>(
    S4sInstancesDocument,
    options,
  );
}
export function useS4sInstancesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.S4sInstancesQuery,
    Types.S4sInstancesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.S4sInstancesQuery, Types.S4sInstancesQueryVariables>(
    S4sInstancesDocument,
    options,
  );
}
export type S4sInstancesQueryHookResult = ReturnType<typeof useS4sInstancesQuery>;
export type S4sInstancesLazyQueryHookResult = ReturnType<typeof useS4sInstancesLazyQuery>;
export type S4sInstancesQueryResult = Apollo.QueryResult<
  Types.S4sInstancesQuery,
  Types.S4sInstancesQueryVariables
>;
export function refetchS4sInstancesQuery(variables?: Types.S4sInstancesQueryVariables) {
  return { query: S4sInstancesDocument, variables: variables };
}
export const SingleDomainReportsDocument = gql`
  query singleDomainReports($domainId: ID!, $grid: DomainReportGridInput) {
    singleDomainReports(domainId: $domainId, grid: $grid) {
      total
      rows {
        ...domainReport
      }
    }
  }
  ${DomainReportFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useSingleDomainReportsQuery__
 *
 * To run a query within a React component, call `useSingleDomainReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleDomainReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleDomainReportsQuery({
 *   variables: {
 *      domainId: // value for 'domainId'
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useSingleDomainReportsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.SingleDomainReportsQuery,
    Types.SingleDomainReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SingleDomainReportsQuery, Types.SingleDomainReportsQueryVariables>(
    SingleDomainReportsDocument,
    options,
  );
}
export function useSingleDomainReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SingleDomainReportsQuery,
    Types.SingleDomainReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.SingleDomainReportsQuery,
    Types.SingleDomainReportsQueryVariables
  >(SingleDomainReportsDocument, options);
}
export type SingleDomainReportsQueryHookResult = ReturnType<typeof useSingleDomainReportsQuery>;
export type SingleDomainReportsLazyQueryHookResult = ReturnType<
  typeof useSingleDomainReportsLazyQuery
>;
export type SingleDomainReportsQueryResult = Apollo.QueryResult<
  Types.SingleDomainReportsQuery,
  Types.SingleDomainReportsQueryVariables
>;
export function refetchSingleDomainReportsQuery(
  variables: Types.SingleDomainReportsQueryVariables,
) {
  return { query: SingleDomainReportsDocument, variables: variables };
}
export const SubpagePrioritiesDocument = gql`
  query subpagePriorities {
    subpagePriorities {
      key
      id
    }
  }
`;

/**
 * __useSubpagePrioritiesQuery__
 *
 * To run a query within a React component, call `useSubpagePrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubpagePrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubpagePrioritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubpagePrioritiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.SubpagePrioritiesQuery,
    Types.SubpagePrioritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SubpagePrioritiesQuery, Types.SubpagePrioritiesQueryVariables>(
    SubpagePrioritiesDocument,
    options,
  );
}
export function useSubpagePrioritiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SubpagePrioritiesQuery,
    Types.SubpagePrioritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SubpagePrioritiesQuery, Types.SubpagePrioritiesQueryVariables>(
    SubpagePrioritiesDocument,
    options,
  );
}
export type SubpagePrioritiesQueryHookResult = ReturnType<typeof useSubpagePrioritiesQuery>;
export type SubpagePrioritiesLazyQueryHookResult = ReturnType<typeof useSubpagePrioritiesLazyQuery>;
export type SubpagePrioritiesQueryResult = Apollo.QueryResult<
  Types.SubpagePrioritiesQuery,
  Types.SubpagePrioritiesQueryVariables
>;
export function refetchSubpagePrioritiesQuery(variables?: Types.SubpagePrioritiesQueryVariables) {
  return { query: SubpagePrioritiesDocument, variables: variables };
}
export const SubpageStatusesDocument = gql`
  query subpageStatuses {
    subpageStatuses {
      key
      id
    }
  }
`;

/**
 * __useSubpageStatusesQuery__
 *
 * To run a query within a React component, call `useSubpageStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubpageStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubpageStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubpageStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.SubpageStatusesQuery,
    Types.SubpageStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SubpageStatusesQuery, Types.SubpageStatusesQueryVariables>(
    SubpageStatusesDocument,
    options,
  );
}
export function useSubpageStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SubpageStatusesQuery,
    Types.SubpageStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SubpageStatusesQuery, Types.SubpageStatusesQueryVariables>(
    SubpageStatusesDocument,
    options,
  );
}
export type SubpageStatusesQueryHookResult = ReturnType<typeof useSubpageStatusesQuery>;
export type SubpageStatusesLazyQueryHookResult = ReturnType<typeof useSubpageStatusesLazyQuery>;
export type SubpageStatusesQueryResult = Apollo.QueryResult<
  Types.SubpageStatusesQuery,
  Types.SubpageStatusesQueryVariables
>;
export function refetchSubpageStatusesQuery(variables?: Types.SubpageStatusesQueryVariables) {
  return { query: SubpageStatusesDocument, variables: variables };
}
export const SubpageTypesDocument = gql`
  query subpageTypes {
    subpageTypes {
      key
      id
    }
  }
`;

/**
 * __useSubpageTypesQuery__
 *
 * To run a query within a React component, call `useSubpageTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubpageTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubpageTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubpageTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.SubpageTypesQuery, Types.SubpageTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SubpageTypesQuery, Types.SubpageTypesQueryVariables>(
    SubpageTypesDocument,
    options,
  );
}
export function useSubpageTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SubpageTypesQuery,
    Types.SubpageTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SubpageTypesQuery, Types.SubpageTypesQueryVariables>(
    SubpageTypesDocument,
    options,
  );
}
export type SubpageTypesQueryHookResult = ReturnType<typeof useSubpageTypesQuery>;
export type SubpageTypesLazyQueryHookResult = ReturnType<typeof useSubpageTypesLazyQuery>;
export type SubpageTypesQueryResult = Apollo.QueryResult<
  Types.SubpageTypesQuery,
  Types.SubpageTypesQueryVariables
>;
export function refetchSubpageTypesQuery(variables?: Types.SubpageTypesQueryVariables) {
  return { query: SubpageTypesDocument, variables: variables };
}
export const SubpagesDocument = gql`
  query subpages($grid: SubpageGridInput) {
    subpages(grid: $grid) {
      total
      rows {
        ...subpage
      }
    }
  }
  ${SubpageFragmentDoc}
`;

/**
 * __useSubpagesQuery__
 *
 * To run a query within a React component, call `useSubpagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubpagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubpagesQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useSubpagesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.SubpagesQuery, Types.SubpagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SubpagesQuery, Types.SubpagesQueryVariables>(
    SubpagesDocument,
    options,
  );
}
export function useSubpagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.SubpagesQuery, Types.SubpagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SubpagesQuery, Types.SubpagesQueryVariables>(
    SubpagesDocument,
    options,
  );
}
export type SubpagesQueryHookResult = ReturnType<typeof useSubpagesQuery>;
export type SubpagesLazyQueryHookResult = ReturnType<typeof useSubpagesLazyQuery>;
export type SubpagesQueryResult = Apollo.QueryResult<
  Types.SubpagesQuery,
  Types.SubpagesQueryVariables
>;
export function refetchSubpagesQuery(variables?: Types.SubpagesQueryVariables) {
  return { query: SubpagesDocument, variables: variables };
}
export const TeamDocument = gql`
  query team($id: ID!) {
    team(id: $id) {
      ...team
    }
  }
  ${TeamFragmentDoc}
  ${UserInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useTeamQuery__
 *
 * To run a query within a React component, call `useTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamQuery(
  baseOptions: Apollo.QueryHookOptions<Types.TeamQuery, Types.TeamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TeamQuery, Types.TeamQueryVariables>(TeamDocument, options);
}
export function useTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamQuery, Types.TeamQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TeamQuery, Types.TeamQueryVariables>(TeamDocument, options);
}
export type TeamQueryHookResult = ReturnType<typeof useTeamQuery>;
export type TeamLazyQueryHookResult = ReturnType<typeof useTeamLazyQuery>;
export type TeamQueryResult = Apollo.QueryResult<Types.TeamQuery, Types.TeamQueryVariables>;
export function refetchTeamQuery(variables: Types.TeamQueryVariables) {
  return { query: TeamDocument, variables: variables };
}
export const TeamListDocument = gql`
  query teamList($grid: TeamGridInput) {
    teams(grid: $grid) {
      total
      rows {
        ...teamInTheList
      }
    }
  }
  ${TeamInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useTeamListQuery__
 *
 * To run a query within a React component, call `useTeamListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamListQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useTeamListQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.TeamListQuery, Types.TeamListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TeamListQuery, Types.TeamListQueryVariables>(
    TeamListDocument,
    options,
  );
}
export function useTeamListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamListQuery, Types.TeamListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TeamListQuery, Types.TeamListQueryVariables>(
    TeamListDocument,
    options,
  );
}
export type TeamListQueryHookResult = ReturnType<typeof useTeamListQuery>;
export type TeamListLazyQueryHookResult = ReturnType<typeof useTeamListLazyQuery>;
export type TeamListQueryResult = Apollo.QueryResult<
  Types.TeamListQuery,
  Types.TeamListQueryVariables
>;
export function refetchTeamListQuery(variables?: Types.TeamListQueryVariables) {
  return { query: TeamListDocument, variables: variables };
}
export const TeamStatisticsDocument = gql`
  query teamStatistics($id: ID!) {
    teamStatistics(id: $id) {
      id
      totals {
        ...teamStatisticsTotals
      }
      details {
        ...userStatisticsDetails
      }
    }
  }
  ${TeamStatisticsTotalsFragmentDoc}
  ${UserStatisticsDetailsFragmentDoc}
`;

/**
 * __useTeamStatisticsQuery__
 *
 * To run a query within a React component, call `useTeamStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamStatisticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.TeamStatisticsQuery,
    Types.TeamStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TeamStatisticsQuery, Types.TeamStatisticsQueryVariables>(
    TeamStatisticsDocument,
    options,
  );
}
export function useTeamStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TeamStatisticsQuery,
    Types.TeamStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TeamStatisticsQuery, Types.TeamStatisticsQueryVariables>(
    TeamStatisticsDocument,
    options,
  );
}
export type TeamStatisticsQueryHookResult = ReturnType<typeof useTeamStatisticsQuery>;
export type TeamStatisticsLazyQueryHookResult = ReturnType<typeof useTeamStatisticsLazyQuery>;
export type TeamStatisticsQueryResult = Apollo.QueryResult<
  Types.TeamStatisticsQuery,
  Types.TeamStatisticsQueryVariables
>;
export function refetchTeamStatisticsQuery(variables: Types.TeamStatisticsQueryVariables) {
  return { query: TeamStatisticsDocument, variables: variables };
}
export const TeamsDocument = gql`
  query teams($grid: TeamGridInput) {
    teams(grid: $grid) {
      total
      rows {
        ...team
      }
    }
  }
  ${TeamFragmentDoc}
  ${UserInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.TeamsQuery, Types.TeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TeamsQuery, Types.TeamsQueryVariables>(TeamsDocument, options);
}
export function useTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.TeamsQuery, Types.TeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TeamsQuery, Types.TeamsQueryVariables>(TeamsDocument, options);
}
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>;
export type TeamsLazyQueryHookResult = ReturnType<typeof useTeamsLazyQuery>;
export type TeamsQueryResult = Apollo.QueryResult<Types.TeamsQuery, Types.TeamsQueryVariables>;
export function refetchTeamsQuery(variables?: Types.TeamsQueryVariables) {
  return { query: TeamsDocument, variables: variables };
}
export const TemplatesExternalReportDocument = gql`
  query templatesExternalReport {
    templatesExternalReport {
      id
      key
    }
  }
`;

/**
 * __useTemplatesExternalReportQuery__
 *
 * To run a query within a React component, call `useTemplatesExternalReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesExternalReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesExternalReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplatesExternalReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TemplatesExternalReportQuery,
    Types.TemplatesExternalReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.TemplatesExternalReportQuery,
    Types.TemplatesExternalReportQueryVariables
  >(TemplatesExternalReportDocument, options);
}
export function useTemplatesExternalReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TemplatesExternalReportQuery,
    Types.TemplatesExternalReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.TemplatesExternalReportQuery,
    Types.TemplatesExternalReportQueryVariables
  >(TemplatesExternalReportDocument, options);
}
export type TemplatesExternalReportQueryHookResult = ReturnType<
  typeof useTemplatesExternalReportQuery
>;
export type TemplatesExternalReportLazyQueryHookResult = ReturnType<
  typeof useTemplatesExternalReportLazyQuery
>;
export type TemplatesExternalReportQueryResult = Apollo.QueryResult<
  Types.TemplatesExternalReportQuery,
  Types.TemplatesExternalReportQueryVariables
>;
export function refetchTemplatesExternalReportQuery(
  variables?: Types.TemplatesExternalReportQueryVariables,
) {
  return { query: TemplatesExternalReportDocument, variables: variables };
}
export const TextOrderOrderTypesDocument = gql`
  query textOrderOrderTypes {
    textOrderOrderTypes {
      id
      key
      value
    }
  }
`;

/**
 * __useTextOrderOrderTypesQuery__
 *
 * To run a query within a React component, call `useTextOrderOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextOrderOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextOrderOrderTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTextOrderOrderTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TextOrderOrderTypesQuery,
    Types.TextOrderOrderTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TextOrderOrderTypesQuery, Types.TextOrderOrderTypesQueryVariables>(
    TextOrderOrderTypesDocument,
    options,
  );
}
export function useTextOrderOrderTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TextOrderOrderTypesQuery,
    Types.TextOrderOrderTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.TextOrderOrderTypesQuery,
    Types.TextOrderOrderTypesQueryVariables
  >(TextOrderOrderTypesDocument, options);
}
export type TextOrderOrderTypesQueryHookResult = ReturnType<typeof useTextOrderOrderTypesQuery>;
export type TextOrderOrderTypesLazyQueryHookResult = ReturnType<
  typeof useTextOrderOrderTypesLazyQuery
>;
export type TextOrderOrderTypesQueryResult = Apollo.QueryResult<
  Types.TextOrderOrderTypesQuery,
  Types.TextOrderOrderTypesQueryVariables
>;
export function refetchTextOrderOrderTypesQuery(
  variables?: Types.TextOrderOrderTypesQueryVariables,
) {
  return { query: TextOrderOrderTypesDocument, variables: variables };
}
export const TextOrderPrioritiesDocument = gql`
  query textOrderPriorities {
    textOrderPriorities {
      id
      key
      value
    }
  }
`;

/**
 * __useTextOrderPrioritiesQuery__
 *
 * To run a query within a React component, call `useTextOrderPrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextOrderPrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextOrderPrioritiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTextOrderPrioritiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TextOrderPrioritiesQuery,
    Types.TextOrderPrioritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TextOrderPrioritiesQuery, Types.TextOrderPrioritiesQueryVariables>(
    TextOrderPrioritiesDocument,
    options,
  );
}
export function useTextOrderPrioritiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TextOrderPrioritiesQuery,
    Types.TextOrderPrioritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.TextOrderPrioritiesQuery,
    Types.TextOrderPrioritiesQueryVariables
  >(TextOrderPrioritiesDocument, options);
}
export type TextOrderPrioritiesQueryHookResult = ReturnType<typeof useTextOrderPrioritiesQuery>;
export type TextOrderPrioritiesLazyQueryHookResult = ReturnType<
  typeof useTextOrderPrioritiesLazyQuery
>;
export type TextOrderPrioritiesQueryResult = Apollo.QueryResult<
  Types.TextOrderPrioritiesQuery,
  Types.TextOrderPrioritiesQueryVariables
>;
export function refetchTextOrderPrioritiesQuery(
  variables?: Types.TextOrderPrioritiesQueryVariables,
) {
  return { query: TextOrderPrioritiesDocument, variables: variables };
}
export const TextOrdersStatisticsDocument = gql`
  query textOrdersStatistics($grid: TextOrderStatisticsGridInput) {
    textOrdersStatistics(grid: $grid) {
      total
      statistics {
        ...textOrdersStatistics
      }
    }
  }
  ${TextOrdersStatisticsFragmentDoc}
`;

/**
 * __useTextOrdersStatisticsQuery__
 *
 * To run a query within a React component, call `useTextOrdersStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextOrdersStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextOrdersStatisticsQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useTextOrdersStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TextOrdersStatisticsQuery,
    Types.TextOrdersStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TextOrdersStatisticsQuery, Types.TextOrdersStatisticsQueryVariables>(
    TextOrdersStatisticsDocument,
    options,
  );
}
export function useTextOrdersStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TextOrdersStatisticsQuery,
    Types.TextOrdersStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.TextOrdersStatisticsQuery,
    Types.TextOrdersStatisticsQueryVariables
  >(TextOrdersStatisticsDocument, options);
}
export type TextOrdersStatisticsQueryHookResult = ReturnType<typeof useTextOrdersStatisticsQuery>;
export type TextOrdersStatisticsLazyQueryHookResult = ReturnType<
  typeof useTextOrdersStatisticsLazyQuery
>;
export type TextOrdersStatisticsQueryResult = Apollo.QueryResult<
  Types.TextOrdersStatisticsQuery,
  Types.TextOrdersStatisticsQueryVariables
>;
export function refetchTextOrdersStatisticsQuery(
  variables?: Types.TextOrdersStatisticsQueryVariables,
) {
  return { query: TextOrdersStatisticsDocument, variables: variables };
}
export const TextOrderStatusesDocument = gql`
  query textOrderStatuses {
    textOrderStatuses {
      id
      key
      value
    }
  }
`;

/**
 * __useTextOrderStatusesQuery__
 *
 * To run a query within a React component, call `useTextOrderStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextOrderStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextOrderStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTextOrderStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TextOrderStatusesQuery,
    Types.TextOrderStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TextOrderStatusesQuery, Types.TextOrderStatusesQueryVariables>(
    TextOrderStatusesDocument,
    options,
  );
}
export function useTextOrderStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TextOrderStatusesQuery,
    Types.TextOrderStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TextOrderStatusesQuery, Types.TextOrderStatusesQueryVariables>(
    TextOrderStatusesDocument,
    options,
  );
}
export type TextOrderStatusesQueryHookResult = ReturnType<typeof useTextOrderStatusesQuery>;
export type TextOrderStatusesLazyQueryHookResult = ReturnType<typeof useTextOrderStatusesLazyQuery>;
export type TextOrderStatusesQueryResult = Apollo.QueryResult<
  Types.TextOrderStatusesQuery,
  Types.TextOrderStatusesQueryVariables
>;
export function refetchTextOrderStatusesQuery(variables?: Types.TextOrderStatusesQueryVariables) {
  return { query: TextOrderStatusesDocument, variables: variables };
}
export const TextOrderTextTypesDocument = gql`
  query textOrderTextTypes {
    textOrderTextTypes {
      id
      key
      value
    }
  }
`;

/**
 * __useTextOrderTextTypesQuery__
 *
 * To run a query within a React component, call `useTextOrderTextTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextOrderTextTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextOrderTextTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTextOrderTextTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TextOrderTextTypesQuery,
    Types.TextOrderTextTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TextOrderTextTypesQuery, Types.TextOrderTextTypesQueryVariables>(
    TextOrderTextTypesDocument,
    options,
  );
}
export function useTextOrderTextTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TextOrderTextTypesQuery,
    Types.TextOrderTextTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TextOrderTextTypesQuery, Types.TextOrderTextTypesQueryVariables>(
    TextOrderTextTypesDocument,
    options,
  );
}
export type TextOrderTextTypesQueryHookResult = ReturnType<typeof useTextOrderTextTypesQuery>;
export type TextOrderTextTypesLazyQueryHookResult = ReturnType<
  typeof useTextOrderTextTypesLazyQuery
>;
export type TextOrderTextTypesQueryResult = Apollo.QueryResult<
  Types.TextOrderTextTypesQuery,
  Types.TextOrderTextTypesQueryVariables
>;
export function refetchTextOrderTextTypesQuery(variables?: Types.TextOrderTextTypesQueryVariables) {
  return { query: TextOrderTextTypesDocument, variables: variables };
}
export const TextOrdersDocument = gql`
  query textOrders($grid: TextOrderGridInput) {
    textOrders(grid: $grid) {
      total
      rows {
        ...textOrder
        __typename
      }
      __typename
    }
  }
  ${TextOrderFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useTextOrdersQuery__
 *
 * To run a query within a React component, call `useTextOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextOrdersQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useTextOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.TextOrdersQuery, Types.TextOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TextOrdersQuery, Types.TextOrdersQueryVariables>(
    TextOrdersDocument,
    options,
  );
}
export function useTextOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.TextOrdersQuery, Types.TextOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TextOrdersQuery, Types.TextOrdersQueryVariables>(
    TextOrdersDocument,
    options,
  );
}
export type TextOrdersQueryHookResult = ReturnType<typeof useTextOrdersQuery>;
export type TextOrdersLazyQueryHookResult = ReturnType<typeof useTextOrdersLazyQuery>;
export type TextOrdersQueryResult = Apollo.QueryResult<
  Types.TextOrdersQuery,
  Types.TextOrdersQueryVariables
>;
export function refetchTextOrdersQuery(variables?: Types.TextOrdersQueryVariables) {
  return { query: TextOrdersDocument, variables: variables };
}
export const TextWritersDocument = gql`
  query textWriters {
    textWriters {
      id
      key
      value
    }
  }
`;

/**
 * __useTextWritersQuery__
 *
 * To run a query within a React component, call `useTextWritersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTextWritersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTextWritersQuery({
 *   variables: {
 *   },
 * });
 */
export function useTextWritersQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.TextWritersQuery, Types.TextWritersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TextWritersQuery, Types.TextWritersQueryVariables>(
    TextWritersDocument,
    options,
  );
}
export function useTextWritersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TextWritersQuery,
    Types.TextWritersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TextWritersQuery, Types.TextWritersQueryVariables>(
    TextWritersDocument,
    options,
  );
}
export type TextWritersQueryHookResult = ReturnType<typeof useTextWritersQuery>;
export type TextWritersLazyQueryHookResult = ReturnType<typeof useTextWritersLazyQuery>;
export type TextWritersQueryResult = Apollo.QueryResult<
  Types.TextWritersQuery,
  Types.TextWritersQueryVariables
>;
export function refetchTextWritersQuery(variables?: Types.TextWritersQueryVariables) {
  return { query: TextWritersDocument, variables: variables };
}
export const TicketDocument = gql`
  query ticket($id: ID!) {
    ticket(id: $id) {
      ...ticket
    }
  }
  ${TicketFragmentDoc}
  ${UserWithPermInfoFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketFileFragmentDoc}
`;

/**
 * __useTicketQuery__
 *
 * To run a query within a React component, call `useTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketQuery(
  baseOptions: Apollo.QueryHookOptions<Types.TicketQuery, Types.TicketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TicketQuery, Types.TicketQueryVariables>(TicketDocument, options);
}
export function useTicketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.TicketQuery, Types.TicketQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TicketQuery, Types.TicketQueryVariables>(
    TicketDocument,
    options,
  );
}
export type TicketQueryHookResult = ReturnType<typeof useTicketQuery>;
export type TicketLazyQueryHookResult = ReturnType<typeof useTicketLazyQuery>;
export type TicketQueryResult = Apollo.QueryResult<Types.TicketQuery, Types.TicketQueryVariables>;
export function refetchTicketQuery(variables: Types.TicketQueryVariables) {
  return { query: TicketDocument, variables: variables };
}
export const TicketActivitiesDocument = gql`
  query ticketActivities($grid: TicketActivitiesGridInput) {
    ticketActivities(grid: $grid) {
      total
      rows {
        ...activity
      }
    }
  }
  ${ActivityFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useTicketActivitiesQuery__
 *
 * To run a query within a React component, call `useTicketActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketActivitiesQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useTicketActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TicketActivitiesQuery,
    Types.TicketActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TicketActivitiesQuery, Types.TicketActivitiesQueryVariables>(
    TicketActivitiesDocument,
    options,
  );
}
export function useTicketActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TicketActivitiesQuery,
    Types.TicketActivitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TicketActivitiesQuery, Types.TicketActivitiesQueryVariables>(
    TicketActivitiesDocument,
    options,
  );
}
export type TicketActivitiesQueryHookResult = ReturnType<typeof useTicketActivitiesQuery>;
export type TicketActivitiesLazyQueryHookResult = ReturnType<typeof useTicketActivitiesLazyQuery>;
export type TicketActivitiesQueryResult = Apollo.QueryResult<
  Types.TicketActivitiesQuery,
  Types.TicketActivitiesQueryVariables
>;
export function refetchTicketActivitiesQuery(variables?: Types.TicketActivitiesQueryVariables) {
  return { query: TicketActivitiesDocument, variables: variables };
}
export const TicketCommentsDocument = gql`
  query ticketComments($grid: TicketCommentsGridInput) {
    ticketComments(grid: $grid) {
      total
      rows {
        ...ticketComment
      }
    }
  }
  ${TicketCommentFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketCommentFileFragmentDoc}
`;

/**
 * __useTicketCommentsQuery__
 *
 * To run a query within a React component, call `useTicketCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketCommentsQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useTicketCommentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TicketCommentsQuery,
    Types.TicketCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TicketCommentsQuery, Types.TicketCommentsQueryVariables>(
    TicketCommentsDocument,
    options,
  );
}
export function useTicketCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TicketCommentsQuery,
    Types.TicketCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TicketCommentsQuery, Types.TicketCommentsQueryVariables>(
    TicketCommentsDocument,
    options,
  );
}
export type TicketCommentsQueryHookResult = ReturnType<typeof useTicketCommentsQuery>;
export type TicketCommentsLazyQueryHookResult = ReturnType<typeof useTicketCommentsLazyQuery>;
export type TicketCommentsQueryResult = Apollo.QueryResult<
  Types.TicketCommentsQuery,
  Types.TicketCommentsQueryVariables
>;
export function refetchTicketCommentsQuery(variables?: Types.TicketCommentsQueryVariables) {
  return { query: TicketCommentsDocument, variables: variables };
}
export const TicketsDocument = gql`
  query tickets($grid: TicketGridInput) {
    tickets(grid: $grid) {
      total
      rows {
        ...ticketInTheList
      }
    }
  }
  ${TicketInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useTicketsQuery__
 *
 * To run a query within a React component, call `useTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useTicketsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.TicketsQuery, Types.TicketsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TicketsQuery, Types.TicketsQueryVariables>(TicketsDocument, options);
}
export function useTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.TicketsQuery, Types.TicketsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TicketsQuery, Types.TicketsQueryVariables>(
    TicketsDocument,
    options,
  );
}
export type TicketsQueryHookResult = ReturnType<typeof useTicketsQuery>;
export type TicketsLazyQueryHookResult = ReturnType<typeof useTicketsLazyQuery>;
export type TicketsQueryResult = Apollo.QueryResult<
  Types.TicketsQuery,
  Types.TicketsQueryVariables
>;
export function refetchTicketsQuery(variables?: Types.TicketsQueryVariables) {
  return { query: TicketsDocument, variables: variables };
}
export const TicketPrioritiesDocument = gql`
  query ticketPriorities($projectId: ID, $includeArchived: Boolean) {
    ticketPriorities(projectId: $projectId, includeArchived: $includeArchived) {
      ...priority
    }
  }
  ${PriorityFragmentDoc}
`;

/**
 * __useTicketPrioritiesQuery__
 *
 * To run a query within a React component, call `useTicketPrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketPrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketPrioritiesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useTicketPrioritiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TicketPrioritiesQuery,
    Types.TicketPrioritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TicketPrioritiesQuery, Types.TicketPrioritiesQueryVariables>(
    TicketPrioritiesDocument,
    options,
  );
}
export function useTicketPrioritiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TicketPrioritiesQuery,
    Types.TicketPrioritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TicketPrioritiesQuery, Types.TicketPrioritiesQueryVariables>(
    TicketPrioritiesDocument,
    options,
  );
}
export type TicketPrioritiesQueryHookResult = ReturnType<typeof useTicketPrioritiesQuery>;
export type TicketPrioritiesLazyQueryHookResult = ReturnType<typeof useTicketPrioritiesLazyQuery>;
export type TicketPrioritiesQueryResult = Apollo.QueryResult<
  Types.TicketPrioritiesQuery,
  Types.TicketPrioritiesQueryVariables
>;
export function refetchTicketPrioritiesQuery(variables?: Types.TicketPrioritiesQueryVariables) {
  return { query: TicketPrioritiesDocument, variables: variables };
}
export const TicketStatusesDocument = gql`
  query ticketStatuses($projectId: ID, $includeArchived: Boolean) {
    ticketStatuses(projectId: $projectId, includeArchived: $includeArchived) {
      ...status
    }
  }
  ${StatusFragmentDoc}
`;

/**
 * __useTicketStatusesQuery__
 *
 * To run a query within a React component, call `useTicketStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketStatusesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useTicketStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TicketStatusesQuery,
    Types.TicketStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TicketStatusesQuery, Types.TicketStatusesQueryVariables>(
    TicketStatusesDocument,
    options,
  );
}
export function useTicketStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TicketStatusesQuery,
    Types.TicketStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TicketStatusesQuery, Types.TicketStatusesQueryVariables>(
    TicketStatusesDocument,
    options,
  );
}
export type TicketStatusesQueryHookResult = ReturnType<typeof useTicketStatusesQuery>;
export type TicketStatusesLazyQueryHookResult = ReturnType<typeof useTicketStatusesLazyQuery>;
export type TicketStatusesQueryResult = Apollo.QueryResult<
  Types.TicketStatusesQuery,
  Types.TicketStatusesQueryVariables
>;
export function refetchTicketStatusesQuery(variables?: Types.TicketStatusesQueryVariables) {
  return { query: TicketStatusesDocument, variables: variables };
}
export const TicketTimeLogsDocument = gql`
  query ticketTimeLogs($grid: TimeLogGridInput) {
    timeLogs(grid: $grid) {
      total
      rows {
        ...timeLog
      }
    }
  }
  ${TimeLogFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useTicketTimeLogsQuery__
 *
 * To run a query within a React component, call `useTicketTimeLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketTimeLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketTimeLogsQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useTicketTimeLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TicketTimeLogsQuery,
    Types.TicketTimeLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TicketTimeLogsQuery, Types.TicketTimeLogsQueryVariables>(
    TicketTimeLogsDocument,
    options,
  );
}
export function useTicketTimeLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TicketTimeLogsQuery,
    Types.TicketTimeLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TicketTimeLogsQuery, Types.TicketTimeLogsQueryVariables>(
    TicketTimeLogsDocument,
    options,
  );
}
export type TicketTimeLogsQueryHookResult = ReturnType<typeof useTicketTimeLogsQuery>;
export type TicketTimeLogsLazyQueryHookResult = ReturnType<typeof useTicketTimeLogsLazyQuery>;
export type TicketTimeLogsQueryResult = Apollo.QueryResult<
  Types.TicketTimeLogsQuery,
  Types.TicketTimeLogsQueryVariables
>;
export function refetchTicketTimeLogsQuery(variables?: Types.TicketTimeLogsQueryVariables) {
  return { query: TicketTimeLogsDocument, variables: variables };
}
export const TicketTodosDocument = gql`
  query ticketTodos($ticketId: ID!) {
    ticketTodos(ticketId: $ticketId) {
      ...ticketTodo
    }
  }
  ${TicketTodoFragmentDoc}
`;

/**
 * __useTicketTodosQuery__
 *
 * To run a query within a React component, call `useTicketTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketTodosQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useTicketTodosQuery(
  baseOptions: Apollo.QueryHookOptions<Types.TicketTodosQuery, Types.TicketTodosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TicketTodosQuery, Types.TicketTodosQueryVariables>(
    TicketTodosDocument,
    options,
  );
}
export function useTicketTodosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TicketTodosQuery,
    Types.TicketTodosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TicketTodosQuery, Types.TicketTodosQueryVariables>(
    TicketTodosDocument,
    options,
  );
}
export type TicketTodosQueryHookResult = ReturnType<typeof useTicketTodosQuery>;
export type TicketTodosLazyQueryHookResult = ReturnType<typeof useTicketTodosLazyQuery>;
export type TicketTodosQueryResult = Apollo.QueryResult<
  Types.TicketTodosQuery,
  Types.TicketTodosQueryVariables
>;
export function refetchTicketTodosQuery(variables: Types.TicketTodosQueryVariables) {
  return { query: TicketTodosDocument, variables: variables };
}
export const TicketTypesDocument = gql`
  query ticketTypes($projectId: ID, $includeArchived: Boolean) {
    ticketTypes(projectId: $projectId, includeArchived: $includeArchived) {
      ...type
    }
  }
  ${TypeFragmentDoc}
`;

/**
 * __useTicketTypesQuery__
 *
 * To run a query within a React component, call `useTicketTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketTypesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      includeArchived: // value for 'includeArchived'
 *   },
 * });
 */
export function useTicketTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.TicketTypesQuery, Types.TicketTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TicketTypesQuery, Types.TicketTypesQueryVariables>(
    TicketTypesDocument,
    options,
  );
}
export function useTicketTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TicketTypesQuery,
    Types.TicketTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TicketTypesQuery, Types.TicketTypesQueryVariables>(
    TicketTypesDocument,
    options,
  );
}
export type TicketTypesQueryHookResult = ReturnType<typeof useTicketTypesQuery>;
export type TicketTypesLazyQueryHookResult = ReturnType<typeof useTicketTypesLazyQuery>;
export type TicketTypesQueryResult = Apollo.QueryResult<
  Types.TicketTypesQuery,
  Types.TicketTypesQueryVariables
>;
export function refetchTicketTypesQuery(variables?: Types.TicketTypesQueryVariables) {
  return { query: TicketTypesDocument, variables: variables };
}
export const TicketWatchersDocument = gql`
  query ticketWatchers($ticketId: ID!) {
    ticketWatchers(id: $ticketId) {
      ...userBasic
    }
  }
  ${UserBasicFragmentDoc}
`;

/**
 * __useTicketWatchersQuery__
 *
 * To run a query within a React component, call `useTicketWatchersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketWatchersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketWatchersQuery({
 *   variables: {
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useTicketWatchersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.TicketWatchersQuery,
    Types.TicketWatchersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TicketWatchersQuery, Types.TicketWatchersQueryVariables>(
    TicketWatchersDocument,
    options,
  );
}
export function useTicketWatchersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TicketWatchersQuery,
    Types.TicketWatchersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TicketWatchersQuery, Types.TicketWatchersQueryVariables>(
    TicketWatchersDocument,
    options,
  );
}
export type TicketWatchersQueryHookResult = ReturnType<typeof useTicketWatchersQuery>;
export type TicketWatchersLazyQueryHookResult = ReturnType<typeof useTicketWatchersLazyQuery>;
export type TicketWatchersQueryResult = Apollo.QueryResult<
  Types.TicketWatchersQuery,
  Types.TicketWatchersQueryVariables
>;
export function refetchTicketWatchersQuery(variables: Types.TicketWatchersQueryVariables) {
  return { query: TicketWatchersDocument, variables: variables };
}
export const TicketWithRelatedTicketsDocument = gql`
  query ticketWithRelatedTickets($id: ID!) {
    ticket(id: $id) {
      ...ticketWithRelatedTickets
    }
  }
  ${TicketWithRelatedTicketsFragmentDoc}
  ${TicketFragmentDoc}
  ${UserWithPermInfoFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TicketFileFragmentDoc}
  ${RelatedTicketFragmentDoc}
`;

/**
 * __useTicketWithRelatedTicketsQuery__
 *
 * To run a query within a React component, call `useTicketWithRelatedTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketWithRelatedTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketWithRelatedTicketsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketWithRelatedTicketsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.TicketWithRelatedTicketsQuery,
    Types.TicketWithRelatedTicketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.TicketWithRelatedTicketsQuery,
    Types.TicketWithRelatedTicketsQueryVariables
  >(TicketWithRelatedTicketsDocument, options);
}
export function useTicketWithRelatedTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TicketWithRelatedTicketsQuery,
    Types.TicketWithRelatedTicketsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.TicketWithRelatedTicketsQuery,
    Types.TicketWithRelatedTicketsQueryVariables
  >(TicketWithRelatedTicketsDocument, options);
}
export type TicketWithRelatedTicketsQueryHookResult = ReturnType<
  typeof useTicketWithRelatedTicketsQuery
>;
export type TicketWithRelatedTicketsLazyQueryHookResult = ReturnType<
  typeof useTicketWithRelatedTicketsLazyQuery
>;
export type TicketWithRelatedTicketsQueryResult = Apollo.QueryResult<
  Types.TicketWithRelatedTicketsQuery,
  Types.TicketWithRelatedTicketsQueryVariables
>;
export function refetchTicketWithRelatedTicketsQuery(
  variables: Types.TicketWithRelatedTicketsQueryVariables,
) {
  return { query: TicketWithRelatedTicketsDocument, variables: variables };
}
export const TicketsTitlesListDocument = gql`
  query ticketsTitlesList($grid: TicketGridInput) {
    tickets(grid: $grid) {
      total
      rows {
        id
        title
      }
    }
  }
`;

/**
 * __useTicketsTitlesListQuery__
 *
 * To run a query within a React component, call `useTicketsTitlesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketsTitlesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsTitlesListQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useTicketsTitlesListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TicketsTitlesListQuery,
    Types.TicketsTitlesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TicketsTitlesListQuery, Types.TicketsTitlesListQueryVariables>(
    TicketsTitlesListDocument,
    options,
  );
}
export function useTicketsTitlesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TicketsTitlesListQuery,
    Types.TicketsTitlesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TicketsTitlesListQuery, Types.TicketsTitlesListQueryVariables>(
    TicketsTitlesListDocument,
    options,
  );
}
export type TicketsTitlesListQueryHookResult = ReturnType<typeof useTicketsTitlesListQuery>;
export type TicketsTitlesListLazyQueryHookResult = ReturnType<typeof useTicketsTitlesListLazyQuery>;
export type TicketsTitlesListQueryResult = Apollo.QueryResult<
  Types.TicketsTitlesListQuery,
  Types.TicketsTitlesListQueryVariables
>;
export function refetchTicketsTitlesListQuery(variables?: Types.TicketsTitlesListQueryVariables) {
  return { query: TicketsTitlesListDocument, variables: variables };
}
export const TimeLogsDocument = gql`
  query timeLogs($grid: TimeLogGridInput) {
    timeLogs(grid: $grid) {
      total
      rows {
        ...timeStats
      }
    }
  }
  ${TimeStatsFragmentDoc}
`;

/**
 * __useTimeLogsQuery__
 *
 * To run a query within a React component, call `useTimeLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeLogsQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useTimeLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.TimeLogsQuery, Types.TimeLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TimeLogsQuery, Types.TimeLogsQueryVariables>(
    TimeLogsDocument,
    options,
  );
}
export function useTimeLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.TimeLogsQuery, Types.TimeLogsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TimeLogsQuery, Types.TimeLogsQueryVariables>(
    TimeLogsDocument,
    options,
  );
}
export type TimeLogsQueryHookResult = ReturnType<typeof useTimeLogsQuery>;
export type TimeLogsLazyQueryHookResult = ReturnType<typeof useTimeLogsLazyQuery>;
export type TimeLogsQueryResult = Apollo.QueryResult<
  Types.TimeLogsQuery,
  Types.TimeLogsQueryVariables
>;
export function refetchTimeLogsQuery(variables?: Types.TimeLogsQueryVariables) {
  return { query: TimeLogsDocument, variables: variables };
}
export const TranslatablesDocument = gql`
  query translatables {
    translatables {
      id
      value
      rows {
        id
        type
        translations {
          language_code
          value
        }
      }
    }
  }
`;

/**
 * __useTranslatablesQuery__
 *
 * To run a query within a React component, call `useTranslatablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTranslatablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTranslatablesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTranslatablesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.TranslatablesQuery,
    Types.TranslatablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.TranslatablesQuery, Types.TranslatablesQueryVariables>(
    TranslatablesDocument,
    options,
  );
}
export function useTranslatablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.TranslatablesQuery,
    Types.TranslatablesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.TranslatablesQuery, Types.TranslatablesQueryVariables>(
    TranslatablesDocument,
    options,
  );
}
export type TranslatablesQueryHookResult = ReturnType<typeof useTranslatablesQuery>;
export type TranslatablesLazyQueryHookResult = ReturnType<typeof useTranslatablesLazyQuery>;
export type TranslatablesQueryResult = Apollo.QueryResult<
  Types.TranslatablesQuery,
  Types.TranslatablesQueryVariables
>;
export function refetchTranslatablesQuery(variables?: Types.TranslatablesQueryVariables) {
  return { query: TranslatablesDocument, variables: variables };
}
export const UserDocument = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...user
    }
  }
  ${UserFragmentDoc}
  ${UserInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
  ${TeamBasicFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<Types.UserQuery, Types.UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.UserQuery, Types.UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UserQuery, Types.UserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.UserQuery, Types.UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<Types.UserQuery, Types.UserQueryVariables>;
export function refetchUserQuery(variables: Types.UserQueryVariables) {
  return { query: UserDocument, variables: variables };
}
export const UserBasicDocument = gql`
  query userBasic($id: ID!) {
    user(id: $id) {
      ...userBasic
    }
  }
  ${UserBasicFragmentDoc}
`;

/**
 * __useUserBasicQuery__
 *
 * To run a query within a React component, call `useUserBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserBasicQuery(
  baseOptions: Apollo.QueryHookOptions<Types.UserBasicQuery, Types.UserBasicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.UserBasicQuery, Types.UserBasicQueryVariables>(
    UserBasicDocument,
    options,
  );
}
export function useUserBasicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UserBasicQuery, Types.UserBasicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.UserBasicQuery, Types.UserBasicQueryVariables>(
    UserBasicDocument,
    options,
  );
}
export type UserBasicQueryHookResult = ReturnType<typeof useUserBasicQuery>;
export type UserBasicLazyQueryHookResult = ReturnType<typeof useUserBasicLazyQuery>;
export type UserBasicQueryResult = Apollo.QueryResult<
  Types.UserBasicQuery,
  Types.UserBasicQueryVariables
>;
export function refetchUserBasicQuery(variables: Types.UserBasicQueryVariables) {
  return { query: UserBasicDocument, variables: variables };
}
export const UserListDocument = gql`
  query userList($grid: UserGridInput) {
    users(grid: $grid) {
      total
      rows {
        ...userInTheList
      }
    }
  }
  ${UserInTheListFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useUserListQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.UserListQuery, Types.UserListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.UserListQuery, Types.UserListQueryVariables>(
    UserListDocument,
    options,
  );
}
export function useUserListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UserListQuery, Types.UserListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.UserListQuery, Types.UserListQueryVariables>(
    UserListDocument,
    options,
  );
}
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export type UserListLazyQueryHookResult = ReturnType<typeof useUserListLazyQuery>;
export type UserListQueryResult = Apollo.QueryResult<
  Types.UserListQuery,
  Types.UserListQueryVariables
>;
export function refetchUserListQuery(variables?: Types.UserListQueryVariables) {
  return { query: UserListDocument, variables: variables };
}
export const UserNotificationsDocument = gql`
  query userNotifications($grid: NotificationGridInput) {
    userNotifications(grid: $grid) {
      total
      total_unread
      rows {
        ...notification
      }
    }
  }
  ${NotificationFragmentDoc}
  ${UserBasicFragmentDoc}
`;

/**
 * __useUserNotificationsQuery__
 *
 * To run a query within a React component, call `useUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationsQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useUserNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.UserNotificationsQuery,
    Types.UserNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.UserNotificationsQuery, Types.UserNotificationsQueryVariables>(
    UserNotificationsDocument,
    options,
  );
}
export function useUserNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.UserNotificationsQuery,
    Types.UserNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.UserNotificationsQuery, Types.UserNotificationsQueryVariables>(
    UserNotificationsDocument,
    options,
  );
}
export type UserNotificationsQueryHookResult = ReturnType<typeof useUserNotificationsQuery>;
export type UserNotificationsLazyQueryHookResult = ReturnType<typeof useUserNotificationsLazyQuery>;
export type UserNotificationsQueryResult = Apollo.QueryResult<
  Types.UserNotificationsQuery,
  Types.UserNotificationsQueryVariables
>;
export function refetchUserNotificationsQuery(variables?: Types.UserNotificationsQueryVariables) {
  return { query: UserNotificationsDocument, variables: variables };
}
export const UserStatisticsDocument = gql`
  query userStatistics($id: ID!) {
    userStatistics(id: $id) {
      thisWeek
      overdue
      today
      open
      closed
    }
  }
`;

/**
 * __useUserStatisticsQuery__
 *
 * To run a query within a React component, call `useUserStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserStatisticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.UserStatisticsQuery,
    Types.UserStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.UserStatisticsQuery, Types.UserStatisticsQueryVariables>(
    UserStatisticsDocument,
    options,
  );
}
export function useUserStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.UserStatisticsQuery,
    Types.UserStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.UserStatisticsQuery, Types.UserStatisticsQueryVariables>(
    UserStatisticsDocument,
    options,
  );
}
export type UserStatisticsQueryHookResult = ReturnType<typeof useUserStatisticsQuery>;
export type UserStatisticsLazyQueryHookResult = ReturnType<typeof useUserStatisticsLazyQuery>;
export type UserStatisticsQueryResult = Apollo.QueryResult<
  Types.UserStatisticsQuery,
  Types.UserStatisticsQueryVariables
>;
export function refetchUserStatisticsQuery(variables: Types.UserStatisticsQueryVariables) {
  return { query: UserStatisticsDocument, variables: variables };
}
export const UsersDocument = gql`
  query users($grid: UserGridInput) {
    users(grid: $grid) {
      rows {
        ...userBasic
      }
    }
  }
  ${UserBasicFragmentDoc}
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersQuery, Types.UsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.UsersQuery, Types.UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<Types.UsersQuery, Types.UsersQueryVariables>;
export function refetchUsersQuery(variables?: Types.UsersQueryVariables) {
  return { query: UsersDocument, variables: variables };
}
export const UsersBasicDocument = gql`
  query usersBasic($grid: UserGridInput) {
    users(grid: $grid) {
      total
      rows {
        ...userBasic
      }
    }
  }
  ${UserBasicFragmentDoc}
`;

/**
 * __useUsersBasicQuery__
 *
 * To run a query within a React component, call `useUsersBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersBasicQuery({
 *   variables: {
 *      grid: // value for 'grid'
 *   },
 * });
 */
export function useUsersBasicQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.UsersBasicQuery, Types.UsersBasicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.UsersBasicQuery, Types.UsersBasicQueryVariables>(
    UsersBasicDocument,
    options,
  );
}
export function useUsersBasicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.UsersBasicQuery, Types.UsersBasicQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.UsersBasicQuery, Types.UsersBasicQueryVariables>(
    UsersBasicDocument,
    options,
  );
}
export type UsersBasicQueryHookResult = ReturnType<typeof useUsersBasicQuery>;
export type UsersBasicLazyQueryHookResult = ReturnType<typeof useUsersBasicLazyQuery>;
export type UsersBasicQueryResult = Apollo.QueryResult<
  Types.UsersBasicQuery,
  Types.UsersBasicQueryVariables
>;
export function refetchUsersBasicQuery(variables?: Types.UsersBasicQueryVariables) {
  return { query: UsersBasicDocument, variables: variables };
}
